import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/css/global.css' // 引入全局样式
import './plugins/element.js'
import 'amfe-flexible'
import moment from 'moment'
import VueRouter from 'vue-router'
import puzzleVerification from 'vue-puzzle-verification'
// 在main.js中全局引入
import VideoPlayer from 'vue-video-player/src'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
import topBanner from './components/topBanner.vue'
import BaiduMap from 'vue-baidu-map'
Vue.use(VideoPlayer)
Vue.use(BaiduMap, {
  ak: '5BjyFTZfk2Cy7csbxfYYEeNTvUHj2Gda'
})
Vue.use(topBanner)
Vue.use(puzzleVerification)
Vue.component('topBanner', topBanner)
Vue.prototype.$moment = moment
Vue.config.productionTip = false
moment.locale('zh-cn')

Vue.prototype.ismobil = function () {
  return Vue.ismobil
}
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
