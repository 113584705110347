<template>
    <div class="top-backview">
        <div class="contnet-backview">
            <!-- banner图 -->
            <topBanner
                :bannerData="images"
                direction="left"
                lineColor="yellow"
            ></topBanner>
            <!-- 中间部分内容 -->
            <div class="center-backview">
                <div class="center-title-class">“总经理寄语”</div>
                <div class="determination-back">
                    <img
                        class="determination-img"
                        src="https://earthone.com.cn/imageUrl/pengzong.png"
                        alt=""
                    />
                    <div class="about-us-address">
                        <div class="about-us-title" style="margin-bottom: 3px; margin-top: 10px;">
                            致尊敬的各位
                        </div>
                        <div class="about-us-title" style="margin-bottom: 15px">
                            感谢您对爱志万的关注和厚爱！
                        </div>
                        <div class="about-us-str">
                            爱志万作为日本EARTH
                            SUPPORT公司投资企业，自2016年成立以来，秉着“客户第一”精神，始终坚持“创造一个让所有人都能够在自己住惯了的环境里，按照自己的方式继续生活下去的社会”企业理念，为顾客提供能够获得幸福感的，快乐的和充满感动的服务，我们一直在不懈努力。<br>
                            “百善孝为先”，是中华五千年的承传下来的道德认知。目前随着中国的老年化的加速，传统的家庭养老和社会福利事业的方式，已满足不了愈来愈多样化的养老需求。<br>
                            在此环境下，爱志万不仅带来了作为日本养老服务业先驱者，母公司EARTH SUPPORT的专业技术和养老服务理念，致力于创造“能够让每一位顾客都能够享受得到安全放心和高品质的照护服务”的服务体系，同时摸索建立能够带来持续发展，健康经营的模式，努力为投入养老事业的员工，提供实现自身价值、带来成长，和能够获得幸福感的职场环境。<br>
                            照护服务，是人对人的服务。员工自身素质和技术直接会影响到服务品质。我们一边着重致力于提升每一位员工专业技术能力的人才培训，同时重视能够实现迅速高效，灵活应对多样化和复杂化需求的团队建设。<br>
                            爱志万之品牌，缘起日本，植根中国。<br>
                            在上海，我们完成的仅仅是日本卓越护理技术接壤中华大地的第一步。<br>
                            我们同时懂得事业成功的关键，需要我们保持匠人的务实态度，勇于创新的胆魄，和严谨的专业精神，这样才能够在落地实践中，扎扎实实地寻求和探索出一条真正适合中华民族的实现幸福养老的服务之路。<br>
                            “欲求其中,必求其上,欲求其上,必求其上上”。<br>
                            爱志万将永远保持“高品质”，“迅速高效”，“安全放心”的服务初心，牢记养老之使命，为成为“最受顾客欢迎的企业”而奋斗！
                        </div>
                    </div>
                </div>
                <div class="determination-bottom">
                    <div class="determination-title">我们的理念</div>
                    <div class="determination-eng">Our determination</div>
                    <div class="determination-content">爱志万致力于成为</div>
                    <div class="determination-content">
                        “点燃生命之希望的企业”
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
  props: {
    dataHeight: {
      type: String,
      default: '100%'
    }
  },
  data () {
    return {
      images: [{ url: 'https://earthone.com.cn/imageUrl/leaders_message_banner.jpg', titleStr: '总 经 理 寄 语', testStr: 'SEND WORD' }],
      determinationImg: 'https://earthone.com.cn/imageUrl/determination.jpg'
    }
  }
}
</script>
<style lang="less" scoped>
.marginTop {
    margin-top: 30px;
}
.top-backview {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.contnet-backview {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
}
.banner-backview {
    width: 1000px;
    height: 360px;
    position: relative;
}
.banner-testback {
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    left: 150px;
    top: 120px;
}
.banner-img-class {
    width: 1000px;
    height: 360px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.bannerclass {
    width: 100%;
    height: 360px;
    background-color: rgb(0, 0, 0);
    z-index: 99;
}
.banner-title-class {
    color: rgb(255, 255, 255);
    font-size: 26px;
    text-align: center;
    z-index: 100;
}
.banner-test-class {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 10px;
}
.el-carousel {
    width: 1000px;
    height: 360px;
}
.el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
}
.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}
.el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
}
.center-backview {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.center-title-class {
    width: 750px;
    font-size: 15px;
    color: #00a395;
    text-align: right;
    margin-top: 30px;
}
.determination-back {
    width: 800px;
    margin-bottom: 60px;
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    align-items: self-start;
    justify-content: center;
}
.determination-img {
    width: 200px;
    height: 262px;
}
.about-us-address {
    width: 600px;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.about-us-title {
    width: 100%;
    letter-spacing: 2px;
    font-size: 11px;
    font-weight: bolder;
    color: black;
}
.about-us-str {
    width: 100%;
    letter-spacing: 2px;
    line-height: 14px;
    font-family: 'albbpht';
    font-size: 10px;
    color: black;
}
.about-us-line-img {
    width: 300px;
    margin: 10px;
    height: 1px;
}
.about-us-right {
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.about-us-right-img {
    width: 300px;
    height: 189px;
}
.determination-bottom {
    width: 100%;
    height: 300px;
    padding-left: 20%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background-image: url('https://earthone.com.cn/imageUrl/determination.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left;
}
.determination-title {
    width: 70%;
    font-size: 14px;
    letter-spacing: 2px;
    font-weight: 800;
    color: white;
    text-align: left;
}
.determination-eng {
    width: 70%;
    font-size: 10px;
    margin-top: 3px;
    margin-bottom: 40px;
    color: white;
    text-align: left;
}
.determination-content {
    width: 70%;
    font-size: 18px;
    letter-spacing: 8px;
    font-weight: bolder;
    color: white;
    text-align: left;
}
</style>
