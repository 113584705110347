<template>
  <div class="top-backview">
      <div class="contnet-backview">
          <!-- banner图 -->
          <topBanner :bannerData="images" direction="left" lineColor="white"></topBanner>
          <!-- 中间部分内容 -->
          <div class="center-backview">
              <div class="introCom-content-titleback">
                  <div class="introCom-content-title">联系我们</div>
                  <div class="introCom-content-title-eng">
                    contact us
                  </div>
              </div>
              <div class="contactus-content">
                <div class="contactus-content-item" v-for="(item, index) in contactList" :key="index">
                  <div class="contactus-item-title">{{item.titleStr}}</div>
                  <img class="contactus-item-img" :src="item.imgUrl" alt="">
                  <div class="contactus-item-desc" v-if="item.desc !== ''" v-html="item.desc"></div>
                  <img class="contactus-item-codeimg" v-else :src="item.descImg" alt="">
                </div>
              </div>
              <MapContainer></MapContainer>
          </div>
      </div>
  </div>
</template>
<script>
import MapContainer from '@/components/MapContainer.vue'
export default {
  components: {
    MapContainer
  },
  props: {
    dataHeight: {
      type: String,
      default: '100%'
    }
  },
  data () {
    return {
      contactList: [
        {
          titleStr: '营业时间',
          imgUrl: 'https://earthone.com.cn/imageUrl/Framecontact_icon001.png',
          desc: '周一到周六 8:30-17:30'
        }, {
          titleStr: '公司电话',
          imgUrl: 'https://earthone.com.cn/imageUrl/Framecontact_icon002.png',
          desc: '021-54561388<br>021-64876011（居家专线）'
        }, {
          titleStr: '官方邮箱',
          imgUrl: 'https://earthone.com.cn/imageUrl/Framecontact_icon003.png',
          desc: 'earthone@earthsupport-ch.com'
        }, {
          titleStr: '公司地址',
          imgUrl: 'https://earthone.com.cn/imageUrl/Framecontact_icon004.png',
          desc: '上海市徐汇区龙华路1887号2幢101室，108室'
        }, {
          titleStr: '爱志万地图二维码',
          imgUrl: 'https://earthone.com.cn/imageUrl/Framecontact_icon005.png',
          desc: '',
          descImg: 'https://earthone.com.cn/imageUrl/contact_codeimg.png'
        }
      ],
      images: [{ url: 'https://earthone.com.cn/imageUrl/contactus_banner.jpg', titleStr: '联 系 方 式', testStr: 'Contact information' }]
    }
  },
  mounted () {
  },
  methods: {
  }
}
</script>
<style lang="less" scoped>
.top-backview {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.contnet-backview {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}
.banner-backview {
  width: 1000px;
  height: 360px;
  position: relative;
}
.banner-testback {
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  left: 150px;
  top: 120px;
}
.banner-img-class {
  width: 1000px;
  height: 360px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.bannerclass {
  width: 100%;
  height: 360px;
  background-color: rgb(0, 0, 0);
  z-index: 99;
}
.banner-title-class {
  color: rgb(255, 255, 255);
  font-size: 26px;
  text-align: left;
  z-index: 100;
}
.banner-test-class {
  color: rgb(255, 255, 255);
  text-align: left;
  font-size: 10px;
}
.el-carousel {
  width: 1000px;
  height: 360px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}
.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.center-backview {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content-service-back {
  width: 700px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  .service-item {
      width: 220px;
      height: 200px;
      margin-bottom: 20px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  }
  .service-item-img {
      width: 150px;
      height: 150px;
      border-radius: 75px;
  }
  .service-item-title {
      width: 100%;
      margin-top: 10px;
      letter-spacing: 4px;
      font-size: 12px;
      color: black;
      font-weight: 800;
      text-align: center;
  }
}
.contactus-content{
  width: 800px;
  margin-left: 100px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  .contactus-content-item{
    width: 260px;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    .contactus-item-title{
      width: 100%;
      font-size: 12px;
      color: black;
      font-weight: 700;
      letter-spacing: 4px;
    }
    .contactus-item-img{
      width: 20px;
      height: 20px;
      margin: 10px 0px;
    }
    .contactus-item-desc{
      width: 100%;
      color: rgb(66, 66, 66);
      font-size: 10px;
      text-align: left;
      letter-spacing: 1px;
    }
    .contactus-item-codeimg{
      width: 80px;
      height: 80px;
    }
  }
}
.contactus-address{
  width: 700px;
  height: 295px;
  margin: 30px;
}
</style>
