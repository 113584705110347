<template>
    <div class="top-backview">
        <div class="contnet-backview">
            <!-- banner图 -->
            <topBanner :bannerData="images" direction="right" lineColor="white"></topBanner>
            <!-- 中间部分内容 -->
            <div class="center-backview">
                <div class="introCom-content-titleback">
                    <div class="introCom-content-title">
                        认知障碍非医疗干预法
                    </div>
                    <div class="introCom-content-title-eng">
                        Non medical intervention methods for cognitive
                        impairment
                    </div>
                </div>
                <div class="daycar-content-str">
                    非医疗干预可以通过对认知症患者进行心理、社会方面的关怀，缓解其产生幻觉、妄想、攻击、焦燥性兴奋、徘徊、抑郁等反应式行为（周边症状）。可以维持和提高认知障碍长者的认知能力和生活品质，从而减轻护理人员的负担。
                </div>
                <div class="gray-backview">
                    <div class="introCom-content-titleback">
                        <div class="introCom-content-title">运动疗法</div>
                        <div class="introCom-content-title-eng">
                            Exercise therapy
                        </div>
                        <div class="daycar-content-str margin-top-class">
                            运动疗法不论是进入MCI（轻度认知障碍）阶段的长者、患认知症的长者、健康的长者的语言功能、
                            脑萎缩的恶化抑制、整体认知功能下降的抑制、提高记忆能力等认知功能都有良好的成果。特别是关于运动的习惯，每周3次以上的实施对抑制认知症的发病会更有效果。老年期的运动具有保持肌肉骨骼系统的功能、提高呼吸循环功能、降低血压、改善脂质代谢、减轻压力、预防跌倒等多种效果，这样对保持身心健康是有益的。
                        </div>
                        <div class="service-content-back">
                            <div class="content-back-view">
                                <img
                                    class="content-topimg"
                                    src="https://earthone.com.cn/imageUrl/Frametherapy_01.png"
                                    alt=""
                                />
                                <div class="service-content-title">
                                    Cogni-梯子
                                </div>
                            </div>
                            <div class="content-back-view">
                                <img
                                    class="content-topimg"
                                    src="https://earthone.com.cn/imageUrl/Frametherapy_02.png"
                                    alt=""
                                />
                                <div class="service-content-title">
                                    运动训练
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="introCom-content-titleback">
                    <div class="introCom-content-title">园艺疗法</div>
                    <div class="introCom-content-title-eng">
                        Horticultural therapy
                    </div>
                </div>
                <div class="therapy-content" style="align-items: flex-end">
                    <img
                        class="therapy-content-img"
                        src="https://earthone.com.cn/imageUrl/Frametherapy_03.png"
                        alt=""
                    />
                    <div class="therapy-content-right">
                        <div class="therapy-content-title">园艺疗法</div>
                        <div class="therapy-content-desc">
                            园艺疗法是实际接触和运用园艺材料，制作园艺作品或盆栽，从而达到预定的治疗目标，例如纾解压力、复健心灵、促进社交、情绪、小肌肉训练、认知训练、专注力恢复、精神健康等等好处。<br />通过开展园艺治疗系列小组活动，把一些科学训练方法与园艺活动相结合，锻炼认知障碍长者和健康长者的注意力、判断力、社交能力和语言能力，预防及改善长者的认知障碍情况，同时训练长者的手眼协调能力，刺激长者的视觉、触觉、嗅觉、听觉等感觉，改善长者的感知能力；另外，通过参加园艺活动，能够丰富长者的日常生活，提高长者的人际交往能力，增强长者的获得感、成就感和幸福感，进而提升长者的生活质量和生活水平。
                        </div>
                    </div>
                </div>
                <div class="gray-backview">
                    <div class="introCom-content-titleback">
                        <div class="introCom-content-title">音乐疗法</div>
                        <div class="introCom-content-title-eng">
                            Music therapy
                        </div>
                    </div>
                    <div class="therapy-content">
                        <img
                            class="therapy-content-img2"
                            src="https://earthone.com.cn/imageUrl/Frametherapy_04.png"
                            alt=""
                        />
                        <div class="therapy-content-desc2">
                            通过音乐疗法帮助长者调整情绪和情感，影响心理平衡，缓解压力，改善心理健康。运用音乐疗法，可以激发长者回忆当时的场景。音乐疗法是运用与音乐相关手段，以促进被干预者达到健康的目的。主要运用了听和唱。听可以促进听觉能力，包括注意力、持续度、记忆力、感受力、辨认能力。唱可以增进表达性语言的训练、增强对呼吸与肌肉控制并刺激与提高使用声音的动机。
                        </div>
                    </div>
                </div>

                <div class="introCom-content-titleback">
                    <div class="introCom-content-title">游戏疗法</div>
                    <div class="introCom-content-title-eng">Play therapy</div>
                </div>
                <div class="therapy-content">
                    <div class="therapy-content-desc2">
                        通过碎片拼图、算数、积木、七巧板等玩具锻炼长者的认知和思维能力。游戏疗法能在游戏中观察认知症长者的行为和情绪，从中发现他们的问题，并帮助他们找到解决问题的方法。而且能够通过游戏提高认知症长者的社交能力和情感表达能力，帮助他们更好地与他人沟通，我们能利用游戏的挑战性来激发认知症长者的兴趣，帮助他们专注于干预过程中的任务，从而提高干预效果。同时也能通过游戏来改善认知症长者的情感和心理健康，帮助他们更好地控制情绪，减少压力和焦虑；提高长者的记忆力、思维能力和注意力，帮助他们提高学习效率。
                    </div>
                    <img
                        class="therapy-content-img2"
                        src="https://earthone.com.cn/imageUrl/Frametherapy_05.png"
                        alt=""
                    />
                </div>

                <div class="gray-backview">
                    <div class="introCom-content-titleback">
                        <div class="introCom-content-title">学习疗法</div>
                        <div class="introCom-content-title-eng">
                            Learning therapy
                        </div>
                    </div>
                    <div class="therapy-content">
                        <img
                            class="therapy-content-img2"
                            src="https://earthone.com.cn/imageUrl/Frametherapy_06.png"
                            alt=""
                        />
                        <div class="therapy-content-desc2">
                            学习疗法是通过运用各种空间、逻辑、计算、推理等方面的训练，让长者提高大脑的血流量、促进前额叶活性化，可以帮助改善MCI的状态。同时学习疗法能帮助长者锻炼其大脑皮层中负责思考、语言和记忆等活动的部分，使一些因衰老而丧失的功能得以部分恢复。
                        </div>
                    </div>
                </div>

                <div class="introCom-content-titleback">
                    <div class="introCom-content-title">冥想疗法</div>
                    <div class="introCom-content-title-eng">
                        Meditation therapy
                    </div>
                </div>
                <div class="therapy-content">
                    <div class="therapy-content-desc2">
                        冥想疗法是让长者运用意识的想象力，调动生物信息场，调节身体阴阳，增强体质，改善睡眠质量。冥想可以帮助我们摆脱负面情绪，净化意识、重新掌控生活，也是极好的“脑操”，可以改善睡眠质量、使大脑更强壮。
                    </div>
                    <img
                        class="therapy-content-img2"
                        src="https://earthone.com.cn/imageUrl/Frametherapy_07.png"
                        alt=""
                    />
                </div>
                <div class="gray-backview">
                    <div class="introCom-content-titleback">
                        <div class="introCom-content-title">回忆疗法</div>
                        <div class="introCom-content-title-eng">
                            Memory therapy
                        </div>
                    </div>
                    <div class="therapy-content">
                        <img
                            class="therapy-content-img2"
                            src="https://earthone.com.cn/imageUrl/Frametherapy_08.png"
                            alt=""
                        />
                        <div class="therapy-content-desc2">
                            通过照片让长者回忆过去的美好时光，激发他们的积极情绪，提升生活质量。研究表明，这种疗法可以提高长者自尊，安慰年长的认知症长者。当长者回想过去的日子，他们变得渴望与他人交流，他们在分享过去的生活中得到获得一种成就感。
                        </div>
                    </div>
                </div>
                <div class="introCom-content-titleback">
                    <div class="introCom-content-title">
                        认知障碍干预活动及成效
                    </div>
                    <div class="introCom-content-title-eng">
                        Cognitive impairment intervention activities and their
                        effectiveness
                    </div>
                </div>
                <div class="daycar-content-str">
                    2021年爱志万日间照料中心强阿姨的案例，入选了上海市认知症照护优秀案例汇编。
                </div>
                <div class="effectiveness-back">
                    <img
                        class="effectiveness-img"
                        src="https://earthone.com.cn/imageUrl/effectiveness01.png"
                        alt=""
                    />
                    <img
                        class="effectiveness-img"
                        src="https://earthone.com.cn/imageUrl/effectiveness02.png"
                        alt=""
                    />
                </div>
                <div class="introCom-content-titleback">
                    <div class="introCom-content-title">
                      活动
                    </div>
                    <div class="introCom-content-title-eng">
                      activity
                    </div>
                </div>
                <div class="activity-back">
                  <div class="activity-item" v-for="(item, index) in activiteList" :key="index">
                    <img class="activity-item-img" :src="item.img" alt="">
                    <div class="activity-item-right">
                      <div class="activity-item-right-title">{{item.title}}</div>
                      <div class="activity-item-right-line"></div>
                      <div class="activity-item-right-desc" v-if="index === 4 || index === 5" style="line-height: 20px;">{{item.desc}}</div>
                      <div class="activity-item-right-desc" v-else>{{item.desc}}</div>
                    </div>
                  </div>
                </div>
                <div class="introCom-content-titleback">
                    <div class="introCom-content-title">
                      干预成效
                    </div>
                    <div class="introCom-content-title-eng">
                      Intervention effectiveness
                    </div>
                </div>
                <div class="introCom-back" v-for="(item, index) in introComDesc" :key="index">
                  <div class="introCom-title">{{item.title}}</div>
                  <div class="introCom-desc" v-html="item.desc"></div>
                </div>
                <div style="height: 30px;"></div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
  props: {
    dataHeight: {
      type: String,
      default: '100%'
    }
  },
  data () {
    return {
      introComDesc: [
        { title: '2021年在虹梅街道虹星居委开展园艺疗法', desc: '活动次数：12次<br>参加有效人数：10位<br>MMSE量表平均分：26.0分（干预前）    27.3分（干预后）' },
        { title: '2021年在虹梅街道古美小区开展园艺疗法', desc: '活动次数：12次<br>参加有效人数：13位<br>MMSE量表平均分：25.5分（干预前）    28.5分（干预后）' },
        { title: '2023年在枫林街道开展认知障碍早期干预活动的效果及反馈', desc: '活动次数：12次<br>参加有效人数：10位<br>MoCA量表平均分：24.8分（干预前）    27.1分（干预后）' },
        { title: '长者反馈：参加了干预活动的12次课程后，对比干预前的情况，记忆力有明显提高、睡眠质量有所改善、能独立思考、减少了对家人的依赖。', desc: '' }
      ],
      activiteList: [
        { img: 'https://earthone.com.cn/imageUrl/activity_001.png', title: '六一活动', desc: '这次活动运用了回忆疗法，我们找到了长者小时候的入队徽，让各位长者重温了一下入少先队员的记忆。' },
        { img: 'https://earthone.com.cn/imageUrl/activity_002.png', title: '手工一 彩泥', desc: '本次活动让长者练习手部精细动作，从颜色搭配到形状的改变再到组合在一起，让长者锻炼双手与大脑。' },
        { img: 'https://earthone.com.cn/imageUrl/activity_003.png', title: '手工二 香囊', desc: '借着端午节活动我们做了手工香囊，让长者从观、嗅、摸几点让长者了解材料的知识，从而懂得材料的功效。' },
        { img: 'https://earthone.com.cn/imageUrl/activity_004.png', title: '大脑计算锻炼', desc: '此题库共有25份，长者们很有兴趣，每一次活动都会拿着上次活动发的题大家一起讨论对错，场面很活跃，锻炼了长者的计算能力又激发了长者的求知欲。' },
        { img: 'https://earthone.com.cn/imageUrl/activity_005.png', title: '游戏一 翻纸牌', desc: '此游戏是一种对抗赛，长者双方可以通过手眼协调能力让迅速的找到对方的纸牌并反过来，最后计时时间到双方谁的颜色纸牌多谁获胜，我们还会升级游戏玩法，用筷子挑不用手去翻，难度增加，也更加锻炼了长者的手指灵活能力与大脑的反应能力。' },
        { img: 'https://earthone.com.cn/imageUrl/activity_006.png', title: '游戏二 运送乒乓球', desc: '将乒乓球放在小勺子上面，通过前面的障碍物回到远点交给下一位长者，需要乒乓球在运送和交接的时候球不能掉在地上，不可以用手去触摸，还可以增加难度，右手换左手，障碍物可以多一些，让长者手眼脑一起动起来。' },
        { img: 'https://earthone.com.cn/imageUrl/activity_007.png', title: '梯子运动', desc: '看似简单的梯子运动，其实没有那么简单，从初级的左脚右脚踩进踩出，到后面边问长者问题，边让长者按照指定要求做出需要思考的步伐，边运动变动脑可以让长者锻炼大脑的同时锻炼到了身体。' }
      ],
      bannerSelect: 'https://earthone.com.cn/imageUrl/management_001.png',
      images: [{ url: 'https://earthone.com.cn/imageUrl/intervention_services_banner.jpg', titleStr: '认 知 症 干 预 服 务', testStr: 'Cognitive intervention services' }]
    }
  },
  methods: {
  }
}
</script>
<style lang="less" scoped>
.marginTop {
    margin-top: 30px;
}
.top-backview {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.contnet-backview {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
}
.banner-backview {
    width: 1000px;
    height: 360px;
    position: relative;
}
.banner-testback {
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    position: absolute;
    right: 150px;
    top: 120px;
}
.banner-img-class {
    width: 1000px;
    height: 360px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.bannerclass {
    width: 100%;
    height: 360px;
    background-color: rgb(0, 0, 0);
    z-index: 99;
}
.banner-title-class {
    color: rgb(255, 255, 255);
    font-size: 26px;
    text-align: center;
    z-index: 100;
}
.banner-test-class {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 10px;
}
.el-carousel {
    width: 1000px;
    height: 360px;
}
.el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
}
.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}
.el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
}
.center-backview {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.daycar-content-str {
    width: 700px;
    font-size: 10px;
    font-family: 'albbpht';
    color: rgb(59, 59, 59);
    text-align: center;
}
.margin-top-class {
    margin-top: 20px;
}
.gray-backview {
    width: 100%;
    margin-top: 30px;
    padding-bottom: 20px;
    background: rgb(245, 245, 245);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.service-content-back {
    width: 700px;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .content-back-view {
        width: 340px;
        height: 260px;
        display: flex;
        border: 1px solid #dfdfdf;
        flex-direction: column;
        align-items: center;
        .content-topimg {
            width: 340px;
            height: 218px;
        }
        .service-content-title {
            width: 100%;
            height: 40px;
            font-family: 'albbphtbold';
            line-height: 40px;
            font-size: 12px;
            color: black;
            text-align: center;
        }
    }
}
.therapy-content {
    width: 700px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    .therapy-content-right {
        width: 510px;
        height: 280px;
        display: flex;
        box-shadow: 2px 2px 8px rgba(106, 106, 106, 0.2);
        flex-direction: column;
        .therapy-content-title {
            width: 510px;
            height: 40px;
            text-align: center;
            line-height: 40px;
            font-size: 20px;
            background: #00a395;
            color: white;
            font-weight: bolder;
        }
        .therapy-content-desc {
            width: 460px;
            margin-top: 20px;
            line-height: 20px;
            margin-left: 20px;
            letter-spacing: 4px;
            font-size: 10px;
            color: rgb(49, 49, 49);
        }
    }
    .therapy-content-img {
        width: 160px;
        height: 305px;
    }
    .therapy-content-img2 {
        width: 280px;
        height: 174px;
    }
    .therapy-content-desc2 {
        width: 350px;
        line-height: 18px;
        margin: 0px 15px;
        font-family: 'albbpht';
        letter-spacing: 2px;
        font-size: 10px;
        color: rgb(49, 49, 49);
    }
}
.effectiveness-back{
  width: 750px;
  margin: 20px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .effectiveness-img{
    width: 365px;
    height: 240px;
    margin: 0px 2px;
  }
}
.activity-back{
  width: 750px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  .activity-item{
    width: 365px;
    margin-top: 10px;
    margin-left: 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    border: 1px solid #e1e1e1;
    padding: 15px;
    box-sizing: border-box;
    .activity-item-img{
      width: 85px;
      height: 105px;
    }
    .activity-item-right{
      width: 240px;
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      .activity-item-right-title{
        width: 100%;
        height: 20px;
        line-height: 20px;
        font-size: 12px;
        color: black;
        font-weight: bolder;
        text-align: left;
      }
      .activity-item-right-line{
        width: 100%;
        height: 1px;
        margin: 5px 0px;
        background: rgb(195, 195, 195);
      }
      .activity-item-right-desc{
        width: 100%;
        font-size: 10px;
        margin-top: 6px;
        font-family: 'albbpht';
        color: rgb(0, 0, 0);
        line-height: 18px;
      }
    }
  }
}
.introCom-back{
  width: 700px;
  background: rgb(245, 245, 245);
  padding: 20px;
  margin-top: 10px;
  .introCom-title{
    width: 90%;
    color: black;
    margin-bottom: 10px;
    font-weight: 800;
    letter-spacing: 2px;
    font-size: 12px;
  }
  .introCom-desc{
    color: rgb(82, 82, 82);
    font-weight: 400;
    font-family: 'albbpht';
    font-size: 10px;
    letter-spacing: 2px;
    line-height: 20px;
  }
}
</style>
