<template>
    <div class="top-backview">
        <div class="contnet-backview">
            <!-- banner图 -->
            <topBanner
                :bannerData="images"
                direction="right"
                lineColor="yellow"
            ></topBanner>
            <!-- 中间部分内容 -->
            <div class="center-backview">
                <div class="center-title-class">“爱志万，成立于2016年”</div>
                <div class="determinationBack">
                    <div class="determination-back">
                        <div class="determination-content-back">
                            爱志万（上海）健康管理有限公司是由日本著名养老和康养集团爱志（EARTHSUPPORT）公司投资的养老服务及健康管理领域专<br />业公司。目前除了在上海总部以外，在山东和四川分别设有分公司。<br />
                            自2016年成立以来，爱志万有机地秉承了母公司之理念追求，不忘养老服务之初心，牢记尊老爱老的事业使命，专注为老年人提供实实在在的服务。尤其在社区和居家养老服务模式上，爱志万把母公司在日本积累多年的社区以及居家照护的服务模式和丰富的管理经验，以及精致的照护技术，与国内本土文化结合起来，探索出了一套真正适合中华民族实现幸福养老的服务模式和道路。<br />
                            公司目前业务范围涉及针对中，重度老年人的日托型养护中心、居家养老服务、到宅沐浴服务、养老机构代运营、认知障碍早期干预、辅具租赁与销售、居家适老化改造、健康管理等领域。
                        </div>
                    </div>
                </div>
                <div class="about-content-back">
                    <div class="about-us-address">
                        <div class="about-us-left">
                            <div class="about-us-str">
                                爱志万梦想着创造一个和谐的在地养老环境，让每一个生活在这个环境的长者，即使他（她）们到了需要被人照料的时候，因为有我们的服务支撑，他们可以继续生活在自己所熟悉的环境里，按照自己最认可和最舒适的生活习惯和方式生活，他们因此而不失尊严、能够度过一个有价值、有意义的晚年时光！
                            </div>
                            <img
                                src="https://earthone.com.cn/imageUrl/Vectorline_view.png"
                                alt=""
                                class="about-us-line-img"
                            />
                            <div class="about-us-str">
                                爱志万作为上海市首批指定的长期护理保险的定点服务机构之一，同时拥有上海市养老服务行业协会理事单位、上海市拥军优属基金会特别贡献奖、徐汇区长护险服务技能比赛一等奖，并拥有二类医疗器械经营资格认证等多项资质荣誉。让来自日本的技术种子，在中华大地的土壤中得以生根发芽，开花结果。
                            </div>
                            <img
                                src="https://earthone.com.cn/imageUrl/Vectorline_view.png"
                                alt=""
                                class="about-us-line-img"
                            />
                            <div class="about-us-str">
                                爱志万将朝着“成为最受顾客欢迎的企业”这一目标而不断前进！
                                志万中国，为爱而生！
                            </div>
                        </div>
                        <div class="about-us-right">
                            <img
                                class="about-us-right-img"
                                src="https://earthone.com.cn/imageUrl/framemap.jpg"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
  props: {
    dataHeight: {
      type: String,
      default: '100%'
    }
  },
  data () {
    return {
      images: [{ url: 'https://earthone.com.cn/imageUrl/aboutus-banner.jpg', titleStr: '爱 志 万 介 绍', testStr: 'INTRODUCE' }]
    }
  }
}
</script>
<style lang="less" scoped>
.marginTop {
    margin-top: 30px;
}
.top-backview {
    width: 100%;
    height: auto;
    //
    display: flex;
    align-items: center;
    flex-direction: column;
}

.contnet-backview {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
}
.banner-backview {
    width: 1000px;
    height: 360px;
    position: relative;
}
.banner-testback {
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    right: 150px;
    top: 120px;
}
.banner-img-class {
    width: 1000px;
    height: 360px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.bannerclass {
    width: 100%;
    height: 360px;
    background-color: rgb(0, 0, 0);
    z-index: 99;
}
.banner-title-class {
    color: rgb(255, 255, 255);
    font-size: 26px;
    text-align: center;
    z-index: 100;
}
.banner-test-class {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 10px;
}
.el-carousel {
    width: 1000px;
    height: 360px;
}
.el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
}
.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}
.el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
}
.center-backview {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0px;
}
.center-title-class {
    width: 700px;
    font-size: 13px;
    color: #00a395;
    text-align: left;
    margin-top: 30px;
}
.determinationBack {
    width: 714px;
    height: 335px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 2px 8px rgba(106, 106, 106, 0.4);
}
.determination-back {
    width: 700px;
    height: 321px;
    background-image: url(https://earthone.com.cn/imageUrl/Frameappearance.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}
.determination-content-back {
    width: 610px;
    height: 140px;
    position: relative;
    box-sizing: border-box;
    font-family: 'albbpht';
    padding: 10px 20px;
    font-size: 10px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    line-height: 17px;
    backdrop-filter: blur(4px);
}
// .determination-content-back::after {
//     content: '';
//     position: absolute;
//     z-index: -1; /* 将伪元素放在内容下方 */
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     z-index: 99;
//     background-color: black;
//     opacity: 0.5;
//     /* 设置透明度为半透明 */
//     /* 使用 filter 属性添加模糊效果 */
//     filter: blur(10px);
// }
.about-content-back {
    width: 100%;
    box-sizing: border-box;
    padding: 40px;
    margin-top: 80px;
    margin-bottom: 50px;
    background: rgb(246, 246, 246);
    display: flex;
    align-items: center;
    justify-content: center;
}
.about-us-address {
    width: 800px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.about-us-left {
    width: 370px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.about-us-str {
    width: 100%;
    letter-spacing: 4px;
    font-family: 'albbpht';
    font-size: 10px;
    line-height: 16px;
    color: black;
}
.about-us-line-img {
    width: 380px;
    margin: 10px;
    height: 1px;
}
.about-us-right {
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.about-us-right-img {
    width: 380px;
    height: 240px;
}
</style>
