<template>
    <el-container>
        <el-header class="headview">
            <Topbar class="topbar" ref="topbar"></Topbar>
            <!-- logo图片 -->
        </el-header>
        <el-container>
            <el-main id="main">
                <router-view :key="$router.path"></router-view>
                <div class="foot-class">
                    <Footer
                        class="footerclass"
                        @reloadSelectUrl="reloadSelectUrl"
                    ></Footer>
                </div>
                <div class="connection-back">
                    <div class="back-view" @click="turnToContanct()">
                        <div class="title">联</div>
                        <div class="title">系</div>
                        <div class="title">我</div>
                        <div class="title">们</div>
                        <img
                            class="title-img"
                            src="@/assets/images/earthImages/contanct_icon.png"
                            alt=""
                        />
                    </div>
                </div>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
import Topbar from '@/components/Topbar.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: 'app',
  data () {
    return {
    }
  },
  methods: {
    goTohome () {
      this.$refs.topbar.reloadActiveIndex('/homeView')
      this.$router.push('/homeView')
    },
    turnToContanct () {
      this.$router.push('/contactus')
    },
    reloadSelectUrl (reloadSelectUrl) {
      this.$refs.topbar.reloadActiveIndex(reloadSelectUrl)
    }
  },
  components: {
    Topbar,
    Footer
  }
}
</script>

<style lang="less" scoped>
.connection-back {
    position: fixed;
    right: 0;
    top: 80px;
    z-index: 9999;
}
.back-view {
    width: 30px;
    height: 90px;
    cursor: pointer;
    background: #00a395;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    right: 0px;
    top: 100px;
    z-index: 100;
}
.title {
    width: 30px;
    height: 14px;
    text-align: center;
    color: white;
    font-size: 10px;
}
.title-img {
    width: 10px;
    height: 10px;
    margin-top: 5px;
    border-radius: 5px;
}
.headview {
    width: 100%;
    background-color: #0d8e7f;
    // position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
// .top-leftlogo {
//   widows: 120px;
//   height: 120px;
//   z-index: 100;
//   position: absolute; /*固定在顶部*/
//   top: 0;
//   left: 0px;
// }
// .top-leftlogo-image {
//   width: 70px;
//   height: 94px;
// }
.footerclass {
    // width: 1000px;
    width: 100%;
}
.el-main {
    width: 100%;
    padding: 0px !important;
    // overflow-y: scroll;
    height: calc(100vh - 70px);
    background-color: #ffffff;
}
.el-main::-webkit-scrollbar {
    display: none;
}
.el-header {
    height: 100px !important;
    padding: 0;
}
.foot-class {
    width: 100%;
    display: flex;
    background-color: #d8d8d8;
    justify-content: center;
}
</style>
