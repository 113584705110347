<template>
  <div class="bottom-contentview">
    <div class="bottom-redview"></div>
    <div class="bottom-contactview">
      <div class="bottom-contactview-titleview">
        <div class="bottom-contactview-title" @click="turnToHome">首 页</div>
      </div>
      <div class="bottom-contactview-titleview">
        <div class="bottom-contactview-title">关 于 我 们</div>
        <div class="bottom-contactview-content" @click="menuClicked('/aboutUs')">爱志万介绍</div>
        <div class="bottom-contactview-content" @click="menuClicked('/LeadersMessage')">总经理寄语</div>
        <div class="bottom-contactview-content" @click="menuClicked('/introCom')">母公司介绍</div>
        <div class="bottom-contactview-content" @click="menuClicked('/companyhistory')">爱志万大事记</div>
      </div>
      <div class="bottom-contactview-titleview">
        <div class="bottom-contactview-title">服 务 介 绍</div>
        <div class="bottom-contactview-content" @click="menuClicked('/bathingatHome')">到宅沐浴服务</div>
        <div class="bottom-contactview-content" @click="menuClicked('/dayCareCenters')">日间照料中心</div>
        <div class="bottom-contactview-content" @click="menuClicked('/homeService')">居家上门服务</div>
        <div class="bottom-contactview-content" @click="menuClicked('/conTraining')">教育咨询培训</div>
        <div class="bottom-contactview-content" @click="menuClicked('/healthManagement')">健康管理</div>
        <div class="bottom-contactview-content" @click="menuClicked('/cognitiveImpairment')">认知症干预服务</div>
        <div class="bottom-contactview-content" @click="menuClicked('/leasasles')">辅助租赁与销售</div>
        <div class="bottom-contactview-content" @click="menuClicked('/careinstitution')">养老机构代运营</div>
        <!-- <img class="bottom-contactview-img" src="https://earthone.com.cn/imageUrl/fuwu_bottom_img.png" alt=""> -->
      </div>
      <div class="bottom-contactview-titleview">
        <div class="bottom-contactview-title">展 示 中 心</div>
        <div class="bottom-contactview-content" @click="menuClicked('/imagewall')">爱志万照片墙</div>
        <div class="bottom-contactview-content" @click="menuClicked('/clientmind')">客户心路</div>
      </div>
      <div class="bottom-contactview-titleview">
        <div class="bottom-contactview-title">联 系 我 们</div>
        <div class="bottom-contactview-content" @click="menuClicked('/contactus')">联系方式</div>
        <div class="bottom-contactview-content" @click="menuClicked('/consultation')">合作咨询</div>
      </div>
      <div class="bottom-contactview-codeback">
        <div class="code_img_class_back">
          <img class="code_img_class" src="https://earthone.com.cn/imageUrl/gongzhonghao_code.png" alt="">
          <div class="code_str_class">公众号二维码</div>
        </div>
        <div class="code_img_class_back">
          <img class="code_img_class" src="https://earthone.com.cn/imageUrl/douyin_code.png" alt="">
          <div class="code_str_class">抖音号二维码</div>
        </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'app',
  data() {
    return {
    }
  },
  methods: {
    turnToHome() {
      this.$emit('reloadSelectUrl', '/homeView')
      this.$router.push('/')
    },
    menuClicked (path) {
      this.$emit('reloadSelectUrl', path)
      this.$router.push(path)
    }
  }
}
</script>

<style lang="less" scoped>
.bottom-contentview {
  /* width: 1000px; */
  width: 100%;
}

.bottom-contactview {
  /* width: 1000px; */
  width: 100%;
padding-bottom: 40px;
  background: #f5f5f5;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.bottom-redview {
  /* width: 1000px; */
  display: flex;
  left: 0;
  right: 0;
  width: 100%;
  height: 200px;
  background: url(https://earthone.com.cn/imageUrl/Framefooter-img.png);
  background-size: cover;
  background-position: center;
}
.bottom-contactview-titleview{
  width: 160px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.bottom-contactview-title{
  margin-top: 30px;
  font-size: 16px;
  cursor: pointer;
  font-weight: bolder;
  color: black;
}
.bottom-contactview-content{
  margin-top: 8px;
  font-size: 10px;
  cursor: pointer;
  color: rgb(55, 55, 55);
}
.bottom-contactview-content:hover{
  color: #00a395;
}
.bottom-contactview-img{
  margin-top: 8px;
  width: 70px;
  height: 17px;
}
.bottom-contactview-codeback{
  width: 160px;
  position: absolute;
  bottom: 30px;
  right: 140px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.code_img_class_back{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.code_str_class{
  width: 100%;
  text-align: center;
  font-size: 10px;
  margin-top: 10px;
  color: rgb(72, 72, 72);
}
.code_img_class{
    width: 60px;
    height: 60px;
}
</style>
