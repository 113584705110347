<template>
  <div class="top-backview">
      <div class="contnet-backview">
          <!-- banner图 -->
          <topBanner :bannerData="images" direction="right" lineColor="white"></topBanner>
          <!-- 中间部分内容 -->
          <div class="center-backview">
              <div class="introCom-content-titleback">
                  <div class="introCom-content-title">商业合作</div>
                  <div class="introCom-content-title-eng">
                    Business cooperation
                  </div>
              </div>
              <div class="daycar-content-str">
                养老服务市场在不断壮大的同时，爱志万也希望与业界同行深度合作。我们热忱欢迎各个行业的合作伙伴能够在双赢的前提下，走出合作的成功之路。
              </div>

              <div class="cooperation-back">
                <div class="introCom-content-titleback">
                  <div class="introCom-content-title" style="color: white;">公益合作</div>
                  <div class="introCom-content-line"></div>
                  <div class="daycar-content-str" style="color: white;">
                    我们以公益之力团结在一起，使更多长者享受有尊严、有价值、有意义的晚年时光！<br>欢迎公益组织、基金会、社会性公益团体联系我们，扶老助残的路上，与你携手，让世界多一点关爱！
                   </div>
              </div>
              </div>
              <div class="introCom-content-titleback">
                  <div class="introCom-content-title">异业合作</div>
                  <div class="introCom-content-title-eng">
                    Cross industry cooperation
                  </div>
              </div>
              <div class="daycar-content-str" style="margin-bottom: 40px;">
                异业合作是爱志万重视的市场推广手段之一，通过双方资源的互换和整合，获取双方品牌曝光和用户互引的效益，欢迎各行业的优秀品牌垂询商谈，合作共赢。              </div>
          </div>
      </div>
  </div>
</template>
<script>

export default {
  props: {
    dataHeight: {
      type: String,
      default: '100%'
    }
  },
  data () {
    return {
      images: [{ url: 'https://earthone.com.cn/imageUrl/cooperation_banner.jpg', titleStr: '合 作 咨 询', testStr: 'Collaborative consulting' }]
    }
  },
  mounted () {
  },
  methods: {
  }
}
</script>
<style lang="less" scoped>
.top-backview {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.contnet-backview {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}
.banner-backview {
  width: 1000px;
  height: 360px;
  position: relative;
}
.banner-testback {
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  left: 150px;
  top: 120px;
}
.banner-img-class {
  width: 1000px;
  height: 360px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.bannerclass {
  width: 100%;
  height: 360px;
  background-color: rgb(0, 0, 0);
  z-index: 99;
}
.banner-title-class {
  color: rgb(255, 255, 255);
  font-size: 26px;
  text-align: left;
  z-index: 100;
}
.banner-test-class {
  color: rgb(255, 255, 255);
  text-align: left;
  font-size: 10px;
}
.el-carousel {
  width: 1000px;
  height: 360px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}
.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.center-backview {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content-service-back {
  width: 700px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  .service-item {
      width: 220px;
      height: 200px;
      margin-bottom: 20px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  }
  .service-item-img {
      width: 150px;
      height: 150px;
      border-radius: 75px;
  }
  .service-item-title {
      width: 100%;
      margin-top: 10px;
      letter-spacing: 4px;
      font-size: 12px;
      color: black;
      font-weight: 800;
      text-align: center;
  }
}
.daycar-content-str {
    width: 800px;
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 1px;
    color: rgb(59, 59, 59);
    text-align: center;
}
.cooperation-back{
  width: 100%;
  margin-top: 40px;
  padding: 0px 0px 30px 0px;
  background-color: #00a395;
}
.introCom-content-line{
  width: 80px;
  height: 2px;
  margin-top: 10px;
  margin-bottom: 30px;
  background: white;
}
</style>
