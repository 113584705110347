<template>
  <div id="app">
    <!-- 路由占位符 -->
    <router-view :key="$router.path"></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
  data () {
    return {
      ismobile: false
    }
  },
  mounted () {
    if (this._isMobile()) {
      console.log('手机')
      this.ismobile = true
    } else {
      console.log('pc')
      this.ismobile = false
    }
  },
  methods: {
    _isMobile () {
      const flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
      return flag
    }
  }
}
</script>

<style>
@import 'assets/font/font.css';

.el-header {
  padding: 0;
}
.el-footer {
  padding: 0;
}
</style>
