<template>
    <div class="top-backview">
        <div class="contnet-backview">
            <!-- banner图 -->
            <topBanner :bannerData="images" direction="right" lineColor="white"></topBanner>
            <!-- 中间部分内容 -->
            <div class="center-backview">
                <div class="introCom-content-titleback">
                    <div class="introCom-content-title">服务流程</div>
                    <div class="introCom-content-title-eng">
                        Service process
                    </div>
                </div>
                <div class="bathing-content-str">
                    由一辆车、一个组合式专用浴缸、3~4名专业人员所组成的一个专业团队为失能老人提供专业的上门洗澡服务。在到达顾客家之后会进行下述准备工作：
                </div>
                <div class="process-back">
                    <div class="process-left"></div>
                    <div class="process-right-back">
                        <div
                            class="process-right"
                            v-for="(item, index) in processData"
                            :key="index"
                        >
                            <img
                                class="process-right-img"
                                src="@/assets/images/earthImages/process-right-img01.png"
                                alt=""
                            />
                            <div class="process-right-title">
                                {{ item.titleStr }}
                            </div>
                            <img
                                class="process-right-img2"
                                :src="item.contentImg"
                                alt=""
                            />
                            <div class="process-right-title2">
                                {{ item.contentStr }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="introCom-content-titleback">
                    <div class="introCom-content-title">行走的浴缸</div>
                    <div class="introCom-content-title-eng">
                        Walking bathtub
                    </div>
                </div>
                <div class="bathtub-content-str">
                    爱志万所使用的专用组合式浴缸能在没有电梯的住宅区里也能使用，更便捷的为老年人提供上门洗澡服务。<br>
                    浴缸内部的水无间断循环排出，故能时刻确保浴缸内水质的清洁！与浴缸配套的水龙头在入浴中也能对水温进行调节。另外，浴缸本身在材质上具备红外线效<br>
                    果，故在入浴过程中能有效确保浴缸内水温的维持。
                </div>
                <div class="walking-bathtub">
                    <img class="walking-bathtub-img" src="@/assets/images/earthImages/bathtub_001.png" alt="">
                    <img class="walking-bathtub-img" src="@/assets/images/earthImages/bathtub_002.png" alt="">
                    <img class="walking-bathtub-img" src="@/assets/images/earthImages/bathtub_003.png" alt="">
                </div>
                <div class="introCom-content-titleback">
                    <div class="introCom-content-title">三大作用</div>
                    <div class="introCom-content-title-eng">
                        Three major functions
                    </div>
                </div>
                <div class="functions-back">
                    <div class="functions-content-back">
                        <img class="functions-content-back-img" src="@/assets/images/earthImages/function_01.png" alt="">
                        <div class="functions-content-back-title">温热作用</div>
                        <div class="functions-content-back-desc">通过沐浴，皮肤连带皮下血管与血液随之变暖。血管扩张血液流量增加，变暖的血液可温暖全身。只进行手足浴也有使全身变暖的效果。因此也有促进肌肉血液流动而便于身体活动的效果。</div>
                    </div>
                    <div class="functions-content-back">
                        <img class="functions-content-back-img" src="@/assets/images/earthImages/function_02.png" alt="">
                        <div class="functions-content-back-title">浮力·粘性作用</div>
                        <div class="functions-content-back-desc">由于水的浮力作用，身体会变轻，有放松效果。对于身体运动机能较低的人来说，可利用浮力・粘性来进行康复训练、水中运动。在水中，关节、肌肉的负担或痛感减轻，活动将更加容易。</div>
                    </div>
                    <div class="functions-content-back">
                        <img class="functions-content-back-img" src="@/assets/images/earthImages/function_03.png" alt="">
                        <div class="functions-content-back-title">静水压作用</div>
                        <div class="functions-content-back-desc">静水压对下半身施加压力，使返回心脏的流量增加，由心脏挤压出的心拍量会增加。从而促进全身的血液循环、肌肉内积累的疲劳物质（乳酸等）被排出，可更快的去除疲劳。</div>
                    </div>
                </div>
                <div class="introCom-content-titleback">
                    <div class="introCom-content-title">专业团队</div>
                    <div class="introCom-content-title-eng">
                        Professional team
                    </div>
                </div>
                <div class="team-back">
                    <div class="team-back-desc">由3~4名专业人员（包括护士）组成的团队对顾客进行符合顾客现状的入浴提案。只要家中能确保3㎡的空间就能使用我们上门洗澡的服务！针对由于因房型及居住环境等苦恼不能洗澡的顾客，我们也有很多丰富的经验技术来对应解决。</div>
                    <img class="team-back-img" src="@/assets/images/earthImages/team_right.png" alt="">
                </div>
                <div class="introCom-content-titleback">
                    <div class="introCom-content-title">市场占有率No.1</div>
                    <div class="introCom-content-title-eng">
                        Market share No.1
                    </div>
                </div>
                <div class="Market-share-back">
                    <div class="Market-share-title">“到宅沐浴”起源于40多年前日本的爱志公司（爱志万的母公司）。在2000年成为日本介护保险里面的一个服务项目。一直以来，爱志公司在到宅沐浴事业市场占有率中一直位居「日本国内第一」。</div>
                    <img class="Market-share-img" src="@/assets/images/earthImages/market-share.png" alt="">
                </div>
                <div class="introCom-content-titleback">
                    <div class="introCom-content-title">沐浴器械</div>
                    <div class="introCom-content-title-eng">
                        Bathing equipment
                    </div>
                </div>
                <div class="bathing-back">
                    <div class="bathing-back-content">
                        <div class="bathing-back-content-imgback">
                            <img class="bathing-back-content-img" src="@/assets/images/earthImages/bathing_01.png" alt="">
                        </div>
                        <div class="bathing-back-content-title">到宅沐浴浴缸</div>
                        <div class="bathing-back-content-desc">日本进口</div>
                    </div>
                    <div class="bathing-back-content">
                        <div class="bathing-back-content-imgback">
                            <img class="bathing-back-content-img" src="@/assets/images/earthImages/bathing_02.png" alt="">
                        </div>
                        <div class="bathing-back-content-title">到宅沐浴浴缸</div>
                        <div class="bathing-back-content-desc">日本进口</div>
                    </div>
                    <div class="bathing-back-content">
                        <div class="bathing-back-content-imgback">
                            <img class="bathing-back-content-img" src="@/assets/images/earthImages/bathing_03.png" alt="">
                        </div>
                        <div class="bathing-back-content-title">到宅沐浴浴缸</div>
                        <div class="bathing-back-content-desc">日本进口</div>
                    </div>
                    <div class="bathing-back-content">
                        <div class="bathing-back-content-imgback">
                            <img class="bathing-back-content-img" src="@/assets/images/earthImages/bathing_04.png" alt="">
                        </div>
                        <div class="bathing-back-content-title">到宅沐浴浴缸</div>
                        <div class="bathing-back-content-desc">日本进口</div>
                    </div>
                </div>
                <div class="bathing_bottom">
                    <div class="bathing-bottom-desc">特征：触感柔软，耐热，不担心生锈、腐蚀，耐久性好。保湿性高，容易清洗等保养。据说“比铁更强，比铝轻”，被用于大型圆顶屋顶等。</div>
                    <div class="bathing-bottom-desc">效果：通过远红外线效果，从身体内部温暖</div>
                    <div class="bathing-bottom-desc">功能：在浴缸旁边可以调节水温，新的水可以不断循环的构造</div>
                    <div class="bathing-bottom-desc">种类：一体式浴缸，分体式浴缸</div>
                    <div class="bathing-bottom-desc">附属用品：担架，排水泵，进出水管等</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  props: {
    dataHeight: {
      type: String,
      default: '100%'
    }
  },
  data () {
    return {
      images: [{ url: 'https://earthone.com.cn/imageUrl/bathing_athome_banner.jpg', titleStr: '到 宅 沐 浴 服 务', testStr: 'Home bathing service' }],
      determinationImg: 'https://earthone.com.cn/imageUrl/determination.jpg',
      processData: [
        {
          titleStr: 'STEP01',
          contentImg: 'https://earthone.com.cn/imageUrl/process-con01.png',
          contentStr: '由护士进行体温及血压等的测量，在确认顾客的排便及伤口（如有）状况后来判断是否适合入浴。另外根据顾客的要求，还能提供剪手脚指甲的服务。'
        },
        {
          titleStr: 'STEP02',
          contentImg: 'https://earthone.com.cn/imageUrl/process-con02.png',
          contentStr: '准备沐浴环境，必要时需要协助移动家具。会事先铺设防水塑料布、设置浴缸、调试水温及室温、采取保护隐私措施等一系列准备工作。'
        },
        {
          titleStr: 'STEP03',
          contentImg: 'https://earthone.com.cn/imageUrl/process-con03.png',
          contentStr: '把老人移动至浴缸内。先由同性员工协助脱衣，针对全失能老人会采取减轻老人负担的移动方式，由3名员工一起协助抱起进行移动。'
        },
        {
          titleStr: 'STEP04',
          contentImg: 'https://earthone.com.cn/imageUrl/process-con04.png',
          contentStr: '入浴包括洗脸、洗头、洗全身的三个环节。从头到脚仔细的进行擦洗。'
        },
        {
          titleStr: 'STEP05',
          contentImg: 'https://earthone.com.cn/imageUrl/process-con05.png',
          contentStr: '入浴后由护士再次确认健康状况。其余人员负责用消毒液清洗浴缸，恢复顾客家中原有环境。'
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.contnet-backview {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
}
.banner-title-class {
    color: rgb(255, 255, 255);
    font-size: 26px;
    text-align: right;
    z-index: 100;
}
.banner-test-class {
    color: rgb(255, 255, 255);
    text-align: right;
    font-size: 10px;
}
.el-carousel {
    width: 1000px;
    height: 360px;
}
.el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
}
.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}
.el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
}
.center-backview {
    width: 100%;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.bathing-content-str {
    width: 750px;
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 1px;
    font-family: 'albbpht';
    color: rgb(0, 0, 0);
    text-align: center;
}
.process-back {
    width: 750px;
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    .process-left {
        width: 2px;
        margin-left: 10px;
        margin-top: -15px;
        height: 560px;
        background-color: #00a395;
    }
    .process-right-back {
        width: 740px;
        margin-left: -13px;
        display: flex;
        flex-direction: column;
    }
    .process-right {
        width: 740px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        .process-right-img {
            width: 24px;
            height: 24px;
        }
        .process-right-title {
            width: 80px;
            font-size: 15px;
            color: #00a395;
            letter-spacing: 2px;
            margin-left: 10px;
        }
        .process-right-img2 {
            width: 190px;
            height: 101px;
            margin-left: 15px;
            margin-bottom: 20px;
        }
        .process-right-title2 {
            width: 400px;
            font-size: 11px;
            margin-left: 15px;
            font-family: 'albbpht';
            letter-spacing: 2px;
            line-height: 18px;
        }
    }
}
.bathtub-content-str{
    width: 750px;
    text-align: center;
    font-family: 'albbpht';
    letter-spacing: 1px;
    line-height: 16px;
    font-size: 10px;
}
.walking-bathtub{
    width: 690px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 40px;
    .walking-bathtub-img{
        width: 230px;
        height: 142px;
    }
}
.functions-back{
    width: 700px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    .functions-content-back{
        width: 200px;
        padding: 20px 0px;
        background: white;
        box-shadow: 2px 2px 8px rgba(106, 106, 106, 0.2);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        .functions-content-back-img{
            width: 80px;
            height: 80px;
        }
        .functions-content-back-title{
            width: 100%;
            margin: 10px;
            font-size: 14px;
            color: black;
            letter-spacing: 4px;
            text-align: center;
        }
        .functions-content-back-desc{
            width: 90%;
            font-size: 10px;
            color: black;
            letter-spacing: 1px;
            font-family: 'albbpht';
            text-align: center;
            line-height: 20px;
        }
    }
}
.team-back {
    width: 640px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    .team-back-desc{
        width: 300px;
        font-size: 10px;
        line-height: 25px;
        letter-spacing: 1px;
        font-weight: 400;
        color: rgb(63, 63, 63);
        text-align: left;
    }
    .team-back-img{
        width: 300px;
        height: 201px;
    }
}
.Market-share-back{
    width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .Market-share-title{
        width: 100%;
        font-size: 10px;
        letter-spacing: 2px;
        color: rgb(56, 56, 56);
        text-align: center;
        line-height: 16px;
    }
    .Market-share-img{
        margin: 30px ;
        width: 400px;
        height: 98px;
    }
}
.bathing-back-content-imgback{
    width: 165px;
    height: 123px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 2px 8px rgba(106, 106, 106, 0.5);
}
.bathing-back{
    width: 700px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    .bathing-back-content{
        width: 160;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .bathing-back-content-img{
            width: 155px;
            height: 113px;
        }
        .bathing-back-content-title{
            width: 100%;
            font-size: 10px;
            letter-spacing: 2px;
            font-weight: 700;
            margin-top: 10px;
            text-align: center;
            color: black;
        }
        .bathing-back-content-desc{
            width: 100%;
            text-align: center;
            font-size: 10px;
            color: rgb(109, 109, 109);
        }
    }
}
.bathing_bottom{
    width: 660px;
    margin-bottom: 40px;
    margin-top: 20px;
    padding: 15px 15px 0px 15px;
    background: rgb(245, 245, 245);
    color: rgb(67, 67, 67);
    .bathing-bottom-desc{
        font-size: 10px;
        text-align: left;
        margin-bottom: 15px;
    }
}
</style>
