var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"top-backview"},[_c('div',{staticClass:"contnet-backview"},[_c('topBanner',{attrs:{"bannerData":_vm.images,"direction":"left","lineColor":"white"}}),_c('div',{staticClass:"center-backview"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"SwiperBox",on:{"mouseenter":function($event){return _vm.MouseFun('移入')},"mouseleave":function($event){return _vm.MouseFun('移出')}}},[_c('div',{staticClass:"img-back"},_vm._l((_vm.topBannerImgList),function(item,index){return _c('img',{key:index,class:[
                            'imgCss',
                            _vm.ShowImg == index ? 'ShowCss' : ''
                        ],attrs:{"src":item.url}})}),0),_c('div',{staticClass:"leftBtn",on:{"click":function($event){return _vm.throttle(_vm.PrevFun)}}},[_vm._v("←")]),_c('div',{staticClass:"rightBtn",on:{"click":function($event){return _vm.throttle(_vm.NextFun)}}},[_vm._v(" → ")]),_c('div',{staticClass:"instBox"},_vm._l((_vm.bannerImgList.length),function(item,index){return _c('div',{key:index,class:[
                            'inst',
                            _vm.ShowImg == index ? 'instActv' : ''
                        ],on:{"click":function($event){_vm.ShowImg = index}}})}),0)]),_c('div',{staticClass:"horizontal-scroll-container"},[_c('div',{staticClass:"horizontal-scroll-content"},_vm._l((_vm.bannerImgList),function(item,index){return _c('div',{key:index,staticClass:"scroll-item",on:{"click":function($event){return _vm.bottomClicked(index)}}},[_c('div',{staticClass:"scroll-item-img",style:('background-image: url(' + item.url + ');')})])}),0)]),_c('div',{staticClass:"product-center-backview"},[_c('div',{staticClass:"product-center-menu"},[_c('div',{staticClass:"product-center-menu-title"},[_vm._v("产品中心")]),_vm._l((_vm.productList),function(item,index){return _c('div',{key:index,class:_vm.selectIndex === index
                                ? 'product-center-menu-item-active'
                                : 'product-center-menu-item',on:{"mouseenter":function($event){return _vm.productMouseFun(index)},"mouseleave":function($event){return _vm.productMouseFun(0)},"click":function($event){return _vm.productClicked(index)}}},[_vm._v(" "+_vm._s(item.title)+" ")])})],2),_c('div',{staticClass:"product-center-strback"},_vm._l((_vm.showProduct.descList),function(childItem,itemIndex){return _c('div',{key:itemIndex,staticClass:"product-str-item"},[_c('div',{staticClass:"product-str-title"},[_vm._v(" "+_vm._s(childItem.childTitle)+" ")]),_c('div',{staticClass:"product-str-desc",domProps:{"innerHTML":_vm._s(childItem.childDesc)}})])}),0),_c('div',{staticClass:"product-center-imgback"},[_c('img',{staticClass:"product-center-img",attrs:{"src":_vm.showProduct.image,"alt":""}})])]),_c('div',{staticClass:"rehabilitation-strback"},[_vm._m(2),_vm._l((_vm.introComDesc),function(item,index){return _c('div',{key:index,staticClass:"introCom-back"},[_c('div',{staticClass:"introCom-title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"introCom-desc",domProps:{"innerHTML":_vm._s(item.desc)}})])}),_c('img',{staticClass:"rehabilitation-img",attrs:{"src":"https://earthone.com.cn/imageUrl/rehabilitation.jpg","alt":""}})],2)])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"introCom-content-titleback"},[_c('div',{staticClass:"introCom-content-title"},[_vm._v("辅具租赁与销售")]),_c('div',{staticClass:"introCom-content-title-eng"},[_vm._v(" Leasing and sales of auxiliary equipment ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"daycar-content-str"},[_vm._v(" 《中国康复辅助器具目录》里有12个主类，93个次类，538个支类。而实际常用的、大众接受度较高的是以下《五大类》辅具产品：1，移动类辅具、2，医"),_c('br'),_vm._v("疗类辅助器具、3，生活自理和防护辅助器具、4，假肢矫形器、5，家具及其配件。（来源：《上海市康复辅助器具社区租赁产品目录》） "),_c('br'),_vm._v("为了对产品有深刻的认识，针对这五大类产品作详细的介绍。 ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"introCom-content-titleback"},[_c('div',{staticClass:"introCom-content-title"},[_vm._v(" 上海市康复辅具社区租赁项目 ")]),_c('div',{staticClass:"introCom-content-title-eng"},[_vm._v(" Shanghai Rehabilitation Aids Community Rental Project ")])])}]

export { render, staticRenderFns }