<template>
    <div class="top-backview">
        <div class="contnet-backview">
            <!-- banner图 -->
            <topBanner :bannerData="images" direction="right" lineColor="yellow"></topBanner>

            <!-- 中间部分内容 -->
            <div class="center-backview">
                <div class="top-content-back">
                    <div class="center-title-class" style="letter-spacing: 2px">
                        我们珍惜每个人的尊严
                    </div>
                    <div class="center-title-class">
                        <span style="margin-left: 20px"></span
                        ><span style="letter-spacing: 2px; margin-right: 20px"
                            >“点燃生命之希望的企业”</span
                        >——<span style="letter-spacing: 2px">公司理念</span>
                    </div>
                    <div class="introCom-content">
                        <div class="introCom-content-str">
                            日本已经迈入了世界上任何国家都没有经历过的超老龄化社会。<br />2040年，老龄化率将超过35%，也就是说每三人中就有一人是65岁以上的老年人。<br />“照护”已经成为关乎到所有人的大事。
                        </div>
                        <img
                            class="introCom-content-img"
                            src="https://earthone.com.cn/imageUrl/intruduct-center002.png"
                            alt=""
                        />
                    </div>
                    <div class="introCom-content-imgback">
                        <img
                            class="introCom-content-img002"
                            src="https://earthone.com.cn/imageUrl/intruduct-center003.png"
                            alt=""
                        />
                        <img
                            class="introCom-content-img003"
                            src="https://earthone.com.cn/imageUrl/intruduct-center001.png"
                            alt=""
                        />
                    </div>
                </div>

                <div class="introCom-content-titleback1">
                    <div class="introCom-content-title">爱志使命</div>
                    <div class="introCom-content-title-eng">
                        Company mission
                    </div>
                </div>
                <div class="introCom-content-comstr">
                    我们爱志的使命是创造一个能够安心生活的社会环境，让所有的人即使到了生命的最后时刻，都可以享受有尊严，有价值的人生。
                    “享用美食”“做喜欢的事”和“朋友一起度过开心的时光”…希望每个人无论到了什么时候，都能够活出自我，活出风采。这是我们的心愿。
                    当有一天，自己突然需要人照护的时候，很多人都会对未来感到不安。或许，有很多人正在苦恼从哪里着手解决。我们作为专业的“照护者”，正朝着打造让所有人感到放心和信赖的企业，这一目标努力奋斗。
                </div>
                <img
                    class="introCom-content-comstr-img"
                    src="https://earthone.com.cn/imageUrl/company_mission.jpg"
                    alt=""
                />
                <div class="introCom-content-titleback1">
                    <div class="introCom-content-title">基本方针</div>
                    <div class="introCom-content-title-eng">Basic policy</div>
                </div>
                <div class="introCom-content-policy">
                    <div class="content-policy-back">
                        <img
                            class="policy-img"
                            src="https://earthone.com.cn/imageUrl/basic_policy01.png"
                            alt=""
                        />
                        <div class="policy-text">高品质</div>
                    </div>
                    <div class="content-policy-back">
                        <img
                            class="policy-img"
                            src="https://earthone.com.cn/imageUrl/basic_policy02.png"
                            alt=""
                        />
                        <div class="policy-text">迅速</div>
                    </div>
                    <div class="content-policy-back">
                        <img
                            class="policy-img"
                            src="https://earthone.com.cn/imageUrl/basic_policy03.png"
                            alt=""
                        />
                        <div class="policy-text">安全放心</div>
                    </div>
                </div>
                <div class="introCom-content-idea">
                    <div class="introCom-content-titleback1">
                        <div class="introCom-content-title">经营理念</div>
                        <div class="introCom-content-title-eng">
                            management idea
                        </div>
                    </div>
                    <div class="content-idea-center">
                        <img
                            class="content-idea-img"
                            src="https://earthone.com.cn/imageUrl/Frameidea_img.jpg"
                            alt=""
                        />
                        <div class="content-idea-center-text">
                            <span>
                                我们在努力地创造一个社会环境，<br>
                                让生活在这里的所有人都可以持续地住在自己习惯的生活环境里，<br>
                                按照他们自己的生活方式，有尊严，有价值地生活。<br /><br
                            /></span>
                            <span
                                >爱志日本自1996年11月在东京都涩谷区开业，目前在国内有服务网点500处、服务项目30种，其中“到宅沐浴”的规模占全日本国内第一。在日本针对125万人次的顾客调查中，爱志集团提供的服务顾客满意度达到90%以上。</span
                            >
                        </div>
                    </div>
                    <div class="content-idea-center-person">爱志社长</div>
                </div>
                <div class="introCom-content-titleback1">
                    <div class="introCom-content-title">爱志服务</div>
                    <div class="introCom-content-title-eng">Service</div>
                </div>
                <img
                    class="service-img-class"
                    src="https://earthone.com.cn/imageUrl/service-img.jpg"
                    alt=""
                />
            </div>
        </div>
    </div>
</template>
<script>

export default {
  props: {
    dataHeight: {
      type: String,
      default: '100%'
    }
  },
  data () {
    return {
      images: [{ url: 'https://earthone.com.cn/imageUrl/introduct_banner.jpg', titleStr: '母 公 司 介 绍', testStr: 'INTRODUCE' }],
      determinationImg: 'https://earthone.com.cn/imageUrl/determination.jpg'
    }
  }
}
</script>
<style lang="less" scoped>
.marginTop {
    margin-top: 30px;
}
.introCom-content {
    width: 600px;
    padding: 30px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.introCom-content-imgback {
    width: 700px;
    margin-top: -100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
}
.introCom-content-str {
    width: 360px;
    font-size: 10px;
    color: black;
    line-height: 20px;
    letter-spacing: 2px;
}
.introCom-content-img {
    width: 250px;
    height: 147px;
}
.introCom-content-img002 {
    width: 171px;
    height: 220px;
    margin-right: 50px;
}
.introCom-content-img003 {
    width: 242px;
    height: 300px;
    margin-right: 100px;
}
.top-backview {
    width: 100%;
    height: auto;

    display: flex;
    align-items: center;
    flex-direction: column;
}
.contnet-backview {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
}
.banner-backview {
    width: 1000px;
    height: 360px;
    position: relative;
}
.banner-testback {
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: absolute;
    right: 170px;
    top: 120px;
}
.banner-img-class {
    width: 1000px;
    height: 360px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.bannerclass {
    width: 100%;
    height: 360px;
    background-color: rgb(0, 0, 0);
    z-index: 99;
}
.banner-title-class {
    color: rgb(255, 255, 255);
    font-size: 26px;
    text-align: right;
    z-index: 100;
}
.banner-test-class {
    color: rgb(255, 255, 255);
    text-align: right;
    font-size: 10px;
}
.el-carousel {
    width: 1000px;
    height: 360px;
}
.el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
}
.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}
.el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
}
.center-backview {
    width: 100%;

    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.top-content-back{
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: auto;
    background-image: url(https://earthone.com.cn/imageUrl/content_back.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.center-title-class {
    width: 550px;
    font-size: 14px;
    font-weight: 800;
    color: #00a395;
    text-align: left;
}
.determination-back {
    width: 700px;
    margin-bottom: 60px;
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.introCom-content-comstr {
    width: 600px;
    letter-spacing: 4px;
    font-family: 'albbpht';
    margin: 10px 0px;
    font-size: 10px;
    color: black;
    color: black;
}
.introCom-content-comstr-img {
    width: 600px;
    height: 253px;
}
.introCom-content-policy {
    width: 700px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .content-policy-back {
        width: 180px;
        margin: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .policy-text {
            width: 100%;
            text-align: center;
            color: #00a395;
            font-size: 12px;
            margin-top: 10px;
            letter-spacing: 2px;
            height: auto;
        }
        .policy-img {
            width: 80px;
            height: 80px;
        }
    }
}
.introCom-content-idea {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f9f9f9;
}
.content-idea-center {
    width: 600px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .content-idea-img {
        width: 240px;
        height: 150px;
    }
    .content-idea-center-text {
        width: 340px;
        font-family: 'albbpht';
        font-size: 10px;
        line-height: 16px;
        color: black;
        letter-spacing: 2px;
    }
}
.content-idea-center-person {
    width: 600px;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 40px;
    font-size: 10px;
    letter-spacing: 2px;
    color: rgb(80, 80, 80);
}
.service-img-class {
    width: 550px;
    height: 680px;
    margin: 20px 0px;
}
</style>
