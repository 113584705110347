<template>
  <div class="top-backview">
      <div class="contnet-backview">
          <!-- banner图 -->
          <topBanner :bannerData="images" direction="left" lineColor="white"></topBanner>

          <!-- 中间部分内容 -->
          <div class="center-backview">
              <div class="introCom-content-titleback">
                  <div class="introCom-content-title">健康管理</div>
                  <div class="introCom-content-title-eng">
                    Health management
                  </div>
              </div>
              <div class="daycar-content-str">
                爱志万母公司旗下的“MEDICAL TRUST”是日本著名的健康管理公司。 2001年成立于日本东京。为上千家的企业提供专业的健康管理服务。              </div>
              <div
                  class="bottom-banner-img"
                  :style="'background-image: url(' + bannerSelect + ');'"
              ></div>
              <div class="bottom-content-back">
                <div class="bottom-content-back-title">健康服务</div>
                <div class="bottom-content-back-title-eng">Health services</div>
                <div class="bottom-content-back-desc">帮助企业管理层以及特殊岗位员工通过建立健康个案管理方式，提供针对体检结果的健康指导 (日本产业医生远程咨询)，营养指导 (日本营养师远程和中国营养师管理)，亚健康生活方式改善指导等服务。通过建立健康生活方式，从而达到提高工作效率和个人生活品质的目的。</div>
              </div>
          </div>
      </div>
  </div>
</template>
<script>

export default {
  props: {
    dataHeight: {
      type: String,
      default: '100%'
    }
  },
  data () {
    return {
      bannerSelect: 'https://earthone.com.cn/imageUrl/management_001.png',
      images: [{ url: 'https://earthone.com.cn/imageUrl/health_man_banner.png', titleStr: '健 康 管 理', testStr: 'Health management' }]
    }
  },
  methods: {
  }
}
</script>
<style lang="less" scoped>
.marginTop {
  margin-top: 30px;
}
.top-backview {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.contnet-backview {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}
.banner-backview {
  width: 1000px;
  height: 360px;
  position: relative;
}
.banner-testback {
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  left: 200px;
  top: 120px;
}
.banner-img-class {
  width: 1000px;
  height: 360px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.bannerclass {
  width: 100%;
  height: 360px;
  background-color: rgb(0, 0, 0);
  z-index: 99;
}
.banner-title-class {
  color: rgb(255, 255, 255);
  font-size: 26px;
  text-align: center;
  z-index: 100;
}
.banner-test-class {
  color: rgb(255, 255, 255);
  text-align: center;
  font-size: 10px;
}
.el-carousel {
  width: 1000px;
  height: 360px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}
.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.center-backview {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.daycar-content-str {
  width: 700px;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 1px;
  color: rgb(59, 59, 59);
  text-align: center;
}
.bottom-banner-img {
  width: 700px;
  height: 295px;
  margin: 30px 0px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.bottom-content-back{
  width: 100%;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(https://earthone.com.cn/imageUrl/bottom-content-backimg.jpg);
  .bottom-content-back-title{
    width: 700px;
    font-size: 14px;
    letter-spacing: 1px;
    color: white;
    font-weight: 600;
  }
  .bottom-content-back-title-eng{
    width: 700px;
    margin-bottom: 20px;
    letter-spacing: 1px;
    font-size: 10px;
    color: white;
  }
  .bottom-content-back-desc{
    width: 700px;
    margin-top: 5px;
    line-height: 18px;
    letter-spacing: 6px;
    font-size: 10px;
    color: white;
  }
}
</style>
