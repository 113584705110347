<template>
    <div class="topBox">
        <!-- 左侧logo -->
        <div class="top-search-view">
            <img
                src="https://earthone.com.cn/imageUrl/logo.png"
                alt=""
                class="top-leftlogo-image"
                @click="goTohome()"
            />
            <div class="search-view" style="background: white;">
                <!-- <img
                    class="search-img"
                    src="https://earthone.com.cn/imageUrl/Framesearch.png"
                    alt=""
                />
                <input
                    type="text"
                    class="search-input"
                    placeholder="请输入检索内容"
                /> -->
            </div>
            <div class="search-right-view">
                <img
                    class="phone-image margin-right"
                    src="https://earthone.com.cn/imageUrl/Framewechat.png"
                    alt=""
                />
                <img
                    class="phone-image"
                    src="https://earthone.com.cn/imageUrl/Framephone.png"
                    alt=""
                />
                <div class="right-information">
                    <div class="phone-class">021-54561388</div>
                    <div class="phone-str">接待时间：周一至周六 8:30~17:30</div>
                </div>
            </div>
        </div>
        <!-- 顶部导航栏 -->
        <div class="top-tabview">
            <el-menu
                class="my-menu"
                :default-active="activeUrl"
                mode="horizontal"
                background-color="#06998a"
                text-color="#ffffff"
                active-text-color="#ffffff"
                router
            >
                <template v-for="(item, i) of menuList">
                    <el-submenu v-if="item.childList" :index="i" :key="i">
                        <template slot="title">{{ item.title }}</template>
                        <template v-for="(child, j) of item.childList">
                            <el-menu-item
                                v-if="child.linkStr !== 'back'"
                                :key="j"
                                :index="child.linkStr"
                                >{{ child.title }}</el-menu-item
                            >
                        </template>
                    </el-submenu>
                    <el-menu-item v-else :key="i + 1000" :index="item.linkStr">{{
                        item.title
                    }}</el-menu-item>
                    <div v-if="i !== menuList.length - 1" class="center-line" :key="i + 2000"></div>
                </template>
            </el-menu>
        </div>
    </div>
</template>

<script>
export default {
  name: 'app',
  data () {
    return {
      searchText: '',
      nowMenuList: [],
      lastMenuList: [],
      screenWidth: document.body.clientWidth,
      isWidth: true,
      activeUrl: '/homeView',
      uniqueOpened: false,
      menuList: [
        {
          id: '1',
          title: '首页',
          linkStr: '/homeView'
        },
        {
          id: '2',
          title: '关于我们',
          linkStr: '/aboutUs',
          childList: [
            {
              title: '爱志万介绍',
              linkStr: '/aboutUs'
            },
            { title: '总经理寄语', linkStr: '/LeadersMessage' },
            { title: '母公司介绍', linkStr: '/introCom' },
            { title: '爱志万大事记', linkStr: '/companyhistory' }
          ]
        },
        {
          id: '3',
          title: '服务介绍',
          linkStr: '',
          childList: [
            {
              title: '到宅沐浴服务',
              linkStr: '/bathingatHome'
            },
            { title: '日间照料中心', linkStr: '/dayCareCenters' },
            { title: '居家上门服务', linkStr: '/homeService' },
            { title: '教育咨询培训', linkStr: '/conTraining' },
            { title: '健康管理', linkStr: '/healthManagement' },
            { title: '认知症干预服务', linkStr: '/cognitiveImpairment' },
            { title: '辅助租赁与销售', linkStr: '/leasasles' },
            { title: '养老机构代运营', linkStr: '/careinstitution' }
          ]
        },
        {
          id: '4',
          title: '展示中心',
          linkStr: '',
          childList: [
            {
              title: '爱志万照片墙',
              linkStr: '/imagewall'
            },
            { title: '客户心路', linkStr: '/clientmind' }
          ]
        },
        {
          id: '5',
          title: '联系我们',
          linkStr: '/contactus',
          childList: [
            {
              title: '联系方式',
              linkStr: '/contactus'
            },
            { title: '合作咨询', linkStr: '/consultation' }
          ]
        }
      ]
    }
  },
  watch: {
    screenWidth: {
      handler: function (val) {
        if (val < 1050) {
          this.isWidth = false
        } else {
          this.isWidth = true
        }
      },
      immediate: true
    }
  },
  mounted () {
    this.screenWidth = document.body.clientWidth
    this.nowMenuList = this.menuList
    window.onresize = () => {
      this.screenWidth =
                window.innerWidth ||
                document.documentElement.clientWidth ||
                document.body.clientWidth
    }
  },
  methods: {
    reloadActiveIndex (selectUrl) {
      this.activeUrl = selectUrl
    },
    // 搜索点击事件
    handleSearch () {
      console.log(this.searchText)
    },
    handleSelect (key, keyPath) {
      console.log(key, keyPath)
    },
    goTohome () {
      this.$router.push('/')
    },
    LookOrderGrab () {
      window.open('http://t1.ink/f/xzacwe')
    },
    handleCommand (command) {
      console.log('nowMenuList', this.nowMenuList)
      const selectMenu = this.nowMenuList[command]
      if (selectMenu.linkStr === 'back') {
        this.nowMenuList = this.lastMenuList
      } else if (selectMenu.linkStr === 'childMenu') {
        // 只支持二级菜单，如果有三级菜单还需要完善lastMenuList逻辑
        this.lastMenuList = this.nowMenuList
        this.nowMenuList = selectMenu.childList
      } else {
        this.$router.push(selectMenu.linkStr)
      }
    },
    menuClicked () {
      this.nowMenuList = this.menuList
    },
    handleToLog () {
      // const prefix = process.env.NODE_ENV === 'production' ? '' : 'test.'
      // // registeredContent
      // const url = process.env.NODE_ENV === 'development' ? `http://${window.location.host}/#/loginview` : `http://${prefix}ghacedu.com/#/loginview`

      // window.open(url)
      this.$router.push('/loginview')
    }
  }
}
</script>

<style lang="less" scoped>
.topBox {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100px;
    background: white;
}
.center-line{
  width: 1px;
  height: 10px;
  margin-top: 10px;
  background: white;
}
.top-search-view {
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    .top-leftlogo-image {
        width: auto;
        height: 40px;
        margin: 0px 80px;
    }
    .search-view {
        width: 330px;
        height: 30px;
        margin-right: 20px;
        box-sizing: border-box;
        padding: 3px 15px;
        border-radius: 15px;
        background: #b9b9b9;
        display: flex;
        flex-direction: row;
        align-items: center;
        .search-img {
            width: 16px;
            height: 16px;
        }
        .search-input {
            width: 260px;
            margin-left: 10px;
            font-size: 12px;
            border: #b9b9b9;
            background: #b9b9b9;
            color: white;
            height: 100%;
        }
        .search-input:focus {
            outline: none;
        }
        input::-webkit-input-placeholder {
            color: white;
        }
    }
    .search-right-view {
        display: flex;
        flex-direction: row;
        align-items: center;
        .phone-image {
            width: 30px;
            height: 30px;
            margin-right: 5px;
        }
        .margin-right {
            margin-right: 20px;
        }
        .right-information {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            .phone-class {
                font-size: 14px;
                font-weight: bolder;
                color: black;
            }
            .phone-str {
                font-size: 10px;
                color: #464646;
            }
        }
    }
}

.el-input > .el-input__inner {
    background-color: rgba(255, 255, 255, 0.247) !important;
}

/* .top-leftPlace {
  width: 400px;
  background-color: green;
  height: 70px;
} */
.top-tabview {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #06998a;
}
/* 一级菜单鼠标划过 */
.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
    outline: 0 !important;
    background: none !important;
}
/* 二级菜单鼠标划过 */
.el-menu .el-menu-item:hover {
    outline: 0 !important;
    color: #000000 !important;
    background: none !important;
    border-bottom-color: #ffffff !important;
}
.el-menu--horizontal .el-menu .el-menu-item:hover {
    background: #ffffff !important;
}
/* 三级菜单鼠标划过 */
.el-menu .el-submenu .el-menu-item:hover {
    color: #000000 !important;
    background: #ffffff !important;
}
.my-menu {
    border: none;
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: center;
    .el-menu-item,
    .el-submenu__title {
        height: 30px;
        padding: 0px 30px !important;
        line-height: 30px;
    }
}
.el-menu /deep/ .el-menu--horizontal[data-v-458ce5ce] {
    border-bottom: none;
}
.el-menu--horizontal /deep/ .el-submenu .el-submenu__title {
    height: 30px !important;
    line-height: 30px !important;
    padding: 0px 30px !important;
}
.el-menu--horizontal /deep/ .el-submenu .el-submenu__title:hover {
    height: 30px !important;
    line-height: 30px !important;
    border-bottom-color: #ffffff !important;
    background: none !important;
}
.top-login {
    position: absolute;
    right: 40px;
    top: 10px;
    font-size: 12px;
    color: #fff;
    text-align: center;
    cursor: pointer;
    img {
        width: 30px;
        height: 30px;
    }
}
</style>
