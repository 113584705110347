
<template>
    <div class="top-backview">
        <div class="contnet-backview">
            <!-- banner图 -->
            <topBanner :bannerData="images" direction="right"></topBanner>
            <!-- 中间部分内容 -->
            <div class="center-backview">
                <div class="content-title">我 们 的 理 念</div>
                <div class="content-eng-title">Our determination</div>
                <div class="content-title">
                    爱 志 万 致 力 于 成 为 “点 燃 生 命 之 希 望 的 企 业”
                </div>
                <div class="content-sendword" @click="turnToMessage">
                    <div class="content-title2">总经理寄语</div>
                    <div class="content-title2">to message ></div>
                </div>
                <div class="content-title">服 务 产 品</div>
                <div class="content-eng-title">service products</div>
                <div class="content-service-back">
                    <div
                        class="service-item"
                        v-for="(item, index) in serviceList"
                        @click="turnToService(item.linkStr)"
                        :key="index"
                    >
                        <img
                            class="service-item-img"
                            :src="item.image"
                            alt=""
                        />
                        <div class="service-item-title-text-title">
                            {{ item.titleStr }}
                        </div>
                        <div class="service-item-line"></div>
                        <div class="service-item-title-text-eng">
                            {{ item.engStr }}
                        </div>
                    </div>
                </div>
                <div class="content-title">照 片 墙</div>
                <div class="content-eng-title">Photo wall</div>
                <div class="content-service-back">
                    <div
                        class="imageWall-item"
                        v-for="(item, index) in imageWall"
                        :style="'background-image: url(' + item.image + ');'"
                        @click="imageWallClicked(index)"
                        :key="index"
                    >
                        <img class="wall-image" :src="item.icon" alt="" />
                    </div>
                </div>
                <div class="content-title">公 司 理 念</div>
                <div class="content-eng-title">Company determination</div>
                <div class="determination-back">
                    <div class="determination-content-back">
                        我们希望创造一个和谐的在地养老环境，<br />让每一个生活在这个环境的长者，<br />即使到了需要被人照料的时候，<br />能够继续生活在自己所熟悉的家庭氛围中，<br />按照自己最舒适的生活习惯，<br />享受有尊严、有价值、有意义的晚年时光！
                    </div>
                </div>
                <!-- <div class="determination-video-back">
                    <video-player
                        class="video-player vjs-custom-skin"
                        ref="videoPlayer"
                        :options="playerOptions"
                        :playsinline="false"
                    >
                    </video-player>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import topBanner from '../components/topBanner.vue'
export default {
  props: {
  },
  components: {
    topBanner
  },
  data () {
    return {
      images: [{ url: 'https://earthone.com.cn/imageUrl/home-banner.jpg', titleStr: '点 燃 生 命 之 希 望 的 企 业', testStr: 'We support our clients to lead their lives in <br>their own way,respecting their dignity' }],
      serviceList: [{ image: 'https://earthone.com.cn/imageUrl/Frameservice_01.png', titleStr: '到宅沐浴服务', engStr: 'Home bathing service', linkStr: '/bathingatHome' },
        { image: 'https://earthone.com.cn/imageUrl/Frameservice_02.png', titleStr: '居家上门服务', engStr: 'Home service', linkStr: '/homeService' },
        { image: 'https://earthone.com.cn/imageUrl/Frameservice_03.png', titleStr: '日间照料中心', engStr: 'Day care centers', linkStr: '/dayCareCenters' },
        { image: 'https://earthone.com.cn/imageUrl/Frameservice_04.png', titleStr: '认知症干预服务', engStr: 'Cognitive intervention services', linkStr: '/cognitiveImpairment' },
        { image: 'https://earthone.com.cn/imageUrl/Frameservice_05.png', titleStr: '养老机构代运营', engStr: 'Operation of elderly care institutions on behalf of others', linkStr: '/careinstitution' },
        { image: 'https://earthone.com.cn/imageUrl/Frameservice_06.png', titleStr: '辅具租赁与销售', engStr: 'Leasing and sales of auxiliary equipment', linkStr: '/leasasles' },
        { image: 'https://earthone.com.cn/imageUrl/Frameservice_07.png', titleStr: '教育咨询培训', engStr: 'Education consulting and training', linkStr: '/conTraining' },
        { image: 'https://earthone.com.cn/imageUrl/Frameservice_08.png', titleStr: '健康管理', engStr: 'Health management', linkStr: '/healthManagement' }],
      imageWall: [
        { image: 'https://earthone.com.cn/imageUrl/Frameimage_wall_01.png', icon: 'https://earthone.com.cn/imageUrl/Framewall_icon1.png' },
        { image: 'https://earthone.com.cn/imageUrl/Frameimage_wall_02.png', icon: 'https://earthone.com.cn/imageUrl/Framewall_icon2.png' },
        { image: 'https://earthone.com.cn/imageUrl/Frameimage_wall_03.jpg', icon: 'https://earthone.com.cn/imageUrl/Framewall_icon3.png' }
      ],
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
        autoplay: false, // 如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          type: 'video/mp4', // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
          src: 'https://cdn.spbcn.org/video/bimushi_2022.mp4' // url地址
        }],
        poster: '../assets/images/earthImages/video_cover.png', // 你的封面地址
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true // 全屏按钮
        }
      }
    }
  },
  methods: {
    // 照片墙点击事件
    imageWallClicked (index) {
      this.$router.push('/imagewall')
    },
    homeMenuClicked (i) {
      if (i === 0) {
        this.$router.push('/classesHome')
      } else if (i === 1) {
        this.$router.push('/literacyStudies')
      } else if (i === 2) {
        this.$router.push('')
      } else if (i === 3) {
        this.$router.push('/IntangibleAngel')
      }
    },
    turnToMessage () {
      this.$router.push('/LeadersMessage')
    },
    turnToService (linkStr) {
      console.log('linkStr', linkStr)
      this.$router.push(linkStr)
    }
  }
}
</script>

<style lang="less" scoped>
.marginTop {
    margin-top: 30px;
}
.top-backview {
    width: 100%;
    height: auto;
    // padding-bottom: 10px;
    // background-color: rgb(216, 216, 216);
    //
    // background-size: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.contnet-backview {
    // width: 1000px;
    width: 100%;
    // height: auto;
    // background-color: black;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
}
.banner-backview {
    width: 1000px;
    height: 360px;
    position: relative;
}
.banner-testback {
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    right: 150px;
    top: 120px;
}
.banner-img-class {
    width: 1000px;
    height: 360px;
    // background-image: url(../assets/images/earthImages/home-banner.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.bannerclass {
    width: 100%;
    height: 360px;
    background-color: rgb(0, 0, 0);
    z-index: 99;
}
.banner-title-class {
    color: rgb(255, 255, 255);
    font-size: 26px;
    text-align: center;
    z-index: 100;
}
.banner-test-class {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 10px;
}
.el-carousel {
    width: 1000px;
    height: 360px;
}
.el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
}
.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}
.el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
}
.center-backview {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0px;
}
.content-sendword {
    width: 200px;
    height: 30px;
    margin-top: 40px;
    margin-bottom: 30px;
    cursor: pointer;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
    border: 1px solid #00a395;
}
.content-sendword:hover {
    background: linear-gradient(to right, #c4fffa, #8dfbf2);
}
.content-service-back {
    width: 800px;
    margin-bottom: 30px;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}
.service-item {
    width: 185px;
    padding: 20px 0px;
    // height: 120px;
    margin-bottom: 15px;
    border-radius: 5px;
    cursor: pointer;
    background: linear-gradient(to right, #4fc4c0, #86e3e0);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.service-item:hover {
    background: linear-gradient(to right, #e97085, #f1969b);
}
.service-item-img {
    width: 36px;
    height: 36px;
    margin-bottom: 8px;
}
.service-item-title-text-title {
    color: white;
    font-size: 12px;
    text-align: center;
}
.service-item-line {
    width: 20px;
    height: 1px;
    margin: 5px;
    background: white;
}
.service-item-title-text-eng {
    width: 100%;
    margin-top: 5px;
    color: white;
    font-size: 10px;
    text-align: center;
}
.imageWall-item {
    width: 255px;
    height: 149px;
    display: flex;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    cursor: pointer;
    justify-content: center;
    position: relative;
}
.imageWall-item:hover{
    .wall-image{
        width: 40px;
        height: 40px;
    }
}
.imageWall-item::before {
    content: '';
    display: block;
    background: linear-gradient(
        to left,
        rgba(255, 255, 255, 0) 50%,
        rgba(38, 210, 202, 0.8) 50%
    );
    background-size: 210% 100%;
    background-position: right bottom;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    transition: all 1s;
    -webkit-transition: all 1s;
}
.wall-image {
    width: 20px;
    height: 20px;
    transition: width 0.3s, height 0.3s;
}
.imageWall-item:hover:before {
    background-position: left bottom;
}

.determination-back {
    width: 790px;
    height: 321px;
    margin-top: 15px;
    background-image: url(../assets/images/earthImages/Framedetermination.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.determination-video-back {
    width: 700px;
    height: 394px;
    margin-top: 20px;
}
.player-video {
    width: 100%;
    height: 100%;
}
.determination-content-back {
    width: 400px;
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 5px;
    box-sizing: border-box;
    padding: 10px 20px;
    font-size: 10px;
    text-align: center;
    color: white;
    line-height: 24px;
    background: rgba(0, 0, 0, 0.4);
}
</style>
