<template>
    <div class="top-backview">
        <div class="contnet-backview">
            <!-- banner图 -->
            <topBanner
                :bannerData="images"
                direction="left"
                lineColor="white"
            ></topBanner>
            <!-- 中间部分内容 -->
            <div class="center-backview">
                <div class="introCom-content-titleback">
                    <div class="introCom-content-title">辅具租赁与销售</div>
                    <div class="introCom-content-title-eng">
                        Leasing and sales of auxiliary equipment
                    </div>
                </div>
                <div class="daycar-content-str">
                    《中国康复辅助器具目录》里有12个主类，93个次类，538个支类。而实际常用的、大众接受度较高的是以下《五大类》辅具产品：1，移动类辅具、2，医<br />疗类辅助器具、3，生活自理和防护辅助器具、4，假肢矫形器、5，家具及其配件。（来源：《上海市康复辅助器具社区租赁产品目录》）
                    <br />为了对产品有深刻的认识，针对这五大类产品作详细的介绍。
                </div>
                <div
                    class="SwiperBox"
                    @mouseenter="MouseFun('移入')"
                    @mouseleave="MouseFun('移出')"
                >
                    <div class="img-back">
                        <!-- 图片 -->
                        <img
                            :class="[
                                'imgCss',
                                ShowImg == index ? 'ShowCss' : ''
                            ]"
                            :src="item.url"
                            v-for="(item, index) in topBannerImgList"
                            :key="index"
                        />
                    </div>
                    <!-- 左箭头按钮 -->
                    <div class="leftBtn" @click="throttle(PrevFun)">&larr;</div>
                    <!-- 右箭头按钮 -->
                    <div class="rightBtn" @click="throttle(NextFun)">
                        &rarr;
                    </div>
                    <!-- 下方指示点容器 -->
                    <div class="instBox">
                        <div
                            v-for="(item, index) in bannerImgList.length"
                            :key="index"
                            @click="ShowImg = index"
                            :class="[
                                'inst',
                                ShowImg == index ? 'instActv' : ''
                            ]"
                        ></div>
                    </div>
                </div>
                <div class="horizontal-scroll-container">
                    <div class="horizontal-scroll-content">
                        <div
                            class="scroll-item"
                            v-for="(item, index) in bannerImgList"
                            :key="index"
                            @click="bottomClicked(index)"
                        >
                            <div
                                class="scroll-item-img"
                                :style="
                                    'background-image: url(' + item.url + ');'
                                "
                            ></div>
                        </div>
                    </div>
                </div>
                <div class="product-center-backview">
                    <div class="product-center-menu">
                        <div class="product-center-menu-title">产品中心</div>
                        <div
                            v-for="(item, index) in productList"
                            :key="index"
                            :class="
                                selectIndex === index
                                    ? 'product-center-menu-item-active'
                                    : 'product-center-menu-item'
                            "
                            @mouseenter="productMouseFun(index)"
                            @mouseleave="productMouseFun(0)"
                            @click="productClicked(index)"
                        >
                            {{ item.title }}
                        </div>
                    </div>
                    <div class="product-center-strback">
                        <div
                            class="product-str-item"
                            v-for="(
                                childItem, itemIndex
                            ) in showProduct.descList"
                            :key="itemIndex"
                        >
                            <div class="product-str-title">
                                {{ childItem.childTitle }}
                            </div>
                            <div
                                class="product-str-desc"
                                v-html="childItem.childDesc"
                            ></div>
                        </div>
                    </div>
                    <div class="product-center-imgback">
                        <img
                            class="product-center-img"
                            :src="showProduct.image"
                            alt=""
                        />
                    </div>
                </div>
                <div class="rehabilitation-strback">
                    <div class="introCom-content-titleback">
                        <div class="introCom-content-title">
                            上海市康复辅具社区租赁项目
                        </div>
                        <div class="introCom-content-title-eng">
                            Shanghai Rehabilitation Aids Community Rental
                            Project
                        </div>
                    </div>
                    <div
                        class="introCom-back"
                        v-for="(item, index) in introComDesc"
                        :key="index"
                    >
                        <div class="introCom-title">{{ item.title }}</div>
                        <div class="introCom-desc" v-html="item.desc"></div>
                    </div>
                    <img
                        class="rehabilitation-img"
                        src="https://earthone.com.cn/imageUrl/rehabilitation.jpg"
                        alt=""
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import topBanner from '@/components/topBanner.vue'

export default {
  props: {
    dataHeight: {
      type: String,
      default: '100%'
    }
  },
  components: {
    topBanner
  },
  data () {
    return {
      ShowImg: 0,
      selectIndex: 0,
      flag: true, // 用来节流防止重复点击
      start: null, // 自动执行下一张定时器
      bannerSelect: 'https://earthone.com.cn/imageUrl/equipment_001.jpg',
      bannerImgList: [
        { url: 'https://earthone.com.cn/imageUrl/equipment_001.jpg' },
        { url: 'https://earthone.com.cn/imageUrl/equipment_003.jpg' },
        { url: 'https://earthone.com.cn/imageUrl/equipment_004.jpg' },
        { url: 'https://earthone.com.cn/imageUrl/equipment_002.png' },
        { url: 'https://earthone.com.cn/imageUrl/equipment_005.jpg' }
      ],
      topBannerImgList: [
        { url: 'https://earthone.com.cn/imageUrl/equipment_banner_001.jpg' },
        { url: 'https://earthone.com.cn/imageUrl/equipment_banner_003.jpg' },
        { url: 'https://earthone.com.cn/imageUrl/equipment_banner_004.jpg' },
        { url: 'https://earthone.com.cn/imageUrl/equipment_002.png' },
        { url: 'https://earthone.com.cn/imageUrl/equipment_banner_005.png' }
      ],
      productList: [
        {
          image: 'https://earthone.com.cn/imageUrl/product_img001.png',
          title: '移动类辅具',
          descList: [
            {
              childTitle: '【轮式助行器LR550】',
              childDesc: '本款产品适用于下肢力量较弱的老年人，用于日常辅助出行，行走康复。'
            },
            {
              childTitle: '产品简介',
              childDesc: '可推可坐，手推肘推，适合多种场景<br>双侧手刹，可抑速，更加安全保险<br>轻便升降，一折一叠，轻巧易收纳<br>超大容量购物篮，实用耐用'
            }
          ]
        },
        {
          image: 'https://earthone.com.cn/imageUrl/product_img002.png',
          title: '医疗类辅助器具',
          descList: [
            {
              childTitle: '【司羿手功能康复机器人C11】',
              childDesc: '本款手功能康复训练机器人，针对脑卒中等神经系统损伤引起的手功能障碍的康复治疗，针对性强。'
            },
            {
              childTitle: '产品简介',
              childDesc: '人体工学康复手套 ，卧拳紧实/伸展彻底<br>针对性分指训练 ，任意手指屈伸自如<br>精细双手镜像训练 ，促进脑功能重塑<br>趣味游戏主动训练 ，模拟康复师指令<br>可连接蓝牙，在线同步训练数据，小程序监督助力<br>屈伸时间智能调节 ，定时定力系统训练，使用多种训练模式<br>气动锻炼不伤手 ，避免手部痉挛<br>体积小巧，净重0.82KG，便于收纳，方便携带。'
            }
          ]
        },
        {
          image: 'https://earthone.com.cn/imageUrl/product_img003.png',
          title: '生活自理和防护辅助器具',
          descList: [
            {
              childTitle: '【升乐迪马桶辅助升降椅】',
              childDesc: '马桶升降椅，通过简单的操作，加上良好的支撑和符合人体工程学的设计，降低了跌倒的风险，确保每个人都能独立又轻松、安全、舒适地使用马桶。'
            },
            {
              childTitle: '产品简介',
              childDesc: '专为老年人、孕妇、运动康复者、膝关节脆弱者、残疾人等设计 <br>一键式控制，操作简单<br>符合人体工程学的设计,缓解了用户膝盖上80%的压力<br>部分型号有“一健呼叫”功能，可预防在如厕时发生意外<br>扶手：防滑硅胶材料'
            }
          ]
        },
        {
          image: 'https://earthone.com.cn/imageUrl/product_img004.png',
          title: '假肢矫形器',
          descList: [
            {
              childTitle: '【免荷一号膝关节支具】',
              childDesc: '本款产品适用于患有骨性关节炎的人群。佩戴后可缓解骨性关节炎带来的膝关节疼痛，提高活动能力及生活质量。'
            },
            {
              childTitle: '产品简介',
              childDesc: '缓解疼痛，增加活动量，提高生活质量<br>减少使用其它治疗方式，减少经济负担<br>可缓解骨性关节炎的进程<br>无侵入式治疗'
            }
          ]
        }, {
          image: 'https://earthone.com.cn/imageUrl/product_img005.png',
          title: '家具及其配件',
          descList: [
            {
              childTitle: '【八乐梦电动护理床CQ-6390D】',
              childDesc: '本款电动护理床适用于长期卧床的人群，“一健”功能，实现背部升降，腿部升降，整床升降功能。满足坐卧、饮食、上下床等不同场景需求。'
            },
            {
              childTitle: '产品简介',
              childDesc: '3马达：高度调节，背部升降，脚部升降，背部腿部同时升降<br>辅助起身，便捷省力<br>低床位设计，身材小的人坐的也安心<br>无侧框架的构造，也减轻跌倒的危险性<br>护理时将床调高，减少护理负担<br>操作简单的遥控器，便于本人及护理员使用'
            }
          ]
        }],
      images: [{ url: 'https://earthone.com.cn/imageUrl/equipment_banner.jpg', titleStr: '辅 具 租 赁 与 销 售', testStr: 'Leasing and sales of auxiliary equipment' }],
      showProduct: {
        image: 'https://earthone.com.cn/imageUrl/product_img001.png',
        title: '移动类辅具',
        descList: [
          {
            childTitle: '【轮式助行器LR550】',
            childDesc: '本款产品适用于下肢力量较弱的老年人，用于日常辅助出行，行走康复。'
          },
          {
            childTitle: '产品简介',
            childDesc: '可推可坐，手推肘推，适合多种场景<br>双侧手刹，可抑速，更加安全保险<br>轻便升降，一折一叠，轻巧易收纳<br>超大容量购物篮，实用耐用'
          }
        ]
      },
      introComDesc: [
        { title: '服务对象', desc: '老年人、残疾人、伤病人等需要康复辅具的人群。' },
        { title: '可享受补贴对象', desc: '上海市户籍<br>60周岁（含）低保、低收入老年人 + 75周岁（含）以上老年人<br>60-74周岁（含）经上海市老年照护统一需求评估具有二级及以上照护等级的人员。' },
        { title: '补贴政策', desc: '符合条件，享受补贴价格<br>※参照最新的补贴标准（上海市康复辅助器具社区租赁服务平台：https://shfjzl.mzj.sh.gov.cn/）' },
        { title: '租赁流程图', desc: '' }
      ]
    }
  },
  mounted () {
    this.setTimeoFun()
  },
  methods: {
    // 这里定义一个鼠标移入移出事件，鼠标悬停时停止自动轮播，鼠标移出则重新执行自动轮播
    MouseFun (type) { // 停止定时器            // 重新执行定时器
      type === '移入' ? clearTimeout(this.start) : this.setTimeoFun()
    },
    productMouseFun (index) {
    },
    productClicked (index) {
      this.selectIndex = index
      this.showProduct = this.productList[index]
    },
    setTimeoFun () {
      this.start = setInterval(() => {
        this.NextFun()
      }, 3000)
    },
    // 这里通过额外封装的节流函数触发 PrevFun() 和 NextFun(),以达到防止重复点击的效果
    throttle (fun) {
      if (this.flag) {
        this.flag = false
        fun() // 此为模板中传递进来的PrevFun()或NextFun()函数
        setTimeout(() => {
          this.flag = true
        }, 500) // 节流间隔时间
      }
    },
    // 上一张
    PrevFun () {
      if (this.ShowImg !== 0) {
        this.ShowImg--
      } else {
        this.ShowImg = this.bannerImgList.length - 1
      }
    },
    // 下一张
    NextFun () {
      if (this.ShowImg !== this.bannerImgList.length - 1) {
        this.ShowImg++
      } else {
        this.ShowImg = 0
      }
    },
    bottomClicked (index) {
      this.ShowImg = index
    }
  }
}
</script>
<style lang="less" scoped>
.product-center-menu-item-active {
    background: #d7fefb;
    color: #00a395;
    width: 100%;
    height: 40px;
    position: relative;
    cursor: pointer;
    line-height: 40px;
    border: 1px solid rgb(240, 240, 240);
    text-align: center;
    font-size: 10px;
}
.product-center-menu-item-active::after {
    content: ''; /* 伪元素的内容为空 */
    position: absolute; /* 绝对定位，相对于最近的非static定位的祖先元素 */
    left: 0; /* 从左边开始 */
    bottom: -1px; /* 紧贴底部 */
    width: 50px; /* 宽度为父元素的100% */
    left: 75px;
    height: 2px; /* 高度（厚度）为2px */
    background-color: #00a395; /* 背景颜色为黑色 */
}
.product-center-backview {
    width: 700px;
    margin: 20px 0px;
    display: flex;
    flex-direction: row;
    .product-center-menu {
        width: 200px;
        display: flex;
        flex-direction: column;
        .product-center-menu-title {
            width: 100%;
            height: 50px;
            line-height: 50px;
            background: #00a395;
            border: 1px solid #00a395;
            color: white;
            text-align: center;
            font-size: 14px;
            font-weight: bolder;
        }
        .product-center-menu-item {
            width: 100%;
            height: 40px;
            position: relative;
            cursor: pointer;
            line-height: 40px;
            background: rgb(248, 248, 248);
            border: 1px solid rgb(240, 240, 240);
            color: black;
            text-align: center;
            font-size: 10px;
        }
        .product-center-menu-item:hover {
            background: #d7fefb;
            color: #00a395;
        }
        .product-center-menu-item:hover::after {
            opacity: 1; /* 鼠标移入时，将透明度设置为1，显示横线 */
        }
        .product-center-menu-item::after {
            content: ''; /* 伪元素的内容为空 */
            position: absolute; /* 绝对定位，相对于最近的非static定位的祖先元素 */
            left: 0; /* 从左边开始 */
            bottom: -1px; /* 紧贴底部 */
            width: 50px; /* 宽度为父元素的100% */
            left: 75px;
            height: 2px; /* 高度（厚度）为2px */
            opacity: 0; /* 初始透明度为0，即隐藏横线 */
            background-color: #00a395; /* 背景颜色为黑色 */
        }
    }
    .product-center-strback {
        width: 300px;
        display: flex;
        flex-direction: column;
        .product-str-item {
            width: 300px;
            margin-left: 20px;
            display: flex;
            flex-direction: column;
            .product-str-title {
                width: 300px;
                margin-top: 8px;
                font-size: 12px;
                color: black;
                font-weight: bolder;
            }
            .product-str-desc {
                width: 220px;
                margin: 2px 0px;
                font-family: 'albbpht';
                line-height: 18px;
                font-size: 10px;
                color: black;
            }
        }
    }
    .product-center-imgback {
        width: 180px;
        height: 236px;
        .product-center-img {
            width: 100%;
            height: 100%;
        }
    }
}
.rehabilitation-strback {
    width: 100%;
    background: rgb(248, 248, 248);
    padding: 0px 0px 20px 0px;
    margin: 20px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .introCom-back {
        width: 100%;
        .introCom-title {
            color: rgb(0, 0, 0);
            text-align: center;
            margin-top: 18px;
            font-weight: 700;
            margin-bottom: 2px;
            letter-spacing: 1px;
            font-family: 'albbpht';
            font-size: 10px;
        }
        .introCom-desc {
            text-align: center;
            color: rgb(36, 36, 36);
            font-family: 'albbpht';
            letter-spacing: 1px;
            font-size: 10px;
            line-height: 16px;
        }
    }
    .rehabilitation-img {
        width: 600px;
        height: 615px;
        margin: 20px;
    }
}

.marginTop {
    margin-top: 30px;
}
.top-backview {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.contnet-backview {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
}
.banner-backview {
    width: 1000px;
    height: 360px;
    position: relative;
}
.banner-testback {
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    position: absolute;
    left: 100px;
    top: 120px;
}
.banner-img-class {
    width: 1000px;
    height: 360px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.bannerclass {
    width: 100%;
    height: 360px;
    background-color: rgb(0, 0, 0);
    z-index: 99;
}
.banner-title-class {
    color: rgb(255, 255, 255);
    font-size: 26px;
    text-align: center;
    z-index: 100;
}
.banner-test-class {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 10px;
}
.el-carousel {
    width: 1000px;
    height: 360px;
}
.el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
}
.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}
.el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
}
.center-backview {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.daycar-content-str {
    width: 750px;
    font-size: 10px;
    font-family: 'albbpht';
    letter-spacing: 1px;
    color: rgb(59, 59, 59);
    text-align: center;
}
.bottom-banner-img {
    width: 700px;
    height: 253px;
    margin: 20px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.horizontal-scroll-container {
    /* 设置容器的宽度，可以是固定的也可以是百分比 */
    width: 700px;
    /* 隐藏超出容器的内容，但显示滚动条 */
    overflow-x: auto;
    /* 你可以添加一些其他样式，如 padding, border 等 */
    white-space: nowrap; /* 防止内容换行 */
}

.horizontal-scroll-content {
    /* 这里不需要特别设置宽度，因为它将根据其子元素自动扩展 */
    /* 但你可以设置一些其他样式，如 padding, margin 等 */
}

.scroll-item {
    /* 设置每个滚动项的样式，如宽度、高度、margin 等 */
    display: inline-block; /* 或者使用 flex, grid 等布局方式 */
    width: 140px; /* 示例宽度 */
    height: 80px; /* 示例高度 */
    margin-right: 10px; /* 示例间距 */
}
.scroll-item:hover {
    border: 1px solid #00a395;
}
.scroll-item-img {
    width: 100%;
    height: 100%;
    border: 1px solid rgb(227, 227, 227);
    background-color: #eeeeee;
    cursor: pointer;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
/* 图片容器样式 */
.SwiperBox {
    position: relative;
    margin: 20px 0px;
    width: 700px;
    height: 253px;
    cursor: pointer;
}
.img-back {
    width: 700px;
    display: flex;
    align-items: center;
    justify-content: center;
}
/* 图片默认样式 */
.imgCss {
    position: absolute;
    // left: 200px;
    top: 0px;
    width: 700px;
    height: 217px;
    opacity: 0;
    object-fit: contain;
    transition: 0.8s; /* 淡入淡出过渡时间 */
}
/* 图片选中样式(继承上方默认样式) */
.ShowCss {
    opacity: 1;
}
/* 两个按钮共有的样式,也可直接使用箭头图片替代 */
.leftBtn,
.rightBtn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(109, 109, 109, 0.445);
    color: #fff;
    border-radius: 50%;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
}
.leftBtn {
    left: 10px;
}
.rightBtn {
    right: 10px;
}
/* 下方指示器盒子 */
.instBox {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 10px;
    display: flex;
}
/* 小圆点 */
.inst {
    width: 15px;
    height: 3px;
    // border: 1px solid #ccc;
    margin-right: 8px;
    background: #e2e2e2;
    border-radius: 2px;
    cursor: pointer;
}
.inst:last-child {
    margin-right: 0px;
}
.instActv {
    width: 30px;
    border: 1px solid #06998a;
    background: #06998a;
}
</style>
