<template>
    <div class="top-backview">
        <div class="contnet-backview">
            <!-- banner图 -->
            <topBanner :bannerData="images" direction="right" lineColor="yellow"></topBanner>
            <!-- 中间部分内容 -->
            <div class="center-backview">
                <div
                    class="history-backview"
                    v-for="(item, index) in historyList"
                    :key="index"
                >
                    <div class="history-title-class">{{ item.year }}</div>
                    <div
                        class="history-line-class"
                        v-for="(childItem, childIndex) in item.eventList"
                        :key="childIndex"
                    >
                        <div class="history-line-class-left">
                            <div class="history-line-class-left-title" v-if="childItem.direction === 'left'">
                                {{ childItem.title }}
                            </div>
                            <div class="history-line-class-left-content" v-if="childItem.direction === 'left'" v-html="childItem.content">
                            </div>
                        </div>
                        <div class="history-line-class-img">
                            <div class="history-line-class-img-line"></div>
                            <img
                                class="history-line-class-img-img"
                                src="https://earthone.com.cn/imageUrl/events_point.png"
                                alt=""
                            />
                        </div>
                        <div class="history-line-class-right">
                            <div class="history-line-class-right-title" v-if="childItem.direction === 'right'">
                                {{ childItem.title }}
                            </div>
                            <div class="history-line-class-right-content" v-if="childItem.direction === 'right'" v-html="childItem.content">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
  props: {
    dataHeight: {
      type: String,
      default: '100%'
    }
  },
  data () {
    return {
      historyList: [{
        year: '2016',
        eventList: [
          {
            title: '1月13日',
            content: '爱志万(上海)健康管理有限公司正式核准成立<br>（2022年9月变更名称。旧名：爱志旺（上海）健康管理有限公司）',
            direction: 'left'
          },
          {
            title: '9月',
            content: '爱志万日间照料中心(上海市徐汇区龙华路1887号101室)正式开幕营运',
            direction: 'right'
          }
        ]
      },
      {
        year: '2017',
        eventList: [
          {
            title: '4月',
            content: '上海市徐汇区田林街道日间照料中心委托运营',
            direction: 'left'
          },
          {
            title: '5月',
            content: '上海市长期护理保险试点,爱志万公司为民营企业首家被指定试点单位',
            direction: 'right'
          },
          {
            title: '9月',
            content: '上海市徐汇区虹梅街道残疾人辅具社委托运营',
            direction: 'left'
          }
        ]
      },
      {
        year: '2019',
        eventList: [
          {
            title: '4月',
            content: '上海市徐汇区田林街道认知障碍早期干预项目 承接(一共4个街道认知障碍友好社区项目)',
            direction: 'left'
          }
        ]
      },
      {
        year: '2020',
        eventList: [
          {
            title: '2020年8月',
            content: '爱志万(上海)健康管理有限公司山东分公司成立',
            direction: 'left'
          },
          {
            title: '2020年9月',
            content: '新华社「“遗忘”不应是他们唯一的选择」报道(爱志万日间照料中心+田林街道认知障碍友好社区项目)',
            direction: 'right'
          },
          {
            title: '2020年10月',
            content: '山东省养老服务人才中日合作暨校企交流研讨班承接<br>上海市养老服务行业协会十佳社区养老服务机构设施长彭雨<br>爱志万(上海)健康管理有限公司四川分公司成立<br>成为上海市康复辅具租赁项目的服务商',
            direction: 'left'
          }
        ]
      },
      {
        year: '2021',
        eventList: [
          {
            title: '6月',
            content: '成为上海健康医学院「大学生就业实践基地」',
            direction: 'left'
          },
          {
            title: '10月',
            content: '上海市老年基金会卧床老人上门沐浴服务项目服务合同承接',
            direction: 'right'
          }
        ]
      },
      {
        year: '2022',
        eventList: [
          {
            title: '2月',
            content: '成为上海市养老服务行业协会理事单位',
            direction: 'left'
          },
          {
            title: '9月',
            content: '友邦人寿保险有限公司康养网络合作机构',
            direction: 'right'
          }
        ]
      },
      {
        year: '2023',
        eventList: [
          {
            title: '5月',
            content: '上海市嘉定区失能老年人到家沐浴服务项目 承接',
            direction: 'left'
          },
          {
            title: '7月',
            content: '爱志万公司正式核准加入爱夕医养联盟',
            direction: 'right'
          }
        ]
      },
      {
        year: '2024',
        eventList: [
          {
            title: '4月',
            content: '爱志万子公司沈阳爱志德澜医养产业发展有限公司正式核准成立',
            direction: 'left'
          }
        ]
      }],
      images: [{ url: 'https://earthone.com.cn/imageUrl/event_banner.jpg', titleStr: '爱 志 万 大 事 记', testStr: 'Chronicles of Major Events' }]
    }
  }
}
</script>
<style lang="less" scoped>
.marginTop {
    margin-top: 30px;
}
.top-backview {
    width: 100%;
    height: auto;
    //
    display: flex;
    align-items: center;
    flex-direction: column;
}
.contnet-backview {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
}
.banner-backview {
    width: 1000px;
    height: 360px;
    position: relative;
}
.banner-testback {
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    right: 150px;
    top: 120px;
}
.banner-img-class {
    width: 1000px;
    height: 360px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.bannerclass {
    width: 100%;
    height: 360px;
    background-color: rgb(0, 0, 0);
    z-index: 99;
}
.banner-title-class {
    color: rgb(255, 255, 255);
    font-size: 26px;
    text-align: center;
    z-index: 100;
}
.banner-test-class {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 10px;
}
.el-carousel {
    width: 1000px;
    height: 360px;
}
.el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
}
.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}
.el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
}
.center-backview {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0px;
}
.history-backview {
    width: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    .history-title-class {
        width: 830px;
        margin: 20px;
        color: #00a395;
        font-size: 14px;
        font-weight: bolder;
        text-align: center;
    }
    .history-line-class {
        width: 800px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .history-line-class-left {
            width: 400px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            .history-line-class-left-title{
              width: 100%;
              text-align: right;
              font-size: 11px;
              color: rgb(0, 0, 0);
            }
            .history-line-class-left-content{
              width: 100%;
              text-align: right;
              margin-top: 5px;
              font-size: 10px;
              line-height: 18px;
              color: rgb(65, 65, 65);
            }
        }
        .history-line-class-img {
            width: 30px;
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            .history-line-class-img-line{
              width: 2px;
              height: 100px;
              z-index: 99;
              position: absolute;
              left: 14px;
              background: #00a395;
            }
            .history-line-class-img-img {
                width: 16px;
                height: 16px;
                z-index: 100;
            }
        }
        .history-line-class-right {
            width: 400px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            .history-line-class-right-title{
              width: 100%;
              text-align: left;
              font-size: 11px;
              color: rgb(0, 0, 0);
            }
            .history-line-class-right-content{
              width: 100%;
              text-align: left;
              margin-top: 5px;
              line-height: 18px;
              font-size: 10px;
              color: rgb(65, 65, 65);
            }
        }
    }
}
</style>
