<template>
    <div class="top-backview">
        <div class="contnet-backview">
            <!-- banner图 -->
            <topBanner :bannerData="images" direction="right" lineColor="white"></topBanner>

            <!-- 中间部分内容 -->
            <div class="center-backview">
                <div class="introCom-content-titleback">
                    <div class="introCom-content-title">服务内容</div>
                    <div class="introCom-content-title-eng">
                        service content
                    </div>
                </div>
                <div class="service-content-back">
                    <div class="content-back-view">
                        <img
                            class="content-topimg"
                            src="@/assets/images/earthImages/service-img001.png"
                            alt=""
                        />
                        <div class="service-content-title">定制居家服务</div>
                    </div>
                    <div class="content-back-view">
                        <img
                            class="content-topimg"
                            src="@/assets/images/earthImages/service-img002.png"
                            alt=""
                        />
                        <div class="service-content-title">长护险服务</div>
                    </div>
                </div>
                <div class="gray-back">
                    <div class="introCom-content-titleback">
                        <div class="introCom-content-title">定制居家服务</div>
                        <div class="introCom-content-title-eng">
                            Customized Home Service
                        </div>
                    </div>
                    <div class="content-service-back">
                        <div
                            v-for="(item, index) in serviceList"
                            :key="index"

                            :class="['service-item', index == 1 || index === 3 ? 'service-item-hover' : '']"
                        >
                            <img
                                class="service-item-img"
                                :src="item.currentSrc"
                                alt=""
                            />
                            <div class="service-item-title-text-title">
                                {{ item.titleStr }}
                            </div>
                            <div class="service-item-line"></div>
                            <div class="service-item-title-text-eng">
                                {{ item.engStr }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="introCom-content-titleback">
                    <div class="introCom-content-title">长护险上门服务</div>
                    <div class="introCom-content-title-eng">
                        Long term care insurance door-to-door service
                    </div>
                </div>
                <div class="longterm-back">
                    <div
                        v-for="(item, index) in longtermList"
                        :key="index"
                    >
                      <img class="itemImage" :src="item.image" alt="">
                  </div>
                </div>
                <div class="introCom-back" v-for="(item, index) in introComDesc" :key="index">
                  <div class="introCom-title">{{item.title}}</div>
                  <div class="introCom-desc" v-html="item.desc"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
  props: {
    dataHeight: {
      type: String,
      default: '100%'
    }
  },
  data () {
    return {
      hovered: false,
      introComDesc: [
        { title: '什么是长期护理保险制度（简称：长护险）', desc: '是指以社会互助共济方式筹集资金，对经评估达到一定护理需求等级的长期失能人员，为其基本生活照料和与基本生活密切相关的医疗护理提供服务或资金保障的社会保险制度。' },
        { title: '哪些人可以享受长护险待遇（基本条件）', desc: '年满60周岁以上。<br>参加本市职工基本医疗保险或本市城乡居民医疗保险。<br>参加本市职工医保的以及办理申领基本养老金手续的人员。' },
        { title: '长护险能为长者带来哪些优惠', desc: '个人支付比例为10%，基金支付比例为90%（90%部分由长期护理保险基金支付非个人账户余额支付）。' },
        { title: '如何申请居家长护险及至上门服务为止的流程', desc: '“申请步骤：申请——评估——服务”<br>符合条件的老人携带身份证、医保卡和医疗证明，向居住地的街道社区服务受理中心提出申请。（如委托他人代办，需提供委托人身份证）<br>由专业医疗评估机构进行上门评估，评估结果以《长期护理保险护理需求评估结果告知书》为准。<br>※评估享受待遇期限根据评估实际情况从半年到2年不等。<br>长护险指定服务机构在获取服务对象信息后，上门制定护理计划，再确认安排上门服务。' },
        { title: '评估待遇', desc: '评估费<br>• 200元（其中政府补贴160元，个人自费40元）<br>• 经过统一照护需求评估达到二到六级，在评估有效期内享受相应服务待遇。<br>评估等级<br>• 二/三级    每周上门3次<br>• 四级    每周上门5次<br>• 五/六级    每周上门7次<br>※每次上门服务时间为1小时' },
        { title: '费用支付', desc: '长期护理保险基金支付需刷老人医保卡<br>个人支付6.5元/小时' },
        { title: '长护险服务内容 （42项）', desc: '头面部清洁、梳理<br>洗发<br>指/趾甲护理<br>手、足部清洁温水擦浴<br>沐浴<br>协助进食/水<br>口腔清洁<br>整理床单元<br>排泄护理<br>失禁护理<br>床上使用便器<br>人工取便术<br>晨间护理<br>晚间护理<br>会阴护理<br>药物管理<br>协助翻身叩背排痰<br>协助床上移动<br>借助器具移动<br>皮肤外用药涂擦<br>安全护理<br>生活自理能力训练<br>压疮预防护理<br>留置尿管的护理<br>人工肛门便袋护理<br>开塞露/直肠栓剂给药<br>鼻饲<br>药物喂服<br>物理降温<br>生命体征监测<br>吸氧<br>灌肠<br>导尿（女性）<br>血糖监测<br>压疮伤口换药<br>静脉血标本采集<br>肌肉注射<br>皮下注射<br>造口护理<br>经外周静脉置入中心静脉导管（PICC）维护' }
      ],
      images: [{ url: 'https://earthone.com.cn/imageUrl/home_serve_banner.jpg', titleStr: '居 家 上 门 服 务', testStr: 'Home service' }],
      serviceList: [{ currentSrc: 'https://earthone.com.cn/imageUrl/Framecustomized001.png', defaultImageSrc: 'https://earthone.com.cn/imageUrl/Framecustomized001.png', hoverImageSrc: 'https://earthone.com.cn/imageUrl/Framecustomized001.png', titleStr: '定制居家服务', engStr: '服务内容：沐浴、温水擦浴、协助进食/水、排泄护理、生活自理能力训练etc.' },
        { currentSrc: 'https://earthone.com.cn/imageUrl/Framecustomized002.png', defaultImageSrc: 'https://earthone.com.cn/imageUrl/Framecustomized002.png', hoverImageSrc: 'https://earthone.com.cn/imageUrl/Framecustomized002.png', titleStr: '陪夜服务', engStr: '服务内容：陪夜、协助进食/水、排泄护理、更衣护理etc.' },
        { currentSrc: 'https://earthone.com.cn/imageUrl/Framecustomized003.png', defaultImageSrc: 'https://earthone.com.cn/imageUrl/Framecustomized003.png', hoverImageSrc: 'https://earthone.com.cn/imageUrl/Framecustomized003.png', titleStr: '24小时居家服务', engStr: '服务内容：沐浴、协助进食/水、排泄护理、做饭、打扫清洁etc.※不包括医疗医疗服务行为的提供' },
        { currentSrc: 'https://earthone.com.cn/imageUrl/Framecustomized004.png', defaultImageSrc: 'https://earthone.com.cn/imageUrl/Framecustomized004.png', hoverImageSrc: 'https://earthone.com.cn/imageUrl/Framecustomized004.png', titleStr: '陪诊服务', engStr: '服务内容：院内看诊陪同、专业解读医生话术和病情、陪诊服务的记录etc.' },
        { currentSrc: 'https://earthone.com.cn/imageUrl/Framecustomized005.png', defaultImageSrc: 'https://earthone.com.cn/imageUrl/Framecustomized005.png', hoverImageSrc: 'https://earthone.com.cn/imageUrl/Framecustomized005.png', titleStr: '医院跑腿服务', engStr: '服务内容：代开药和取号、代预约检查和拿取报告、医院和患者之前需要提供的服务etc.' }],
      longtermList: [
        { image: 'https://earthone.com.cn/imageUrl/long-term001.png' }, { image: 'https://earthone.com.cn/imageUrl/long-term002.png' }, { image: 'https://earthone.com.cn/imageUrl/long-term003.png' }, { image: 'https://earthone.com.cn/imageUrl/long-term004.png' }, { image: 'https://earthone.com.cn/imageUrl/long-term005.png' }]
    }
  },
  methods: {
    handleMouseOver(index) {
      // 更新当前悬停的图片的 src
      console.log('index', index)
      this.serviceList[index].currentSrc = this.serviceList[index].hoverImageSrc
    },
    handleMouseOut(index) {
      // 如果鼠标移出的是当前悬停的图片，则恢复其默认 src
      this.serviceList[index].currentSrc = this.serviceList[index].defaultImageSrc// 假设defaultSrc不总是存在，可以用一个默认值代替
    }
  }
}
</script>
<style lang="less" scoped>
.marginTop {
    margin-top: 30px;
}
.top-backview {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.contnet-backview {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
}
.banner-backview {
    width: 1000px;
    height: 360px;
    position: relative;
}
.banner-testback {
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    position: absolute;
    right: 150px;
    top: 120px;
}
.banner-img-class {
    width: 1000px;
    height: 360px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.bannerclass {
    width: 100%;
    height: 360px;
    background-color: rgb(0, 0, 0);
    z-index: 99;
}
.banner-title-class {
    color: rgb(255, 255, 255);
    font-size: 26px;
    text-align: center;
    z-index: 100;
}
.banner-test-class {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 10px;
}
.el-carousel {
    width: 1000px;
    height: 360px;
}
.el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
}
.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}
.el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
}
.center-backview {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.service-content-back {
    width: 700px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    .content-back-view {
        width: 300px;
        height: 240px;
        display: flex;
        border: 1px solid #bebebe;
        flex-direction: column;
        align-items: center;
        .content-topimg {
            width: 300px;
            height: 193px;
        }
        .service-content-title {
            width: 100%;
            height: 47px;
            line-height: 47px;
            font-size: 12px;
            font-family: 'albbphtbold';
            color: black;
            text-align: center;
        }
    }
}
.gray-back {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgb(245, 245, 245);
}
.content-service-back {
    width: 720px;
    margin-bottom: 40px;
    margin-top: 20px;
    display: flex;
    color: #00a395;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}
.service-item {
    width: 240px;
    height: 170px;
    margin-bottom: 20px;
    box-shadow: 2px 2px 8px rgba(106, 106, 106, 0.2);
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.service-item-hover {
    background: #00a395;
    color: white;
    .service-item-line {
        background: white;
    }
}
// .service-item:hover {
//     background: #00a395;
//     color: white;
//     .service-item-line {
//         background: white;
//     }
// }
.service-item-img {
    width: 36px;
    height: 36px;
    margin-bottom: 8px;
}
.service-item-title-text-title {
    width: 86%;
    font-size: 12px;
    font-family: 'albbpht';
    font-weight: 700;
    text-align: center;
}

.service-item-line {
    width: 70px;
    background: #00a395;
    height: 2px;
    margin: 5px;
}
.service-item-title-text-eng {
    width: 96%;
    margin-top: 15px;
    font-size: 10px;
    font-family: 'albbpht';
    text-align: center;
}
.longterm-back{
  width: 750px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
  .itemImage{
    width: 220px;
    margin: 15px;
    height: 137px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.4);
  }
}
.introCom-back{
  width: 700px;
  background: rgb(245, 245, 245);
  padding: 20px;
  margin-top: 10px;
  .introCom-title{
    color: black;
    margin-bottom: 10px;
    font-weight: 800;
    font-size: 13px;
  }
  .introCom-desc{
    color: rgb(82, 82, 82);
    letter-spacing: 1px;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
  }
}
</style>
