<template>
    <div class="top-backview">
        <div class="contnet-backview">
            <!-- banner图 -->
            <topBanner
                :bannerData="images"
                direction="left"
                lineColor="white"
            ></topBanner>
            <!-- 中间部分内容 -->
            <div class="center-backview">
                <div class="introCom-content-titleback">
                    <div class="introCom-content-title">爱志万风采</div>
                    <div class="introCom-content-title-eng">
                        Charm and grace
                    </div>
                </div>
                <div class="content-service-back">
                    <div
                        v-for="(item, index) in imagesWall"
                        :key="index"
                        @click="fn(index)"
                        class="service-item"
                    >
                        <img
                            class="service-item-img"
                            :src="item.currentSrc"
                            alt=""
                        />
                        <div class="service-item-title">
                            {{ item.titleStr }}
                        </div>
                    </div>
                </div>
                <div class="image-wall-contentback">
                    <div
                        class="wall-item-back"
                        v-for="(item, index) in imagesWallList"
                        :key="index"
                        :id="index"
                    >
                        <div class="introCom-content-titleback">
                            <div class="introCom-content-title">
                                {{ item.titleStr }}
                            </div>
                            <div class="introCom-content-title-eng">
                                {{ item.engStr }}
                            </div>
                        </div>
                        <div class="operation_img_back">
                            <div
                                class="operation_img"
                                v-for="(imgItem, itemIndex) in item.imgList"
                                :key="itemIndex"
                            >
                                <img
                                    class="operation_img_img"
                                    :src="imgItem"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <button v-show="showBackToTop" @click="scrollToTop" class="back-to-top-btn">返回顶部</button> -->
        </div>
    </div>
</template>
<script>

export default {
  props: {
    dataHeight: {
      type: String,
      default: '100%'
    }
  },
  data () {
    return {
      threshold: 200,
      showBackToTop: true,
      imagesWall: [{ currentSrc: 'https://earthone.com.cn/imageUrl/grace_001.png', titleStr: '到宅沐浴服务' },
        { currentSrc: 'https://earthone.com.cn/imageUrl/grace_002.png', titleStr: '丰富活动' },
        { currentSrc: 'https://earthone.com.cn/imageUrl/grace_003.png', titleStr: '贴心膳食' },
        { currentSrc: 'https://earthone.com.cn/imageUrl/grace_004.png', titleStr: '员工风采' },
        { currentSrc: 'https://earthone.com.cn/imageUrl/grace_005.png', titleStr: '专业服务' }
      ],
      imagesWallList: [
        {
          titleStr: '到宅沐浴服务',
          engStr: 'Home bathing service',
          imgList: [
            'https://earthone.com.cn/imageUrl/grace_01_001.png',
            'https://earthone.com.cn/imageUrl/grace_01_002.png',
            'https://earthone.com.cn/imageUrl/grace_01_003.png',
            'https://earthone.com.cn/imageUrl/grace_01_004.png',
            'https://earthone.com.cn/imageUrl/grace_01_005.png',
            'https://earthone.com.cn/imageUrl/grace_01_006.png',
            'https://earthone.com.cn/imageUrl/grace_01_007.png',
            'https://earthone.com.cn/imageUrl/grace_01_008.png',
            'https://earthone.com.cn/imageUrl/grace_01_009.png'
          ]
        },
        {
          titleStr: '丰富活动',
          engStr: 'Enriching activities',
          imgList: [
            'https://earthone.com.cn/imageUrl/grace_02_001.png',
            'https://earthone.com.cn/imageUrl/grace_02_002.png',
            'https://earthone.com.cn/imageUrl/grace_02_003.png',
            'https://earthone.com.cn/imageUrl/grace_02_004.png',
            'https://earthone.com.cn/imageUrl/grace_02_005.png',
            'https://earthone.com.cn/imageUrl/grace_02_006.png',
            'https://earthone.com.cn/imageUrl/grace_02_007.png',
            'https://earthone.com.cn/imageUrl/grace_02_008.png',
            'https://earthone.com.cn/imageUrl/grace_02_009.png'
          ]
        },
        {
          titleStr: '贴心膳食',
          engStr: 'Thoughtful diet',
          imgList: [
            'https://earthone.com.cn/imageUrl/grace_03_001.png',
            'https://earthone.com.cn/imageUrl/grace_03_002.png',
            'https://earthone.com.cn/imageUrl/grace_03_003.png'
          ]
        },
        {
          titleStr: '员工风采',
          engStr: 'Employee style',
          imgList: [
            'https://earthone.com.cn/imageUrl/grace_04_001.png',
            'https://earthone.com.cn/imageUrl/grace_04_002.png',
            'https://earthone.com.cn/imageUrl/grace_04_003.png',
            'https://earthone.com.cn/imageUrl/grace_04_004.png',
            'https://earthone.com.cn/imageUrl/grace_04_005.png',
            'https://earthone.com.cn/imageUrl/grace_04_006.png',
            'https://earthone.com.cn/imageUrl/grace_04_007.png',
            'https://earthone.com.cn/imageUrl/grace_04_008.png',
            'https://earthone.com.cn/imageUrl/grace_04_009.png'
          ]
        },
        {
          titleStr: '专业服务',
          engStr: 'professional services',
          imgList: [
            'https://earthone.com.cn/imageUrl/grace_05_001.png',
            'https://earthone.com.cn/imageUrl/grace_05_002.png',
            'https://earthone.com.cn/imageUrl/grace_05_003.png',
            'https://earthone.com.cn/imageUrl/grace_04_006.png',
            'https://earthone.com.cn/imageUrl/grace_04_005.png',
            'https://earthone.com.cn/imageUrl/grace_04_001.png'
          ]
        }
      ],
      images: [{ url: 'https://earthone.com.cn/imageUrl/gracer_banner.png', titleStr: '爱 志 万 照 片 墙', testStr: 'Photo wall' }]
    }
  },
  mounted () {
  },
  methods: {
    fn: function (index) {
      document
        .getElementById(index)
        .scrollIntoView({ block: 'start', behavior: 'smooth' })
    },
    backTopScroll() {
      console.log('window', window)
      // 当滚动距离超过阈值时显示按钮
      this.showBackToTop = window.scrollY > this.threshold
    },
    scrollToTop() {
      // 滚动到页面顶部
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // 平滑滚动（可选）
      })
    }
  }
}
</script>
<style lang="less" scoped>
.top-backview {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.back-to-top-btn {
  /* 样式设置 */
  position: fixed;
  bottom: 100px;
  right: 30px;
  z-index: 999;
  /* 其他样式... */
}
.contnet-backview {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
}
.banner-backview {
    width: 1000px;
    height: 360px;
    position: relative;
}
.banner-testback {
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: absolute;
    left: 150px;
    top: 120px;
}
.banner-img-class {
    width: 1000px;
    height: 360px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.bannerclass {
    width: 100%;
    height: 360px;
    background-color: rgb(0, 0, 0);
    z-index: 99;
}
.banner-title-class {
    color: rgb(255, 255, 255);
    font-size: 26px;
    text-align: left;
    z-index: 100;
}
.banner-test-class {
    color: rgb(255, 255, 255);
    text-align: left;
    font-size: 10px;
}
.el-carousel {
    width: 1000px;
    height: 360px;
}
.el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
}
.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}
.el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
}
.center-backview {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.content-service-back {
    width: 700px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    .service-item {
        width: 220px;
        height: 200px;
        margin-bottom: 20px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .service-item-img {
        width: 150px;
        height: 150px;
        border-radius: 75px;
    }
    .service-item-title {
        width: 100%;
        margin-top: 10px;
        letter-spacing: 4px;
        font-size: 12px;
        color: black;
        font-weight: 800;
        text-align: center;
    }
}
.image-wall-contentback {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .wall-item-back {
        width: 800px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .operation_img_back {
            width: 765px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;
            flex-wrap: wrap;
            .operation_img {
                padding: 5px;
                margin-bottom: 20px;
                box-sizing: border-box;
                box-shadow: 2px 2px 8px rgba(106, 106, 106, 0.2);
                width: 240px;
                height: 170px;
                .operation_img_img {
                    width: 230px;
                    height: 163px;
                }
            }
        }
    }
}
</style>
