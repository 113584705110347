<template>
    <div class="top-backview">
        <div class="contnet-backview">
            <!-- banner图 -->
            <topBanner :bannerData="images" direction="left" lineColor="white"></topBanner>
            <!-- 中间部分内容 -->
            <div class="center-backview">
                <div class="introCom-content-titleback">
                    <div class="introCom-content-title">新建机构运营</div>
                    <div class="introCom-content-title-eng">
                        New institution operation
                    </div>
                </div>
                <div class="content-operation-back">
                    <div
                        v-for="(item, index) in serviceList"
                        :key="index"
                        :class="[
                            'service-item',
                            index == 1 || index === 3 || index === 5
                                ? 'service-item-hover'
                                : ''
                        ]"
                    >
                        <img
                            class="service-item-img"
                            :src="item.currentSrc"
                            alt=""
                        />
                        <div class="service-item-title-text-title">
                            {{ item.titleStr }}
                        </div>
                        <div class="service-item-line"></div>
                    </div>
                </div>
                <div class="operation_img_back">
                    <div
                        class="operation_img"
                        v-for="(item, index) in operationImgList"
                        :key="index"
                    >
                        <img
                            class="operation_img_img"
                            :src="item.imageUrl"
                            alt=""
                        />
                    </div>
                </div>
                <div class="introCom-content-titleback">
                    <div class="introCom-content-title">机构运营优化</div>
                    <div class="introCom-content-title-eng">
                        Institutional operation optimization
                    </div>
                </div>
                <div class="content-service-back">
                    <div
                        class="imageWall-item"
                        v-for="(item, index) in optimizationImgList"
                        @mouseenter="hoverImage(index, true)"
                        @mouseleave="hoverImage(index, false)"
                        :key="index"
                    >
                        <img
                            class="imageWall-item-img"
                            :src="item.image"
                            alt=""
                        />
                        <div class="imageWall-item-bottom-str">
                            {{ item.title }}
                        </div>
                        <div class="imageWall-item-child">
                            <div
                                class="environmental-img-hover"
                                v-if="item.isHovered"
                            >
                                <div class="environmental-img-hover-title">
                                    {{ item.title }}
                                </div>
                                <div class="environmental-img-hover-line"></div>
                                <div class="environmental-img-hover-content">
                                    {{ item.desc }}
                                </div>
                                <img class="environmental-img-hover-point" src="@/assets/images/earthImages/Vectoroptimization_point.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
  props: {
    dataHeight: {
      type: String,
      default: '100%'
    }
  },
  data () {
    return {
      optimizationImgList: [
        { image: 'https://earthone.com.cn/imageUrl/optimization_001.png', title: '教育培训', desc: '包括机构运营管理、现场护理人员、营销人员等定制化、多方式的培训课程', isHovered: false },
        { image: 'https://earthone.com.cn/imageUrl/optimization_002.png', title: '现场协助开业前筹备', desc: '包括运营流程、运营工具、运营指导、制度优化等', isHovered: false },
        { image: 'https://earthone.com.cn/imageUrl/optimization_003.png', title: '开业后的指导和咨询', desc: '引入国际养老理念，将前沿技术落地优化。爱志万根据选择方式、选择深度的不同，结合规模与用户需求，为您提供定制化专案服务。', isHovered: false }
      ],
      operationImgList: [
        { imageUrl: 'https://earthone.com.cn/imageUrl/operation_img001.png' },
        { imageUrl: 'https://earthone.com.cn/imageUrl/operation_img002.png' },
        { imageUrl: 'https://earthone.com.cn/imageUrl/operation_img003.png' },
        { imageUrl: 'https://earthone.com.cn/imageUrl/operation_img004.png' },
        { imageUrl: 'https://earthone.com.cn/imageUrl/operation_img005.png' },
        { imageUrl: 'https://earthone.com.cn/imageUrl/operation_img006.png' }
      ],
      serviceList: [{ currentSrc: 'https://earthone.com.cn/imageUrl/Frameoperation_001.png', defaultImageSrc: 'https://earthone.com.cn/imageUrl/Frameoperation_001.png', hoverImageSrc: 'https://earthone.com.cn/imageUrl/Frameoperation_001.png', titleStr: '服务体系建设', engStr: '服务内容：沐浴、温水擦浴、协助进食/水、排泄护理、生活自理能力训练etc.' },
        { currentSrc: 'https://earthone.com.cn/imageUrl/Frameoperation_002.png', defaultImageSrc: 'https://earthone.com.cn/imageUrl/Frameoperation_002.png', hoverImageSrc: 'https://earthone.com.cn/imageUrl/Frameoperation_002.png', titleStr: '人员配置需求', engStr: '服务内容：陪夜、协助进食/水、排泄护理、更衣护理etc.' },
        { currentSrc: 'https://earthone.com.cn/imageUrl/Frameoperation_003.png', defaultImageSrc: 'https://earthone.com.cn/imageUrl/Frameoperation_003.png', hoverImageSrc: 'https://earthone.com.cn/imageUrl/Frameoperation_003.png', titleStr: '收费标准范本', engStr: '服务内容：沐浴、协助进食/水、排泄护理、做饭、打扫清洁etc.※不包括医疗医疗服务行为的提供' },
        { currentSrc: 'https://earthone.com.cn/imageUrl/Frameoperation_004.png', defaultImageSrc: 'https://earthone.com.cn/imageUrl/Frameoperation_004.png', hoverImageSrc: 'https://earthone.com.cn/imageUrl/Frameoperation_004.png', titleStr: '收益分析核算', engStr: '服务内容：院内看诊陪同、专业解读医生话术和病情、陪诊服务的记录etc.' },
        { currentSrc: 'https://earthone.com.cn/imageUrl/Frameoperation_005.png', defaultImageSrc: 'https://earthone.com.cn/imageUrl/Frameoperation_005.png', hoverImageSrc: 'https://earthone.com.cn/imageUrl/Frameoperation_005.png', titleStr: '市场营销方案', engStr: '服务内容：代开药和取号、代预约检查和拿取报告、医院和患者之前需要提供的服务etc.' },
        { currentSrc: 'https://earthone.com.cn/imageUrl/Frameoperation_006.png', defaultImageSrc: 'https://earthone.com.cn/imageUrl/Frameoperation_006.png', hoverImageSrc: 'https://earthone.com.cn/imageUrl/Frameoperation_006.png', titleStr: '绩效体系设计', engStr: '服务内容：院内看诊陪同、专业解读医生话术和病情、陪诊服务的记录etc.' },
        { currentSrc: 'https://earthone.com.cn/imageUrl/Frameoperation_007.png', defaultImageSrc: 'https://earthone.com.cn/imageUrl/Frameoperation_007.png', hoverImageSrc: 'https://earthone.com.cn/imageUrl/Frameoperation_007.png', titleStr: '开业筹备协助', engStr: '服务内容：院内看诊陪同、专业解读医生话术和病情、陪诊服务的记录etc.' }
      ],
      images: [{ url: 'https://earthone.com.cn/imageUrl/elderlycare_banner.jpg', titleStr: '养 老 机 构 代 运 营', testStr: 'Operation of elderly care institutions on behalf of others' }]
    }
  },
  mounted () {
  },
  methods: {
    hoverImage (selectIndex, isHovered) {
      this.optimizationImgList[selectIndex].isHovered = !this.optimizationImgList[selectIndex].isHovered
    }
  }
}
</script>
<style lang="less" scoped>
.top-backview {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.contnet-backview {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
}
.banner-backview {
    width: 1000px;
    height: 360px;
    position: relative;
}
.banner-testback {
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    position: absolute;
    left: 100px;
    top: 120px;
}
.banner-img-class {
    width: 1000px;
    height: 360px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.bannerclass {
    width: 100%;
    height: 360px;
    background-color: rgb(0, 0, 0);
    z-index: 99;
}
.banner-title-class {
    color: rgb(255, 255, 255);
    font-size: 26px;
    text-align: center;
    z-index: 100;
}
.banner-test-class {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 10px;
}
.el-carousel {
    width: 1000px;
    height: 360px;
}
.el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
}
.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}
.el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
}
.center-backview {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.content-operation-back {
    width: 800px;
    margin-bottom: 40px;
    margin-top: 20px;
    display: flex;
    color: #00a395;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    .service-item {
        width: 200px;
        height: 120px;
        margin-bottom: 20px;
        box-shadow: 2px 2px 10px rgba(86, 86, 86, 0.2);
        background: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .service-item-hover {
        background: #00a395;
        color: white;
        .service-item-line {
            background: white;
        }
    }
    // .service-item:hover {
    //     background: #00a395;
    //     color: white;
    //     .service-item-line {
    //         background: white;
    //     }
    // }
    .service-item-img {
        width: 36px;
        height: 36px;
        margin-bottom: 8px;
    }
    .service-item-title-text-title {
        width: 86%;
        font-size: 12px;
        text-align: center;
    }

    .service-item-line {
        width: 60px;
        background: #00a395;
        height: 1px;
        margin: 5px;
    }
    .service-item-title-text-eng {
        width: 90%;
        margin-top: 5px;
        font-size: 10px;
        text-align: center;
    }
}
.content-service-back{
    width: 760px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 40px;
    margin-top: 20px;
}
.operation_img_back {
    width: 765px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 20px 0px;
    .operation_img {
        padding: 5px;
        margin-top: 20px;
        box-sizing: border-box;
        box-shadow: 2px 2px 8px rgba(106, 106, 106, 0.2);
        width: 250px;
        height: 170px;
        .operation_img_img {
            width: 240px;
            height: 163px;
        }
    }
}
.optimization-img-back {
    width: 700px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    .optimization-img {
        width: 220px;
        height: 260px;
        .optimization-img-img {
            width: 100%;
            height: 100%;
        }
    }
}
.imageWall-item {
    width: 240px;
    height: 295px;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    .imageWall-item-img {
        width: 100%;
        height: 100%;
    }
    .imageWall-item-bottom-str {
        width: 100%;
        position: absolute;
        bottom: 20px;
        text-align: center;
        color: white;
        font-size: 14px;
        font-weight: 800;
    }
}
.environmental-img-hover {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden; /* 如果需要的话，隐藏超出的内容 */
    .environmental-img-hover-title {
        width: 100%;
        margin-top: 50px;
        text-align: center;
        font-size: 14px;
        color: white;
    }
    .environmental-img-hover-line {
        width: 50px;
        height: 1px;
        background: white;
        margin: 5px;
    }
    .environmental-img-hover-content {
        width: 60%;
        text-align: center;
        font-size: 10px;
        margin-top: 20px;
        color: rgb(255, 255, 255);
    }
}
.environmental-img-hover-point{
    width: 50px;
    height: 50px;
    margin-top: 30px;
}
.imageWall-item-child {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.imageWall-item-child:hover:before {
    background-position: left bottom;
}
.imageWall-item-child::before {
    content: '';
    display: block;
    background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 50%,
        rgba(0, 0, 0, 0.5) 50%
    );
    background-size: 100% 210%;
    background-position: top bottom;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
}
</style>
