<template>
    <div class="top-backview">
        <div class="contnet-backview">
            <!-- banner图 -->
            <div class="bannerclass">
                <div class="banner-backview">
                    <div
                        class="banner-img-class"
                        :style="
                            'background-image: url(' + imageBanners.url + ');'
                        "
                    ></div>
                    <div class="banner-testback">
                        <div class="banner-title-class">
                            {{ imageBanners.titleStr }}
                        </div>
                        <div class="banner-bottomview">
                            <div class="banner-test-class">
                                {{ imageBanners.testStr }}
                            </div>
                            <div
                                class="banner-test-line"
                                :style="
                                    'background-image: url(' + white_line + ');'
                                "
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 中间部分内容 -->
            <div class="center-backview">
                <div class="introCom-content-titleback">
                    <div class="introCom-content-title">长者心声</div>
                    <div class="introCom-content-title-eng">
                        The voice of the elderly
                    </div>
                </div>
                <div class="content-service-back">
                    <div
                        v-for="(item, index) in elderlyList"
                        :key="index"
                        class="service-item"
                    >
                        <img
                            class="service-item-img"
                            :src="item.currentSrc"
                            alt=""
                        />
                        <div class="service-item-right">
                            <div class="service-item-title">
                                {{ item.titleStr }}
                            </div>
                            <div
                                class="service-item-desc"
                                v-html="item.desc"
                            ></div>
                            <div
                                class="service-item-more"
                                @click="goToDetail(index)"
                            >
                                详细 >
                            </div>
                        </div>
                    </div>
                </div>
                <div class="introCom-content-titleback">
                    <div class="introCom-content-title">荣誉</div>
                    <div class="introCom-content-title-eng">honor</div>
                </div>
                <div class="honor-banner">
                    <el-carousel
                        trigger="click"
                        type="card"
                        :loop="true"
                        :autoplay="true"
                        :interval="3000"
                    >
                        <el-carousel-item
                            v-for="(item, index) in honorList"
                            :key="index"
                        >
                            <div class="item-back">
                                <div
                                    class="scroll-item-img"
                                    :style="
                                        'background-image: url(' +
                                        item.honorUrl +
                                        ');'
                                    "
                                ></div>
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
            </div>
        </div>
        <div
            class="fullscreen-mask"
            v-show="isVisible"
            @click="isVisible = false"
        >
            <!-- 你可以在这里添加额外的内容，比如一个关闭按钮 -->
            <div class="content-backview">
                <!-- <div
                    class="cover-back1"
                    style="
                        height: 36px;
                        line-height: 36px;
                        font-size: 16px;
                        color: black;
                        background: #f6f6f6;
                    "
                ></div> -->
                <div
                    class="cover-back"
                    style="
                        height: 31px;
                        height: 31px;
                        font-size: 16px;
                        color: black;
                        background: #f6f6f6;
                    "
                ></div>
                <!-- <div
                    class="cover-back2"
                    style="
                        height: 30px;
                        line-height: 30px;
                        font-size: 16px;
                        color: black;
                        width: 11.8%;
                        background: #f6f6f6;
                        right: 38%;
                    "
                ></div> -->
                <!-- <div class="left-contentStr" style="padding: 40px">
                    <div class="left-contentStr-title">
                        {{ showData.titleStr }}
                    </div>
                    <div
                        class="left-contentStr-desc"
                        v-html="showData.desc"
                    ></div>
                </div> -->
                <div class="right-contentStr">
                    <!-- <div
                        class="right-contentStr-img"
                        :style="'background-image: url('+ showData.currentSrc +');'"
                    ></div> -->
                    <iframe id="pdfFrame" :src="'/pdf/web/viewer.html?file=' + showData.pdfUrl + '#view=FitH'" frameborder="0" scrolling="auto" width="100%" height="100%" ></iframe>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
document.addEventListener('keydown', function (event) {
  if (event.metaKey && event.keyCode === 83) {
    event.preventDefault()
    alert('保存文件被禁止')
  }
})
export default {
  props: {
    dataHeight: {
      type: String,
      default: '100%'
    }
  },
  data () {
    return {
      showData: {},
      pdfUrl: '',
      showDesc: '',
      isVisible: false,
      white_line: 'https://earthone.com.cn/imageUrl/white_line.png',
      honorList: [{ honorUrl: 'https://earthone.com.cn/imageUrl/honor_banner001.jpg' }, { honorUrl: 'https://earthone.com.cn/imageUrl/honor_banner002.jpg' }, { honorUrl: 'https://earthone.com.cn/imageUrl/honor_banner003.jpg' }, { honorUrl: 'https://earthone.com.cn/imageUrl/honor_banner004.jpg' }, { honorUrl: 'https://earthone.com.cn/imageUrl/honor_banner005.jpg' }, { honorUrl: 'https://earthone.com.cn/imageUrl/honor_banner006.jpg' }, { honorUrl: 'https://earthone.com.cn/imageUrl/honor_banner007.jpg' }, { honorUrl: 'https://earthone.com.cn/imageUrl/honor_banner008.jpg' }, { honorUrl: 'https://earthone.com.cn/imageUrl/honor_banner009.jpg' }],
      elderlyList: [{ currentSrc: 'https://earthone.com.cn/imageUrl/elderly_001.jpg', titleStr: '袁锡麟', desc: '尊敬的爱志万（上海）健康管理公司：<br>彭雨总经理、大谷副总经理先生 您们好！<br>今天我怀着十分激动的心情，书写这封感谢信，略表心意。<br>我是一名86岁的病患、因患脑梗、心梗、寇心病等后遗症，（腸癌=次手术），造成不能走路，足不出戶，生活不能自理。在党和政府的关心下，经过健康医疗小组专业医师上门评估，並将评估结果在小区公示（张貼），批准我成为贵公司一名被护理人员。<br>护理师陈泽春师傅于今年10月11日开始，每周三天，每天一小时，主动上门为我服务，项目有洗澡、按摩、剪指甲等等，干得满脸是汗一刻不停，二个月来从无迟到早退的情况或提任何要求，工作中不仅是一位尽心、尽责的护理员，而且还宣传我党投入大量的人力、物力和和资金对养老工作的重视，以及您们公司严格的管理制度。我为您们公司点赞，为您们领导培养出如优秀的护理师点赞。特此表扬！', pdfUrl: 'https://cdn.spbcn.org/spbcnappneed/goodsNews/elderly_001.pdf' },
        { currentSrc: 'https://earthone.com.cn/imageUrl/elderly_002.jpg', titleStr: '梁琦', desc: '尊敬的公司领导：您们好！<br>我是居住在徐汇区钦江路 28弄8号楼502室（东方康洛小区）的独居老人梁琦。在这我要衷心感谢：曹雅娟老师、毛老师、王瑶老师和李红晓护理员，同时更感谢党的关怀为民的好政策及公司贯彻执行落实好长照服务的举措，让我实实在在享受到了政府的好政策。由贵公司曹雅娟老师于 2019年3月推荐，向虹梅街道居家养老服务中心申请长期护理保险，4月初由贵公司的毛老师带队到我家进行了评估。经过评估，我被评定为6级失能老人，属于照护等级最高的人群，每周可享受不超过7个小时的居家照护服务。今年的4月14日由曹老师派来李红晓护理员为我进行护理服务。小李工作很严谨，每天准时到达，护理耐心细致，不厌其烦，极具爱心，对我的护理非常到位并且在护理过程中还进行了健康指导，传授给我一些保健知识，使我受益匪浅，非常满意。', pdfUrl: 'https://cdn.spbcn.org/spbcnappneed/goodsNews/elderly_002.pdf' },
        { currentSrc: 'https://earthone.com.cn/imageUrl/elderly_003.jpg', titleStr: '吕松岭和卢玲娣', desc: '我是贵公司服务对象吕松岭和卢玲娣的女儿，我的父母现都已是耄耋之寿了。今天受我父母的委托，怀着感激之情向贵公司反映一下这位为二位老人的服务的工作人员：汤天英。掐指算来小汤来到我家作护理已有两年半之多的时间了，在这两年多的时间里小汤在护理二位老人所表現出的是：专业认真！主动耐心！最能让入感受的是她的亲和力及有着一颗喜良的心。<br>我永还不会忘记在2020年10月底的一天下午3点左右我那90岁的老父像平常一样走出家门（他可惯每天都会在这个时间段在小区散步.，差不多到5点左右回家）然而这天一直到晚上7点钟还没有回家，当我接到邻居打来的电話赶到父母家时，我那89岁的老母亲由担心害怕，心里着急连说话都语无伦次了，一问三不知！说真的。我从来没有想到过在新闻里或在电视里有老人走失的事情会发生在我父亲身上。那天晚上起风了，气温有点凉，可能是担心的缘故，感觉那天晚上的天特别的黑暗。这时我想起了小汤，立马给她打了电話、小汤一接到电话就安慰我叫我不要着急，没几分钟就赶到了我家问明情况后立到报警，', pdfUrl: 'https://cdn.spbcn.org/spbcnappneed/goodsNews/elderly_003.pdf' },
        { currentSrc: 'https://earthone.com.cn/imageUrl/elderly_004.jpg', titleStr: '胡根法', desc: '爱志万（上海）健康管理有限公司：我在二0二0年九月10日突然生病-脑梗。后由120救护车送到中山医院急救，目前虽然生命保住了，但后遗症严重-中风，左腿左手不能抬起来，而且右手还骨折，经有关部的申请，并由医医生评估，要求长护险公司、”爱志万公司为我服务护理。后于11月26日派了一位公司的优秀的护理员（李艳双）护理员为我服务护理。经过一定的实践和磨合后，，我深深地感谢李艳双护理员不但人品高尚而且技术高超，经过半个月时间的护理，我的左腿已经能弯曲了、伸直用力了（拄着拐棍在李艳双护理员的拉撑下能慢慢下地活动了。', pdfUrl: 'https://cdn.spbcn.org/spbcnappneed/goodsNews/elderly_004.pdf' },
        { currentSrc: 'https://earthone.com.cn/imageUrl/elderly_005.jpg', titleStr: '倪如珍', desc: '致爱志万全体工作人员：<br>我写这封信的目的是向爱志万日间照料会所表示最诚挚的感谢和敬意，表达我们对于您们专业服务和无私关怀的深深感激。<br>我的母亲，是一位95岁的老人，患有阿尔兹海默症多年，为了让她得到更好的照料，我们选择把她托付给费所。我们把她送到这里，内心充满了忧虑和不安，但是您的田队的专业和用心心，让我们感到深深的安慰和感激。<br>贵所的护理人员不仅在日常生活中给予了家母无微不至的照顾，每天都陪伴她做操。<br>锻炼身体，让她参加各种丰富多彩的娱厌活动。这些活动不仅让她的身体得到了锻炼，也极大地丰富了她的糊神生活。我母来喜欢打扑克牌，工作人员就每天都派人轮流陪伴她玩，我们惊事地发现，家母的记忆力在这段时间里有所改善，在来所伊始，她的记忆力很差，周边的人都稿不清楚，现在她不仅可以分辦清楚所里拿一位员工，甚至能记住新来的老人，并能准确地告诉我们她的所见所闹，就像是贵所这个大家庭的一份子<br>我记得当我们初次把她送到这里时，她的注意力也不集中，做操、活动的时候总跟不上节奏，东张西望，左顾右盼，但贵所的工作人员并没有放弃她，你们以无比的耐心和爱心，一遍又一遍地引导他、陪伴她一起。现在，她学会了很多种有益身心的健身操。', pdfUrl: 'https://cdn.spbcn.org/spbcnappneed/goodsNews/elderly_005.pdf' },
        { currentSrc: 'https://earthone.com.cn/imageUrl/elderly_006.jpg', titleStr: '胡秀英', desc: '享夕阳美景无限，爱志万日间托老会所独具匠心，呵护老人温暖人心个个敬业讲和谐。<br>今天我们作为胡秀英阿婆的家属，怀着无比感激心情，写信给贵所主体的领导员工们。非常感谢贵所所有各方面人员对无助老人一直默默的关心和呵护。感谢你们帮助老人们的生活付出，一走进《爱志万日间托老会所》就被会所的浓浓亲情所吸引。让哪些无助老人们真正的生活在一个温暖的大家庭。贵所除了对老人们的生活精神关心外，还经常帮助老人一起进行各种体能训练及画画活动，使老人们的手脚脑眼能得到很好的锻炼。每天下午还进行下午茶的愉悦活动，同时建立了《爱志万日间托老会所来所日志》记录了老人一天在会所的各种情况，能让家属尽快知道老人的一切状况，在这个温馨的大家庭中老人真是幸福。', pdfUrl: 'https://cdn.spbcn.org/spbcnappneed/goodsNews/elderly_006.pdf' }
      ],
      imageBanners: { url: 'https://earthone.com.cn/imageUrl/mindset_banner.jpg', titleStr: '客 户 心 路', testStr: 'Customers mindset' }
    }
  },
  mounted () {
    document.addEventListener('keydown', this.disableSaveShortcut)
    // const iframe = document.getElementById('ppt-iframe')
    const iframe = this.$el.querySelector('iframe')
    iframe.contentWindow.document.addEventListener('contextmenu', this.disableDownload)
    iframe.contentWindow.document.addEventListener('keydown', this.disableDownload)
  },
  beforeDestroy () {
    // const iframe = document.getElementById('ppt-iframe')
    const iframe = this.$el.querySelector('iframe')
    iframe.contentWindow.document.removeEventListener('contextmenu', this.disableDownload)
    iframe.contentWindow.document.removeEventListener('keydown', this.disableDownload)
  },
  methods: {
    turnToContanct () {
      this.$router.push('/contactus')
    },
    goToDetail (selectIndex) {
      this.isVisible = true
      this.showData = this.elderlyList[selectIndex]
    }
  }
}
</script>
<style lang="less" scoped>
.cover-back {
    width: 180px;
    text-align: center;
    position: absolute;
    // border-bottom: 1px solid #b6b6b6;
    top: 0;
    right: 0;
    background: rgb(255, 255, 255);
    z-index: 1000000000;
}
.cover-back1 {
    width: 180px;
    border-bottom: 1px solid #b6b6b6;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    background: rgb(255, 255, 255);
    z-index: 1000000000;
}
.cover-back2 {
    text-align: center;
    position: absolute;
    top: 0;
    background: rgb(255, 255, 255);
    z-index: 1000000000;
}
.right-contentStr {
    width: 100%;
    height: 100%;
}
.back-view {
    width: 30px;
    height: 90px;
    cursor: pointer;
    background: #00a395;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    right: 0px;
    top: 100px;
    z-index: 100;
}
.content-backview {
    width: 60%;
    height: 90%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: white;
    .left-contentStr {
        width: 50%;
        height: 100%;
        padding: 30px;
        box-sizing: border-box;
        .left-contentStr-title {
            color: black;
            font-size: 12px;
            margin-top: 5px;
            font-family: 'albbphtbold';
        }
        .left-contentStr-desc {
            width: 90%;
            color: rgb(55, 55, 55);
            font-size: 11px;
            margin-top: 10px;
            line-height: 18px;
            font-family: 'albbpht';
        }
    }
}
.item-back {
    width: 500px;
    height: 400px;
}
.scroll-item-img {
    width: 500px;
    height: 267px;
    border: 1px solid rgb(227, 227, 227);
    background-color: #eeeeee;
    cursor: pointer;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
.banner-bottomview {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .banner-test-line {
        width: 60px;
        height: 3px;
        margin-top: 5px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        // background: white;
    }
}
.title {
    width: 30px;
    height: 14px;
    text-align: center;
    color: white;
    font-size: 10px;
}
.title-img {
    width: 10px;
    height: 10px;
    margin-top: 5px;
    border-radius: 5px;
}
.top-backview {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.contnet-backview {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
}
.banner-backview {
    width: 1000px;
    height: 360px;
    position: relative;
}
.banner-testback {
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    position: absolute;
    right: 150px;
    top: 120px;
}
.banner-img-class {
    width: 1000px;
    height: 360px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.bannerclass {
    width: 100%;
    height: 360px;
    background-color: rgb(0, 0, 0);
    z-index: 99;
}
.banner-title-class {
    color: rgb(255, 255, 255);
    font-size: 26px;
    text-align: left;
    z-index: 100;
}
.banner-test-class {
    color: rgb(255, 255, 255);
    text-align: left;
    font-size: 10px;
}
.center-backview {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.honor-banner {
    width: 100%;
    height: 400px;
}
::v-deep .el-carousel__container {
    height: 300px !important;
}
.el-carousel {
    width: 100%;
    height: 400px;
}
.el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
}
.carousel-image {
    width: 500px; /* 根据需要设置图片宽度 */
    height: auto; /* 保持图片的原始宽高比 */
}
.content-service-back {
    width: 730px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .service-item {
        width: 360px;
        height: 120px;
        border-radius: 5px;
        margin-top: 10px;
        box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .service-item-img {
        width: 150px;
        height: 100px;
        object-fit: cover;
        border: 1px solid #cacaca;
        border-radius: 5px;
    }
    .service-item-right {
        width: 180px;
        height: 100px;
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        .service-item-title {
            width: 100%;
            letter-spacing: 2px;
            font-size: 12px;
            color: black;
            font-weight: 800;
            text-align: left;
        }
        .service-item-desc {
            width: 100%;
            height: 60px;
            overflow: hidden;
            margin-top: 10px;
            font-family: 'albbpht';
            font-size: 10px;
            line-height: 17px;
            color: black;
        }
        .service-item-more {
            width: 100%;
            cursor: pointer;
            margin-top: 10px;
            font-size: 10px;
            color: rgb(88, 88, 88);
        }
        .service-item-more:hover {
            text-decoration: underline;
            color: #00a395;
        }
    }
}
.fullscreen-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* 半透明的黑色背景 */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999999; /* 确保遮罩层在大多数其他元素之上 */
}
.right-contentStr-img {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
</style>
