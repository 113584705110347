import Vue from 'vue'
import VueRouter from 'vue-router'
import homeIndex from '../views/homeIndex.vue'
import homeView from '../views/homeView.vue'
import aboutUs from '../views/aboutUs/aboutUs.vue'
import LeadersMessage from '../views/aboutUs/LeadersMessage.vue'
import introCom from '../views/aboutUs/introCom.vue'
import companyhistory from '../views/aboutUs/companyHistory.vue'
import bathingatHome from '../views/serviceIntroduction/bathingatHome.vue'
import dayCareCenters from '../views/serviceIntroduction/dayCareCenters.vue'
import homeService from '../views/serviceIntroduction/homeService.vue'
import conTraining from '../views/serviceIntroduction/conTraining.vue'
import healthManagement from '../views/serviceIntroduction/healthManagement.vue'
import cognitiveImpairment from '../views/serviceIntroduction/cognitiveImpairment.vue'
import leasasles from '../views/serviceIntroduction/leasasles.vue'
import careinstitution from '../views/serviceIntroduction/careinstitution.vue'
import imagewall from '../views/exhibitionCenter/imagewall.vue'
import clientmind from '../views/exhibitionCenter/clientmind.vue'
import contactus from '../views/contactUs/contactus.vue'
import consultation from '../views/contactUs/consultation.vue'
// 404
// import errorpage from '../views/error/errorPage.vue'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '*',
  //   name: '404',
  //   component: errorpage
  // },
  {
    path: '/',
    component: homeIndex,
    redirect: '/homeView',
    children: [
      { path: '/homeView', component: homeView },
      { path: '/aboutUs', component: aboutUs },
      { path: '/LeadersMessage', component: LeadersMessage },
      { path: '/introCom', component: introCom },
      { path: '/bathingatHome', component: bathingatHome },
      { path: '/dayCareCenters', component: dayCareCenters },
      { path: '/homeService', component: homeService },
      { path: '/contraining', component: conTraining },
      { path: '/healthManagement', component: healthManagement },
      { path: '/cognitiveImpairment', component: cognitiveImpairment },
      { path: '/leasasles', component: leasasles },
      { path: '/careinstitution', component: careinstitution },
      { path: '/companyhistory', component: companyhistory },
      { path: '/imagewall', component: imagewall },
      { path: '/clientmind', component: clientmind },
      { path: '/contactus', component: contactus },
      { path: '/consultation', component: consultation }
    ]
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior (to, from, savedPosition) {
    // 期望滚动到哪个位置
    return {
      x: 0,
      y: 0
    }
  }
})
router.afterEach(() => {
  const main = document.querySelector('#main')
  if (main) {
    main.scrollTop = 0
  }
})
export default router
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
