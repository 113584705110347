
<template>
    <div class="top-back">
        <div class="bannerclass">
            <el-carousel
                :interval="3000"
                indicator-position="none"
                arrow="never"
                id="el-carousel"
                style="width: 100%"
            >
                <el-carousel-item
                    v-for="(item, index) in bannerData"
                    :key="index"
                >
                    <div class="banner-backview">
                        <div
                            class="banner-img-class"
                            :style="'background-image: url(' + item.url + ');'"
                        ></div>
                        <div
                            :class="
                                direction === 'left'
                                    ? 'banner-testback-left'
                                    : 'banner-testback-right'
                            "
                        >
                            <div class="banner-title-class">
                                {{ item.titleStr }}
                            </div>
                            <div class="banner-bottomview">
                                <div
                                    class="banner-test-class"
                                    v-html="item.testStr"
                                ></div>
                                <div class="banner-test-line" v-if="lineColor === 'yellow'" :style="'background-image: url('+ yellow_line +');'"></div>
                                <div class="banner-test-line" v-if="lineColor === 'white'" :style="'background-image: url('+ white_line +');'"></div>
                            </div>
                        </div>
                    </div>
                </el-carousel-item>
            </el-carousel>
        </div>
    </div>
</template>

<script>
export default {
  name: 'app',
  props: {
    direction: String,
    bannerData: {
      type: Array,
      required: false
    },
    lineColor: String
  },
  mounted () {
    // console.log(this.bannerData)
  },
  data () {
    return {
      white_line: 'https://earthone.com.cn/imageUrl/white_line.png',
      yellow_line: 'https://earthone.com.cn/imageUrl/yellow_line.png'
    }
  },
  methods: {

  }
}
</script>

<style lang="less" scoped>
.top-back {
    width: 100%;
    height: 100%;
    position: relative;
}
.banner-backview {
    width: 1000px;
    height: 360px;
    position: relative;
}
.banner-testback-left {
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: absolute;
    left: 150px;
    top: 120px;
}
.banner-bottomview{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.banner-testback-right {
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    position: absolute;
    right: 150px;
    top: 120px;
}
::v-deep .el-carousel__container {
    height: 360px !important;
}
.banner-img-class {
    width: 1000px;
    height: 360px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.bannerclass {
    width: 100%;
    height: 360px;
    background-color: rgb(0, 0, 0);
    z-index: 99;
}
.banner-title-class {
    color: rgb(255, 255, 255);
    font-size: 24px;
    text-align: center;
    z-index: 100;
}
.banner-test-class {
    color: rgb(255, 255, 255);
    text-align: center;
    letter-spacing: 1px;
    font-size: 10px;
}
.banner-test-line {
    width: 60px;
    height: 3px;
    margin-top: 5px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    // background: white;
}
/* 如果 showArrows 为 false，则隐藏箭头 */
.el-carousel.hide-arrows .el-carousel__arrow {
  display: none;
}
</style>
