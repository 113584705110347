<template>
  <div>
    <div id="container"></div>
  </div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader'
window._AMapSecurityConfig = {
  securityJsCode: '431506c7519c9ed10caa0f7bfbe91d60'
}
export default {
  data() {
    return {
      map: null // 初始化 map 对象
    }
  },
  methods: {
    initMap() {
      AMapLoader.load({
        key: 'bdadc7732daa19fd73dcaf359d8603a9', // 此处填入我们注册账号后获取的Key
        version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ['AMap.ToolBar', 'AMap.Scale', 'AMap.ControlBar', 'AMap.ElasticMarker'] // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap) => {
        this.map = new AMap.Map('container', { // 设置地图容器id
          viewMode: '3D', // 是否为3D地图模式
          zoom: 18, // 初始化地图级别
          center: [121.45445279, 31.18352511] // 初始化地图中心点位置
        })
        // 构造点标记
        const marker = new AMap.Marker({
          icon: 'https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png',
          position: [121.45445279, 31.18352511],
          anchor: 'bottom-center', // 锚点
          fitZoom: 14, // 最合适的级别
          scaleFactor: 2, // 地图放大一级的缩放比例系数
          maxScale: 2, // 最大放大比例
          minScale: 1// 最小放大比例
        })
        this.map.add(marker)
        const toolbar = new AMap.ToolBar() // 创建工具条插件实例
        this.map.addControl(toolbar) // 添加工具条插件到页面
        const ElasticMarker = new AMap.ElasticMarker()
        this.map.addControl(ElasticMarker)
        const controlBar = new AMap.ControlBar()
        this.map.addControl(controlBar)
      }).catch(e => {
        console.log(e)
      })
    }
  },
  mounted() {
    // DOM初始化完成进行地图初始化
    this.initMap()
  }
}
</script>

<style>
  #container {
    width: 700px;
    height: 300px;
    margin: 30px auto;
  }
</style>
