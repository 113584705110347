<template>
    <div class="top-backview">
        <div class="contnet-backview">
            <!-- banner图 -->
            <topBanner :bannerData="images" direction="right" lineColor="white"></topBanner>
            <!-- 中间部分内容 -->
            <div class="center-backview">
                <div class="introCom-content-titleback">
                    <div class="introCom-content-title">教育咨询培训</div>
                    <div class="introCom-content-title-eng">
                        Education consulting and training
                    </div>
                </div>
                <div class="daycar-content-str">
                    爱志万式人才培训着重于素质培养，讲求实践出真知，追求不断完善。定期开展员工培训课程。并根据甲方需求，可提供定制化培训教程。
                </div>
                <div
                    class="SwiperBox"
                    @mouseenter="MouseFun('移入')"
                    @mouseleave="MouseFun('移出')"
                >
                    <!-- 图片 -->
                    <img
                        :class="['imgCss', ShowImg == index ? 'ShowCss' : '']"
                        :src="item.url"
                        v-for="(item, index) in bannerImgList"
                        :key="index"
                    />
                    <!-- 左箭头按钮 -->
                    <div class="leftBtn" @click="throttle(PrevFun)">&larr;</div>
                    <!-- 右箭头按钮 -->
                    <div class="rightBtn" @click="throttle(NextFun)">
                        &rarr;
                    </div>
                    <!-- 下方指示点容器 -->
                    <div class="instBox">
                        <div
                            v-for="(item, index) in bannerImgList.length"
                            :key="index"
                            @click="ShowImg = index"
                            :class="[
                                'inst',
                                ShowImg == index ? 'instActv' : ''
                            ]"
                        ></div>
                    </div>
                </div>
                <div class="horizontal-scroll-container">
                    <div class="horizontal-scroll-content">
                        <!-- 这里是你的内容，比如一系列的 div 或者其他元素 -->
                        <div
                            class="scroll-item"
                            v-for="(item, index) in bannerImgList"
                            :key="index"
                            @click="bottomClicked(index)"
                        >
                            <div
                                class="scroll-item-img"
                                :style="
                                    'background-image: url(' + item.url + ');'
                                "
                            ></div>
                        </div>
                    </div>
                </div>
                <div class="introCom-content-titleback">
                    <div class="introCom-content-title">培训种类</div>
                    <div class="introCom-content-title-eng">Training types</div>
                </div>
                <div class="content-service-back">
                    <div
                        class="imageWall-item"
                        v-for="(item, index) in imageWall"
                        @mouseenter="hoverImage(index, true)"
                        @mouseleave="hoverImage(index, false)"
                        :key="index"
                    >
                        <img
                            class="imageWall-item-img"
                            :src="item.image"
                            alt=""
                        />
                        <div class="imageWall-item-bottom-str">
                            {{ item.title }}
                        </div>
                        <div class="imageWall-item-child">
                            <div
                                class="environmental-img-hover"
                                v-if="item.isHovered"
                            >
                                <div class="environmental-img-hover-title">
                                    {{ item.title }}
                                </div>
                                <div class="environmental-img-hover-line"></div>
                                <div class="environmental-img-hover-content">
                                    {{ item.desc }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
  props: {
    dataHeight: {
      type: String,
      default: '100%'
    }
  },
  data () {
    return {
      ShowImg: 0,
      flag: true, // 用来节流防止重复点击
      start: null, // 自动执行下一张定时器
      bannerSelect: 'https://earthone.com.cn/imageUrl/training_001.jpg',
      bannerImgList: [
        { url: 'https://earthone.com.cn/imageUrl/training_001.jpg' },
        { url: 'https://earthone.com.cn/imageUrl/training_002.jpg' },
        { url: 'https://earthone.com.cn/imageUrl/training_003.jpg' },
        { url: 'https://earthone.com.cn/imageUrl/training_004.jpg' },
        { url: 'https://earthone.com.cn/imageUrl/training_005.jpg' }
      ],
      imageWall: [
        { image: 'https://earthone.com.cn/imageUrl/training_type01.png', title: '照护技术领域', desc: '照护礼仪，护理（照护）技术，风险管理，个案管理，老年人肌少症预防的机能训练（非医疗）等', isHovered: false },
        { image: 'https://earthone.com.cn/imageUrl/training_type02.png', title: '认知症照护与干预', desc: '认知症的基本认识，与认知症老人的沟通方法，认知症非医疗干预训练，认知症照护技术等', isHovered: false },
        { image: 'https://earthone.com.cn/imageUrl/training_type03.png', title: '其他养老服务项目类', desc: '老年人辅具相关的基础，家庭适老化改造，社区居家服务的介绍等', isHovered: false }
      ],
      images: [{ url: 'https://earthone.com.cn/imageUrl/conTraining_banner.jpg', titleStr: '教 育 咨 询 培 训', testStr: 'Education consulting and training' }]
    }
  },
  mounted () {
    this.setTimeoFun()
  },
  methods: {
    hoverImage (selectIndex, isHovered) {
      this.imageWall[selectIndex].isHovered = !this.imageWall[selectIndex].isHovered
      // for (let index = 0; index < this.imageWall.length; index++) {
      //   if (selectIndex === index) {
      //     this.imageWall[index].isHovered = true
      //   } else {
      //     this.imageWall[index].isHovered = false
      //   }
      // }
    },
    // 这里定义一个鼠标移入移出事件，鼠标悬停时停止自动轮播，鼠标移出则重新执行自动轮播
    MouseFun (type) { // 停止定时器            // 重新执行定时器
      type === '移入' ? clearTimeout(this.start) : this.setTimeoFun()
    },
    setTimeoFun () {
      this.start = setInterval(() => {
        this.NextFun()
      }, 3000)
    },
    // 这里通过额外封装的节流函数触发 PrevFun() 和 NextFun(),以达到防止重复点击的效果
    throttle (fun) {
      if (this.flag) {
        this.flag = false
        fun() // 此为模板中传递进来的PrevFun()或NextFun()函数
        setTimeout(() => {
          this.flag = true
        }, 500) // 节流间隔时间
      }
    },
    // 上一张
    PrevFun () {
      if (this.ShowImg !== 0) {
        this.ShowImg--
      } else {
        this.ShowImg = this.bannerImgList.length - 1
      }
    },
    // 下一张
    NextFun () {
      if (this.ShowImg !== this.bannerImgList.length - 1) {
        this.ShowImg++
      } else {
        this.ShowImg = 0
      }
    },
    bottomClicked (index) {
      this.ShowImg = index
    }
  }
}
</script>
<style lang="less" scoped>
.marginTop {
    margin-top: 30px;
}
.top-backview {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.contnet-backview {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
}
.banner-backview {
    width: 1000px;
    height: 360px;
    position: relative;
}
.banner-testback {
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    position: absolute;
    right: 150px;
    top: 120px;
}
.banner-img-class {
    width: 1000px;
    height: 360px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.bannerclass {
    width: 100%;
    height: 360px;
    background-color: rgb(0, 0, 0);
    z-index: 99;
}
.banner-title-class {
    color: rgb(255, 255, 255);
    font-size: 26px;
    text-align: center;
    z-index: 100;
}
.banner-test-class {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 10px;
}
.el-carousel {
    width: 1000px;
    height: 360px;
}
.el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
}
.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}
.el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
}
.center-backview {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.daycar-content-str {
    width: 700px;
    font-size: 10px;
    font-family: 'albbpht';
    letter-spacing: 1px;
    color: rgb(59, 59, 59);
    text-align: center;
}
.bottom-banner-img {
    width: 700px;
    height: 253px;
    margin: 20px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.horizontal-scroll-container {
    /* 设置容器的宽度，可以是固定的也可以是百分比 */
    width: 700px;
    /* 隐藏超出容器的内容，但显示滚动条 */
    overflow-x: auto;
    /* 你可以添加一些其他样式，如 padding, border 等 */
    white-space: nowrap; /* 防止内容换行 */
}

.horizontal-scroll-content {
    /* 这里不需要特别设置宽度，因为它将根据其子元素自动扩展 */
    /* 但你可以设置一些其他样式，如 padding, margin 等 */
}

.scroll-item {
    /* 设置每个滚动项的样式，如宽度、高度、margin 等 */
    display: inline-block; /* 或者使用 flex, grid 等布局方式 */
    width: 140px; /* 示例宽度 */
    height: 80px; /* 示例高度 */
    margin-right: 10px; /* 示例间距 */
}
.scroll-item:hover {
    border: 1px solid #00a395;
}
.scroll-item-img {
    width: 100%;
    height: 100%;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.content-service-back {
    width: 700px;
    margin-bottom: 40px;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}
.imageWall-item {
    width: 220px;
    height: 227px;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    .imageWall-item-img {
        width: 220px;
        height: 227px;
    }
    .imageWall-item-bottom-str {
        width: 100%;
        position: absolute;
        bottom: 20px;
        text-align: center;
        color: white;
        font-size: 14px;
        font-weight: 800;
    }
}
.imageWall-item-child {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.imageWall-item-child::before {
    content: '';
    display: block;
    background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 50%,
        rgba(0, 0, 0, 0.5) 50%
    );
    background-size: 100% 210%;
    background-position: top bottom;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
}
.wall-image {
    width: 20px;
    height: 20px;
}
.environmental-img-hover {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden; /* 如果需要的话，隐藏超出的内容 */
    .environmental-img-hover-title {
        width: 100%;
        margin-top: 50px;
        text-align: center;
        font-size: 14px;
        color: white;
    }
    .environmental-img-hover-line {
        width: 50px;
        height: 1px;
        background: white;
        margin: 5px;
    }
    .environmental-img-hover-content {
        width: 80%;
        text-align: center;
        font-family: 'albbpht';
        font-size: 10px;
        margin-top: 10px;
        color: rgb(255, 255, 255);
    }
}
.imageWall-item-child:hover:before {
    background-position: left bottom;
}
.imageWall-title {
    width: 100%;
    font-size: 12px;
    color: white;
    font-weight: bolder;
    text-align: center;
}
.imageWall-desc {
    width: 94%;
    font-size: 10px;
    color: white;
}
/* 图片容器样式 */
.SwiperBox {
    position: relative;
    margin: 20px 0px;
    width: 700px;
    height: 373px;
    cursor: pointer;
}
/* 图片默认样式 */
.imgCss {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 700px;
    height: 373px;
    opacity: 0;
    object-fit: cover;
    transition: 0.8s; /* 淡入淡出过渡时间 */
}
/* 图片选中样式(继承上方默认样式) */
.ShowCss {
    opacity: 1;
}
/* 两个按钮共有的样式,也可直接使用箭头图片替代 */
.leftBtn,
.rightBtn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(109, 109, 109, 0.445);
    color: #fff;
    border-radius: 50%;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
}
.leftBtn {
    left: 10px;
}
.rightBtn {
    right: 10px;
}
/* 下方指示器盒子 */
.instBox {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 10px;
    display: flex;
}
/* 小圆点 */
.inst {
    width: 15px;
    height: 3px;
    // border: 1px solid #ccc;
    margin-right: 8px;
    background: #fff;
    border-radius: 2px;
    cursor: pointer;
}
.inst:last-child {
    margin-right: 0px;
}
.instActv {
    width: 30px;
    border: 1px solid #06998a;
    background: #06998a;
}
</style>
