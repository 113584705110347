<template>
    <div class="top-backview">
        <div class="contnet-backview">
            <!-- banner图 -->
            <topBanner
                :bannerData="images"
                direction="left"
                lineColor="white"
            ></topBanner>

            <!-- 中间部分内容 -->
            <div class="center-backview">
                <div class="introCom-content-titleback">
                    <div class="introCom-content-title">日间照料中心</div>
                    <div class="introCom-content-title-eng">
                        Day care centers
                    </div>
                </div>
                <div class="daycar-content-str">
                    通过门对门接送服务，可以让老人们走出户外，与外界接触，预防闭门不出，维持和改善ＡＤＬ，唤起老人对生活的热情。
                    我们的运营宗旨在于“帮助老年人建立自身的生活热情”和“对家庭的支援（减轻照料老人家人的负担）”
                </div>
                <img
                    class="daycar-content-img"
                    src="https://earthone.com.cn/imageUrl/day-care-centers.jpg"
                    alt=""
                />
                <div class="introCom-content-titleback">
                    <div class="introCom-content-title">环境特色</div>
                    <div class="introCom-content-title-eng">
                        Environmental characteristics
                    </div>
                </div>
                <div class="environmental-banner" id="environmental-banner">
                    <div
                        class="VectorVector-left"
                        @click="leftVectorClick"
                    ></div>
                    <div
                        class="VectorVector-right"
                        @click="rightVectorClick"
                    ></div>
                    <div
                        class="environmental-backview"
                        id="environmental-backview"
                    >
                        <div class="environmental">
                            <!-- @mouseenter="hoverImage(index, true)"
                            @mouseleave="hoverImage(index, false)" -->
                            <div
                                v-for="(item, index) in environmental"
                                :key="index"
                                @click="hoverImageClick(index)"
                                :class="[
                                    'environmental-img',
                                    item.isHovered ? 'selectWidth' : ''
                                ]"
                                :id="
                                    item.isHovered
                                        ? 'selectWidthId'
                                        : 'normalWidthId'
                                "
                            >
                                <img :src="item.imageUrl" alt="" />
                                <div
                                    class="environmental-img-hover"
                                    v-if="item.isHovered"
                                >
                                    <div
                                        class="environmental-img-hover-backview"
                                    >
                                        <div
                                            class="environmental-img-hover-title"
                                        >
                                            {{ item.title }}
                                        </div>
                                        <div
                                            class="environmental-img-hover-line"
                                        ></div>
                                        <div
                                            class="environmental-img-hover-content"
                                            v-html="item.desc"
                                        ></div>
                                    </div>
                                </div>
                                <div
                                    v-else
                                    class="environmental-img-hove-toptitle-back"
                                >
                                    <div
                                        class="environmental-img-hover-toptitle"
                                    >
                                        {{ item.title }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="gray-backview">
                    <div class="introCom-content-titleback">
                        <div class="introCom-content-title">
                            认知障碍早期干预及照护法
                        </div>
                        <div class="introCom-content-title-eng">
                            Early intervention and care methods for cognitive
                            impairment
                        </div>
                        <div class="impairment-content-str">
                            日间照料中心运用Humanitude人性化护理技巧，秉持“以人为本”的服务理念，尊重每一位个体，为长者制定个人护理计划并建立健康档案。在认知症长者的护理过程中，通过自立支援的方式，让长者尽量去完成自己能做的事情，以增强他们的自理能力。同时，配合运用运动疗法、园艺疗法、音乐疗法等来帮助改善长者的情绪、促进长者们的身体活动，并维持认知能力。这种全面的干预照护方式旨在为认知症长者提供更好的生活质量。
                        </div>
                        <img
                            class="impairment-content-img"
                            src="https://earthone.com.cn/imageUrl/impairment_img.jpg"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
  props: {
    dataHeight: {
      type: String,
      default: '100%'
    }
  },
  data () {
    return {
      selectIndex: 0,
      environmental: [
        {
          isHovered: true,
          imageUrl: 'https://earthone.com.cn/imageUrl/environmental_001.jpg',
          title: '接待区',
          desc: '前门接待区配备了鞋套机和鞋套，为了维护所内干净整<br>洁的环境，每位访客一律需穿戴鞋套才能进入日照所。<br>此外，来访者需提前预约，最大限度避免对长者造成打<br>扰和影响，为长者们提供放心安全的活动场所。此外，<br>门口设有电子门禁装置，可以有效防止长者尤其认知症<br>长者发生走失事故。'
        },
        {
          isHovered: false,
          imageUrl: 'https://earthone.com.cn/imageUrl/environmental_002.jpg',
          title: '康复器材',
          desc: '<span style="font-weight: 900; font-size: 15px;">双轴坐式划船训练器</span><br>坐式划艇机训练器可以锻炼肩关节、肩胛骨周围肌肉和上肢力量；<br>长期训练可以维持和增长肌肉的力量，稳定身体，有改善站姿挺拔<br>的作用。<br><span style="font-weight: 900; font-size: 15px;">腹部前屈机</span><br>腹部前屈机可以有效锻炼腹筋群肌肉、强化和维持坐姿的肌肉，改<br>善站起及入座的动作。<br><span style="font-weight: 900; font-size: 15px;">腿部训练器</span><br>腿部训练机可以锻炼腿部和臀部肌肉，有助改善站立的作用。<br><span style="font-weight: 900; font-size: 15px;">卧式健身车</span><br>卧式健身车能够改善膝盖及骨关节的动作、提升心肺及循环系统机<br>能，维持及提升身体的耐久力。'
        },
        {
          isHovered: false,
          imageUrl: 'https://earthone.com.cn/imageUrl/environmental_003.jpg',
          title: '大厅',
          desc: '<span style="font-weight: 900; font-size: 15px;">护士台</span><br>日照设有护士台，每日都有专业的护士对长者们进行定期评估、体<br>征检测、健康及服药的管理。<br><span style="font-weight: 900; font-size: 15px;">日照大厅（多功能区）</span><br>日照大厅有七张多功能升降式圆桌，可以根据长者的身高状况及各<br>种活动的需求进行高低调节。围绕着圆桌，长者们一起交谈、进<br>餐、做游戏，大家围桌而坐热热闹闹。<br><span style="font-weight: 900; font-size: 15px;">洗漱区</span><br>洗漱台下侧的凹陷设计也适合使用轮椅的长者。每日到所后通过洗<br>手、漱口来确保长者们的卫生安全。'
        },
        {
          isHovered: false,
          imageUrl: 'https://earthone.com.cn/imageUrl/environmental_004.jpg',
          title: '休息区',
          desc: '<span style="font-weight: 900; font-size: 15px;">沙发</span><br>多功能的沙发位于日照休息区，通过一键式按钮即可轻松改变沙发<br>可坐可躺的功能，便于长者的操作。沙发的两侧有卷帘式窗帘，放<br>下窗帘即可成为一个独立的空间供所内长者们午间休息。<br><span style="font-weight: 900; font-size: 15px;">护理床</span><br>静养区的电动护理床选用日本著名辅具品牌“芙兰舒”的产品，三<br>马达式的电动床更符合的人体力学，便于需要重度护理的长者使<br>用。<br><span style="font-weight: 900; font-size: 15px;">香薰和音乐</span><br>休息区还设有精油香薰机，午间休息时间配合着围绕全所的轻音乐<br>能帮助长者们快速地进入睡眠状态。'
        },
        {
          isHovered: false,
          imageUrl: 'https://earthone.com.cn/imageUrl/environmental_005.jpg',
          title: '休息间',
          desc: '休息小间比较适合睡眠比较警觉的长者，独立的空间隔离外界嘈杂<br>的声音，显得更为安静和舒适。'
        },
        {
          isHovered: false,
          imageUrl: 'https://earthone.com.cn/imageUrl/environmental_006.jpg',
          title: '信息栏',
          desc: '信息栏上的内容每一季度都会更新。本期的信息栏主要展现了长者<br>们在所内的精彩生活和作品。'
        },
        {
          isHovered: false,
          imageUrl: 'https://earthone.com.cn/imageUrl/environmental_007.jpg',
          title: '轮椅卫生间',
          desc: '卫生间宽广空间的设计便于轮椅的进入，也便于照护员在内进行如<br>厕护理。卫生间内设有便捷式洗手池，小巧不占空间且自动感应，<br>能有效帮助长者如厕后立刻进行自身的手部清洁。'
        },
        {
          isHovered: false,
          imageUrl: 'https://earthone.com.cn/imageUrl/environmental_008.jpg',
          title: '更衣室',
          desc: '更衣室有放置洗衣机，每天都会对长者们使用过的毛巾、浴巾等进<br>行清洗并放入消毒柜消毒。<br>更衣室内也设有卫生间，便于长者的使用。'
        },
        {
          isHovered: false,
          imageUrl: 'https://earthone.com.cn/imageUrl/environmental_009.jpg',
          title: '淋浴间',
          desc: '淋浴间的地板是特殊的防滑地板。<br><span style="font-weight: 900; font-size: 15px;">机械泡浴</span><br>爱志万是全上海第一家引进日本技研和研发制造的机器浴缸的公<br>司。机械浴缸具备出水快、安全、自带按摩冲流的特点，泡澡不<br>仅能促进血液循环、更能改善睡眠质量。<br><span style="font-weight: 900; font-size: 15px;">淋浴处</span><br>整体洗浴空间分成干湿分离的更衣间与淋浴间，淋浴处为了确保安<br>全，全部采用坐式淋浴。<br><span style="font-weight: 900; font-size: 15px;">天花板</span><br>淋浴间的顶部天花板采用斜顶式，有助于顶部积水进行排放，避免<br>在长者沐浴中有滴水问题。'
        },
        {
          isHovered: false,
          imageUrl: 'https://earthone.com.cn/imageUrl/environmental_0010.jpg',
          title: '北门',
          desc: '需要接送服务的长者从此门入所，通过接送服务，为更多行动不<br>便、足不出户、距离较远的长者带来安全、便捷的出行服务。'
        }
      ],
      images: [{ url: 'https://earthone.com.cn/imageUrl/careCenter_banner.jpg', titleStr: '日 间 照 料 中 心', testStr: 'Day care centers ' }]
    }
  },
  methods: {
    // hoverImage (selectIndex, isHovered) {
    //   for (let index = 0; index < this.environmental.length; index++) {
    //     if (selectIndex === index) {
    //       this.environmental[index].isHovered = true
    //     } else {
    //       this.environmental[index].isHovered = false
    //     }
    //   }
    //   setTimeout(() => {
    //     if (selectIndex > 1) {
    //       const scrollableDiv = document.getElementById('environmental-backview')
    //       scrollableDiv.scrollLeft = 150
    //     }
    //   }, 300)
    // },
    hoverImageClick (clickIndex) {
      const direction = clickIndex < this.selectIndex ? 'left' : (clickIndex > this.selectIndex ? 'right' : '')
      for (let index = 0; index < this.environmental.length; index++) {
        if (clickIndex === index) {
          this.environmental[index].isHovered = true
        } else {
          this.environmental[index].isHovered = false
        }
      }
      this.selectIndex = clickIndex
      if ((clickIndex > 1 && clickIndex < this.environmental.length - 1) || (clickIndex > 0 && direction === 'left')) {
        if (clickIndex === this.environmental.length - 2 && direction === 'left') {
          return
        }
        setTimeout(() => {
          const scrollBackDiv = document.getElementById('environmental-banner')
          const environmental = document.getElementById('selectWidthId')
          const scrollableDiv = document.getElementById('environmental-backview')
          const scrollx = (scrollBackDiv.offsetWidth - environmental.offsetWidth) / 2
          if (direction === 'left') {
            scrollableDiv.scrollLeft -= scrollx
          } else if (direction === 'right') {
            scrollableDiv.scrollLeft += scrollx
          }
        }, 300)
      }
    },
    leftVectorClick () {
      if (this.selectIndex !== 0) {
        this.selectIndex--
      }
      this.reloadUI()
      if (this.selectIndex > 0 && this.selectIndex < this.environmental.length - 2) {
        setTimeout(() => {
          const scrollableDiv = document.getElementById('environmental-backview')
          const environmental = document.getElementById('normalWidthId')
          scrollableDiv.scrollLeft -= environmental.offsetWidth
        }, 300)
      }
    },
    rightVectorClick () {
      if (this.selectIndex < this.environmental.length - 1) {
        this.selectIndex++
      }
      this.reloadUI()
      if (this.selectIndex > 1) {
        setTimeout(() => {
          const scrollableDiv = document.getElementById('environmental-backview')
          const environmental = document.getElementById('normalWidthId')
          scrollableDiv.scrollLeft += environmental.offsetWidth
        }, 300)
      }
    },
    reloadUI () {
      for (let index = 0; index < this.environmental.length; index++) {
        if (this.selectIndex === index) {
          this.environmental[index].isHovered = true
        } else {
          this.environmental[index].isHovered = false
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.marginTop {
    margin-top: 30px;
}
.environmental-banner {
    width: 700px;
    height: 346px;
    position: relative;
}
.environmental-backview::-webkit-scrollbar {
    display: none;
}
.environmental-backview {
    width: 700px;
    height: 346px;
    scroll-behavior: smooth;
    overflow-x: auto;
    white-space: nowrap; /* 防止内容换行 */
}
.VectorVector-left {
    position: absolute;
    left: 10px;
    top: 150px;
    width: 20px;
    height: 28px;
    z-index: 10000;
    background-image: url(https://earthone.com.cn/imageUrl/VectorVector-left.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.VectorVector-right {
    position: absolute;
    right: 10px;
    z-index: 10000;
    top: 150px;
    width: 20px;
    height: 28px;
    background-image: url(https://earthone.com.cn/imageUrl/VectorVector-right.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.environmental {
    height: 330px;
}
.environmental-img {
    height: 330px;
    width: 150px;
    display: inline-block; /* 或者使用 flex, grid 等布局方式 */
    position: relative;
    transition: width 0.3s ease;
    overflow: hidden; /* 如果需要的话，隐藏超出的内容 */
}
.selectWidth {
    width: 400px;
}
.environmental-img-hove-toptitle-back {
    height: 330px;
    padding-top: 90px;
    box-sizing: border-box;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background: rgba(0, 0, 0, 0.5);
    overflow: hidden; /* 如果需要的话，隐藏超出的内容 */
    .environmental-img-hover-toptitle {
        width: 100%;
        font-size: 14px;
        margin-left: 50px;
        color: white;
    }
}
.environmental-img-hover {
    height: 330px;
    width: 330px;
    margin-left: 50px;
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    overflow: hidden; /* 如果需要的话，隐藏超出的内容 */
    .environmental-img-hover-backview {
        width: 100%;
        height: 100%;
        padding-top: 90px;
        box-sizing: border-box;
        .environmental-img-hover-title {
            width: 100%;
            font-size: 14px;
            color: white;
        }
        .environmental-img-hover-line {
            width: 30px;
            height: 1px;
            background: white;
            margin: 5px;
        }
        .environmental-img-hover-content {
            width: 295px;
            font-size: 10px;
            line-height: 16px;
            margin-top: 10px;
            color: rgb(255, 255, 255);
        }
    }
}
.environmental-img img {
    width: 100%; /* 让图片宽度适应 div 容器 */
    height: 330px;
    object-fit: cover;
    // object-fit: cover;
}
.top-backview {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.contnet-backview {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
}
.banner-backview {
    width: 1000px;
    height: 360px;
    position: relative;
}
.banner-testback {
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    left: 150px;
    top: 120px;
}
.banner-img-class {
    width: 1000px;
    height: 360px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.bannerclass {
    width: 100%;
    height: 360px;
    background-color: rgb(0, 0, 0);
    z-index: 99;
}
.banner-title-class {
    color: rgb(255, 255, 255);
    font-size: 26px;
    text-align: center;
    z-index: 100;
}
.banner-test-class {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 10px;
}
.el-carousel {
    width: 1000px;
    height: 360px;
}
.el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
}
.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}
.el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
}
.center-backview {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.daycar-content-str {
    width: 660px;
    font-size: 10px;
    letter-spacing: 1px;
    font-family: 'albbpht';
    color: rgb(0, 0, 0);
    text-align: center;
}
.impairment-content-str {
    width: 700px;
    font-size: 10px;
    font-weight: 400;
    margin-top: 20px;
    letter-spacing: 4px;
    color: rgb(59, 59, 59);
    text-align: center;
}
.daycar-content-img {
    width: 700px;
    height: 295px;
    margin: 40px;
}
.gray-backview {
    width: 100%;
    padding-bottom: 30px;
    background: rgb(245, 245, 245);
    display: flex;
    align-items: center;
    justify-content: center;
}
.impairment-content-img {
    width: 600px;
    height: 253px;
    margin-top: 20px;
}
</style>
