import { render, staticRenderFns } from "./homeIndex.vue?vue&type=template&id=3ba5792e&scoped=true&"
import script from "./homeIndex.vue?vue&type=script&lang=js&"
export * from "./homeIndex.vue?vue&type=script&lang=js&"
import style0 from "./homeIndex.vue?vue&type=style&index=0&id=3ba5792e&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ba5792e",
  null
  
)

export default component.exports