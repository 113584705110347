import Vue from 'vue'
import {
  Button, Menu, Submenu, MenuItem, MenuItemGroup, Carousel, CarouselItem, Dropdown, DropdownMenu, DropdownItem, Image, Form, FormItem,
  Input, RadioGroup, Radio, Cascader, Option, Select, Message, MessageBox, Loading, Row, Col, Upload, Dialog, Breadcrumb, BreadcrumbItem,
  Card, Steps, Tabs, TabPane, DatePicker, Descriptions, DescriptionsItem, Tag, Divider, Table, TableColumn, Checkbox, Link, Popover, Container,
  Aside, Header, Main, Footer, Step, PageHeader, Pagination, Empty
} from 'element-ui'
// import ElementUI from 'element-ui'
// import 'element-ui/lib/theme-chalk/index.css'
// Vue.use(ElementUI)
Vue.use(Button)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(MenuItemGroup)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Image)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Cascader)
Vue.use(Option)
Vue.use(Select)
Vue.use(Row)
Vue.use(Col)
Vue.use(Upload)
Vue.use(Dialog)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Card)
Vue.use(Steps)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(DatePicker)
Vue.use(Descriptions)
Vue.use(DescriptionsItem)
Vue.use(Tag)
Vue.use(Divider)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Checkbox)
Vue.use(Link)
Vue.use(Popover)
Vue.use(Container)
Vue.use(Aside)
Vue.use(Header)
Vue.use(Main)
Vue.use(Footer)
Vue.use(Step)
Vue.use(PageHeader)
Vue.use(Pagination)
Vue.use(Empty)
const { directive: loadingDirective, service: loadingService } = Loading
const msgbox = MessageBox
const { alert, confirm, prompt } = msgbox
Vue.use(loadingDirective)

Vue.prototype.$loading = loadingService
Vue.prototype.$msgbox = msgbox
Vue.prototype.$alert = alert
Vue.prototype.$confirm = confirm
Vue.prototype.$prompt = prompt
// Vue.prototype.$notify = Notification
Vue.prototype.$message = Message
// Vue.prototype.$message = Message
// Vue.prototype.$confirm = MessageBox.confirm
// Vue.prototype.$prompt = MessageBox.prompt
// Vue.prototype.$alert = MessageBox.alert
// Vue.prototype.$loading = Loading.service
